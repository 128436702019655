<template>
  <div class="about">
    <div>
      <el-button size="small" type="primary" @click="addNewOrg('new')">新增单位</el-button>
    </div>
    <div class="mt15">
      <el-table border :data="orgData" style="width: 100%;">
        <el-table-column prop="id" label="单位ID" align="center">
        </el-table-column>
        <el-table-column prop="dwmc" label="单位名称" align="center">
        </el-table-column>
        <el-table-column prop="bz" label="备注" align="center">
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button @click="addNewOrg('mod', scope.row)" type="text" size="small">修改</el-button>
            <el-popover placement="top" width="160" v-model="scope.row.visible" class="ml15">
              <p>确定要删除此机构吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="scope.row.visible = false">取消</el-button>
                <el-button type="primary" class="ml15" size="mini" @click="deleteOrg(scope.row)">确定</el-button>
              </div>
              <el-button slot="reference" type="text" size="small">删除</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt15 algRt">
        <el-pagination background layout="total, prev, pager, next" :total="page.total" :page-size="page.size"
          :current-page.sync="page.current" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <!-- <div>
        <el-popover placement="top" width="160" v-model="delVisible" class="ml15">
          <p>确定要删除此机构吗？</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="delVisible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="deleteOrg(scope.row)">确定</el-button>
          </div>
          <el-button slot="reference" type="text" size="small">删除</el-button>
        </el-popover>
      </div> -->
    </div>
    <div>
      <el-dialog :title="orgParm.title" :visible.sync="orgFormVisible">
        <el-form :model="orgParm">
          <el-form-item label="单位名称" :label-width="formLabelWidth">
            <el-input v-model="orgParm.dwmc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="orgParm.bz">
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addNewOrg('cancel')">取 消</el-button>
          <el-button type="primary" @click="saveOrgMod">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import { mapState, mapMutations } from 'vuex';
import { crudFun } from '@/libs/crud';
import Lazy from 'lazy.js';

let that = null;
let orgAddUrl = '/dw/add'; //单位新增
let orgUpdateUrl = '/dw/update'; //单位修改
let orgListUrl = '/dw/list'; //单位查询
let orgDelUrl = '/dw/del'; //单位删除

export default {
  name: 'OrgManageView',
  data() {
    return {
      orgData: [],
      orgFormVisible: false,
      orgParm: {
        id: null,
        dwmc: null,
        bz: null,
        title: null
      },
      formLabelWidth: '120px',
      page: {
        total: 0,
        size: 10,
        current: 1
      },
      // delVisible: false
    }
  },
  computed: {
    // ...mapState([
    // ])
  },
  methods: {
    // ...mapMutations([]),
    //单位查询
    getOrg() {
      let gtCfg = {
        method: 'post',
        url: orgListUrl,
        data: {
          "pageNo": that.page.current,
          "pageSize": that.page.size
        }
      };
      crudFun(gtCfg).then((data) => {
        if (data) {
          let orgArr = data.records;
          Lazy(orgArr).each(function (org) {
            org.visible = false;
          });
          that.page.total = +data.total;
          that.orgData = orgArr;
        }
      });
    },
    //当前页面切换
    handleCurrentChange(val) {
      that.page.current = val;
      that.getOrg();
    },
    //新增单位弹出
    addNewOrg(tp, row) {
      that.orgParm = {
        id: null,
        dwmc: null,
        bz: null,
        title: null
      };
      switch (tp) {
        case 'new':
          that.orgFormVisible = true;
          that.orgParm.title = '单位新增'
          break;
        case 'mod':
          let sltRow = that.$comn.cloneObj(row);
          sltRow.title = '单位修改';
          that.orgFormVisible = true;
          that.orgParm = sltRow;
          break;
        case 'cancel':
          that.orgFormVisible = false;
          break;
      }
    },
    //保存单选新增和修改
    saveOrgMod() {
      let svCfg = {
        method: 'post',
        url: null,
        data: {
          "dwmc": that.orgParm.dwmc,
          "bz": that.orgParm.bz
        }
      };
      if (that.orgParm.id) {
        svCfg.url = orgUpdateUrl;
        svCfg.data.id = that.orgParm.id;
      }
      else {
        svCfg.url = orgAddUrl;
      }
      if (!svCfg.data.dwmc) {
        that.$message({
          showClose: true,
          message: '请输单位名称！',
          type: 'error'
        });
        return;
      }
      crudFun(svCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '成功！',
            type: 'success'
          });
          that.addNewOrg('cancel');
          that.getOrg();
        }
      });
    },
    //删除机构
    deleteOrg(row) {
      let delCfg = {
        method: 'get',
        url: orgDelUrl,
        params: {
          "id": null
        }
      };
      if (row) {
        delCfg.params.id = row.id;
        crudFun(delCfg).then((data) => {
          if (data) {
            row.visible = false;
            that.$message({
              showClose: true,
              message: '删除成功！',
              type: 'success'
            });
          }
          that.getOrg();
        });
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择要删除的单位！',
          type: 'error'
        });
      }
    }
  },
  mounted() {
    that.handleCurrentChange(1);
  },
  created() {
    that = this;
  }
}
</script>

<style lang="less" scoped></style>