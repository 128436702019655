<template>
  <div class="about">
    <div>
      <el-button size="small" type="primary" @click="importUser('pop')">导入学生编排数据</el-button>
    </div>
    <div class="mt15">
      <el-table border :data="userData" style="width: 100%;">
        <el-table-column prop="zkzh" label="准考证号" align="center">
        </el-table-column>
        <el-table-column prop="xm" label="姓名" align="center">
        </el-table-column>
        <!-- <el-table-column prop="zjh" label="证件号" align="center">
        </el-table-column> -->
        <el-table-column prop="kcdm" label="课程代码" align="center">
        </el-table-column>
        <el-table-column prop="kcmc" label="课程名称" align="center">
        </el-table-column>
        <el-table-column prop="kddm" label="考点代码" align="center">
        </el-table-column>
        <el-table-column prop="kdmc" label="考点名称" align="center">
        </el-table-column>
        <!-- <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-popover placement="top" width="160" v-model="scope.row.visible" class="ml15">
              <p>确定要删除此学生吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="scope.row.visible = false">取消</el-button>
                <el-button type="primary" class="ml15" size="mini" @click="deleteUser(scope.row)">确定</el-button>
              </div>
              <el-button slot="reference" type="text" size="small">删除</el-button>
            </el-popover>
          </template>
        </el-table-column> -->
      </el-table>
      <div class="mt15 algRt">
        <el-pagination background layout="total, prev, pager, next" :total="page.total" :page-size="page.size"
          :current-page.sync="page.current" @current-change="queryUser()">
        </el-pagination>
      </div>
    </div>
    <div>
      <!-- 批量添加用户 -->
      <el-dialog title="学生编排数据导入" width="40%" modal :rules="batchRules" :close-on-click-modal="false" :show-close="false"
        :visible.sync="imptFormVisible">
        <el-form :model="batchPar" label-width="160px">
          <el-form-item label="文件">
            <el-upload class="upload-demo" ref="upload" action="" :on-remove="removeFile" :on-change="getFileInfo"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
              <a class="tplAstyle" href="/template/学生编排数据模板.xlsx" download="学生编排数据模板.xlsx">学生编排数据模板.xlsx</a>
              <div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" @click="batchImportUsr()">上传</el-button>
          <el-button size="small" class="ml15" @click="importUser('cancel')">关闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Lazy from 'lazy.js';
import { crudFun } from '@/libs/crud';

let that = null;
let xsbmxxListUrl = '/xsbmxx/list'; //查询学生编排数据
let xsbmxxDelUrl = '/xsbmxx/del'; //删除学生编排数据
let importStuUrl = '/xsbmxx/excelImport/'; //批量导入学生编排数据
export default {
  name: 'StuManageView',
  data() {
    return {
      imptFormVisible: false,
      userData: [],
      userParm: {
        xm: null,
        sjh: null,
        zjhm: null,
        dwid: null,
        jsid: null
      },
      uploadFile: null,
      page: {
        total: 0,
        size: 10,
        current: 1
      },
      batchPar: {
        jsdm: null,
        dwid: null
      },
      batchRules: {
        jsdm: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
        dwid: [
          { required: true, message: '请选择单位', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapState(['mvSltPici'])
  },
  methods: {
    // ...mapMutations(['updateJueseInfo']),
    //查询用户列表
    queryUser() {
      let usrCfg = {
        method: 'post',
        url: xsbmxxListUrl,
        data: {
          "pageNo": that.page.current,
          "pageSize": that.page.size,
          // "pcdm": "2023091001"
          "pcdm": that.mvSltPici
        }
      };
      crudFun(usrCfg).then((data) => {
        if (data) {
          let usrArr = data.records;
          Lazy(usrArr).each(function (usr) {
            usr.visible = false;
          });
          that.page.total = +data.total;
          that.userData = usrArr;
        }
      });
    },
    //删除用户
    deleteUser(row) {
      let delCfg = {
        method: 'get',
        url: xsbmxxDelUrl,
        params: {
          "id": null
        }
      };
      if (row) {
        delCfg.params.id = row.id;
        crudFun(delCfg).then((data) => {
          if (data) {
            row.visible = false;
            that.$message({
              showClose: true,
              message: '删除成功！',
              type: 'success'
            });
          }
          that.queryUser();
        });
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择要删除的用户！',
          type: 'error'
        });
      }
    },
    //导入用户
    importUser(tp) {
      if (tp && tp == 'pop') {
        that.imptFormVisible = !that.imptFormVisible;
      }
      else {
        that.batchPar = {
          jsdm: null,
          dwid: null
        };
        that.uploadFile = null;
        that.imptFormVisible = !that.imptFormVisible;
      }
    },
    //删除选中的文件
    removeFile(file) {
      that.uploadFile = null;
      let upFileElm = document.querySelector('.el-upload-list');
      if(upFileElm){
        upFileElm.remove();
      }
    },
    //得到文件详细信息
    getFileInfo(file) {
      that.uploadFile = file;
    },
    //批量导入用户
    batchImportUsr() {
      let flCfg = {
        method: "post",
        url: importStuUrl + that.mvSltPici,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: null
      };
      const fd = new FormData();
      if (!that.mvSltPici) {
        that.$message({
          showClose: true,
          message: '请选择批次！',
          type: 'error'
        });
        return;
      }
      if (!that.uploadFile) {
        that.$message({
          showClose: true,
          message: '请选择要上传的文件！',
          type: 'error'
        });
        return;
      }
      let file = that.uploadFile.raw;
      fd.append("file", new Blob([file], {type: file.type}));
      flCfg.data = fd;
      crudFun(flCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '学生数据导入成功！',
            type: 'success'
          });
          that.importUser('cancel');
          that.queryUser();
          that.$emit('on-updateData', {type: 'subject'});
        }
      });
    }
  },
  mounted() {
    if(that.mvSltPici){
      that.queryUser();
    }
    else{
      that.userData = [];
      that.$message({
        showClose: true,
        message: '请选择批次！',
        type: 'error'
      });
    }
  },
  created() {
    that = this;
  },
  watch: {
    mvSltPici: {
      handler(newVal, oldVal) {
        // 数据发生变化时执行的操作
        if (that.mvSltPici) {
          that.queryUser();
        }
        else{
          that.userData = [];
        }
      },
      deep: true, // 是否深度监听
      immediate: false, // 是否在组件创建时立即执行回调函数
    },
  }
}
</script>

<style lang="less" scoped>
.input-with-select {
  width: 500px;
  margin-left: 15px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.dialog-footer {
  text-align: center;
}
</style>