<template>
  <div class="timuPage">
    <!-- 修改题目 -->
    <div class="timu" v-if="isModTimu">
      <el-form label-width="100px" v-if="tmRendDone">
        <el-form-item label="题目题号：">
          {{ timuForm.questionNo }}
        </el-form-item>
        <el-form-item label="题目题型：">
          {{ timuForm.questionName }}
        </el-form-item>
        <el-form-item label="题目题干：">
          <div class="tiGan" v-html="timuForm['题目内容']['题干']">
          </div>
        </el-form-item>
        <el-form-item v-if="timuForm['题目类型ID'] < 4">
          <!-- 单选题 -->
          <div class="tizhi" v-if="timuForm['题目类型ID'] == 1">
            <el-radio-group class="wdp100" v-if="forceFresh" v-model="timuForm['学生作答']" @change="assigninVal()">
              <el-radio class="xuanze" v-for="(tz, idx) in timuForm['题目内容']['选项']" :key="idx" :label="tz['选项序号']">
                <span class="xuanzeLetter">{{ letter[idx] + '、' }}</span>
                <div class="xuanzeCont" v-html="tz['选项内容']"></div>
              </el-radio>
            </el-radio-group>
          </div>
          <!-- 多选题 -->
          <div class="tizhi" v-if="timuForm['题目类型ID'] == 2">
            <el-checkbox-group class="wdp100" v-if="forceFresh" v-model="timuForm['学生作答']" @change="assigninVal()">
              <el-checkbox class="xuanze" v-for="(tz, idx) in timuForm['题目内容']['选项']" :key="tz['选项序号']"
                :label="tz['选项序号']">
                <span class="xuanzeLetter">{{ letter[idx] + '、' }}</span>
                <div class="xuanzeCont" v-html="tz['选项内容']"></div>
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <!-- 判断题，需要参考实际题目 -->
          <div v-if="timuForm['题目类型ID'] == 3">
            <el-radio-group class="wdp100" v-if="forceFresh" v-model="timuForm['学生作答']" @change="assigninVal()">
              <el-radio :label="1">对</el-radio>
              <el-radio :label="0">错</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <!-- ======答案的展示====== -->
        <el-form-item class="answer-cont" :label="timuForm['题目类型ID'] < 4 ? '正确答案：' : '评分参考：'">
          <!-- <div v-if="timuForm['答案是图片']">
            <div class="mb15" v-for="(pic, index) in timuForm['图片答案']" :key="index" v-html="pic"></div>
          </div>
          <div v-else> -->
          <div v-if="timuForm['题目类型ID'] < 4" v-html="timuForm.tmAnswer"></div>
          <!-- </div> -->
        </el-form-item>
        <!-- 题型 >=4 的答案的修改 -->
        <div v-if="timuForm['题目类型ID'] >= 4">
          <div v-if="timuForm['题目类型ID'] == 4">
            注意：<em class="clRed">填空题请按照空的顺序输入正确答案，并用英文分号(“;”)分隔！</em>
          </div>
          <div v-if="timuForm['题目类型ID'] == 6">
            注意：<em class="clRed">选择填空题请按照空的顺序输入大写字母（例如：A），并用英文分号(“;”)分隔！</em>
          </div>
          <div id="editor—wrapper">
            <div id="toolbar-container"></div>
            <div id="editor-container"></div>
          </div>
          <!-- <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="timuForm['学生作答']">
          </el-input> -->
          <div>
            <el-upload class="upload-demo" ref="upload" action="" :on-remove="removeFile" :on-change="getFileInfo"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="text">导入图片</el-button>
            </el-upload>
          </div>
        </div>
      </el-form>
    </div>
    <div class="timu" v-else>
      <el-form label-width="100px" v-if="tmRendDone">
        <el-form-item label="题目题号：">
          {{ timuForm.questionNo }}
        </el-form-item>
        <el-form-item label="题目题型：">
          {{ timuForm.questionName || timuForm['题型名称'] }}
        </el-form-item>
        <el-form-item label="题目题干：">
          <div class="tiGan" v-html="timuForm['题目内容']['题干']">
          </div>
        </el-form-item>
        <!-- ======题支展示====== -->
        <el-form-item v-if="timuForm['题目类型ID'] < 4">
          <!-- 单选题 -->
          <div class="tizhi" v-if="timuForm['题目类型ID'] == 1">
            <div class="xuanze" v-for="(tz, idx) in timuForm['题目内容']['选项']" :key="idx">
              <span class="xuanzeLetter">{{ letter[idx] + '、' }}</span>
              <div class="xuanzeCont" v-html="tz['选项内容']"></div>
            </div>
          </div>
          <!-- 多选题 -->
          <div class="tizhi" v-if="timuForm['题目类型ID'] == 2">
            <div class="xuanze" v-for="(tz, idx) in timuForm['题目内容']['选项']" :key="idx">
              <span class="xuanzeLetter">{{ letter[idx] + '、' }}</span>
              <div class="xuanzeCont" v-html="tz['选项内容']"></div>
            </div>
          </div>
          <!-- 判断题，需要参考实际题目 -->
          <div v-if="timuForm['题目类型ID'] == 3">
            <div class="xuanze">对</div>
            <div class="xuanze">错</div>
          </div>
        </el-form-item>
        <!-- ======答案的展示====== -->
        <el-form-item class="answer-cont" :label="timuForm['题目类型ID'] < 4 ? '正确答案：' : '评分参考：'">
          <div v-html="timuForm.tmAnswer"></div>
          <!-- <div v-if="timuForm['答案是图片']">
            <div class="mb15" v-for="(pic, index) in timuForm['图片答案']" :key="index" v-html="pic"></div>
          </div>
          <div v-else>
            <div v-html="timuForm.tmAnswer"></div>
          </div> -->
        </el-form-item>
      </el-form>
    </div>
    <div class="mt15 algCt">
      <!-- 父传过来的值:<span>{{ passTimu }}</span> -->
      <!-- 子传递给父的值:<span>{{ childValue }}</span> -->
      <div v-if="isModTimu">
        <el-button type="primary" size="medium" @click="modifyTimuAnswer">保存修改</el-button>
        <el-button class="ml15" size="medium" @click="closeThisPage">关闭</el-button>
      </div>
      <div v-else>
        <el-button size="medium" @click="closeThisPage">关闭页面</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState, mapMutations } from 'vuex';
import Lazy from 'lazy.js';
import { crudFun } from '@/libs/crud';
import { createEditor, createToolbar } from '@wangeditor/editor';


let that = null;
let timuId = null;
let getQuestiongInfoUrl = '/paper/getQuestionInfo'; //查看题目详细
let updateQuestionAnswerUrl = '/paper/updateQuestionAnswer'; //修改问题答案
let uploadAnswerFileUrl = '/paper/uploadAnswerFile'; //上传标准答案文件
let editor = null;
let toolbar = null;
let isModTm = false;
let rendEditor = false;
export default {
  name: 'TimuView',
  props: {
    passTimu: Object
  },
  data() {
    return {
      // childValue: '我是子组件的数据'
      letter: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
        'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'], //英文字母序号数组
      timuForm: {},
      forceFresh: true,
      isModTimu: false,
      tmRendDone: false,
      uploadFile: null,
      // backFileUrl: 'Resources/answer/735ede28dbde405fa0792233fe569e8d.png',
      // backFileUrl: null,
      saveSuccess: false
    }
  },
  computed: {
    // ...mapState([
    //   'user'
    // ])
  },
  methods: {
    //关闭此页面
    closeThisPage() {
      // that.$emit('timuEvent', that.childValue);
      that.$emit('timuEvent', that.saveSuccess);
    },
    //查询题目
    queryTiMu() {
      let tmCfg = {
        method: 'get',
        url: getQuestiongInfoUrl,
        params: {
          'questionId': timuId
        }
      }
      if (!timuId) {
        that.$message({
          showClose: true,
          message: '缺少题目ID！',
          type: 'error'
        });
      }
      // let isModTm = that.timuForm['修改题目'];
      crudFun(tmCfg).then((data) => {
        if (data) {
          let dealTm = that.$comn.dealTiMu(data, isModTm);
          that.timuForm = Lazy(that.timuForm).merge(dealTm).toObject();
          that.isModTimu = isModTm;
          that.tmRendDone = true;
          if (rendEditor) {
            that.renderEditor();
          }
        }
      });
    },
    //小题组长，显示题目
    renderTiMu() {
      // let isModTm = that.timuForm['修改题目'];
      that.timuForm = that.$comn.dealTiMu(that.timuForm, isModTm);
      that.isModTimu = isModTm;
      that.tmRendDone = true;
      console.log('timuForm:', that.timuForm);
    },
    //修改题目
    modifyTimuAnswer() {
      if (!that.timuForm) {
        that.$message({
          showClose: true,
          message: '缺少题目信息！',
          type: 'error'
        });
        return;
      }
      console.log('timuForm:', that.timuForm);
      let modCfg = {
        method: 'post',
        url: updateQuestionAnswerUrl,
        data: []
      };
      let rspArr = that.$comn.toObj(that.timuForm.responses);
      let questionType = that.timuForm.questionType;
      let stuZd = that.timuForm['学生作答'];
      let newAnswers = [];
      Lazy(rspArr).sort().each(function (res, idx) {
        let answerTemp = {
          "paperId": that.timuForm.paperId,
          "questionId": that.timuForm['题目ID'],
          "identifier": res,
          "answerContent": ""
        };
        let aswStr = null;
        switch (questionType) {
          case 1:
            if (stuZd === 0 || stuZd > 0) {
              aswStr = that.letter[stuZd];
            }
            break;
          case 2:
            let dxTmp = [];
            Lazy(stuZd).sort().each(function (asw) {
              let aswLt = that.letter[asw];
              dxTmp.push(aswLt);
            });
            aswStr = dxTmp.join(',');
            break;
          case 3:
            if (stuZd === 1) {
              aswStr = 'A';
            }
            else if (stuZd === 0) {
              aswStr = 'B';
            }
            break;
          case 4:
            // case 6:
            const tkHtml = editor.getHtml();
            let stdAsw = null;
            let hasImg = tkHtml.indexOf('<img');
            if(hasImg > -1){
              stdAsw = tkHtml;
            }
            else{
              stdAsw = editor.getText();
            }
            let aswSpltArr = stdAsw.split(';');
            aswStr = aswSpltArr[idx];
            break;
          case 5:
            const htmlVal = editor.getHtml();
            aswStr = htmlVal + '';
            break;
        }
        if (aswStr) {
          answerTemp.answerContent = aswStr;
          newAnswers.push(answerTemp);
        }
      });
      modCfg.data = newAnswers;
      crudFun(modCfg).then((data) => {
        if (data) {
          that.saveSuccess = true;
          that.$message({
            showClose: true,
            message: '答案修改成功！',
            type: 'success'
          });
          that.closeThisPage();
        }
      });
    },
    //题目赋值
    assigninVal() {
      // that.timuForm['学生作答']
      that.forceRerender();
    },
    //强制刷新dom
    forceRerender: function (tp) {
      that.forceFresh = false;
      that.$nextTick().then(() => {
        that.forceFresh = true;
      });
    },
    //删除选中的文件
    removeFile(file) {
      that.uploadFile = null;
      let upFileElm = document.querySelector('.el-upload-list');
      if(upFileElm){
        upFileElm.remove();
      }
    },
    //得到文件详细信息
    getFileInfo(file) {
      that.uploadFile = file;
      console.log('uploadFile:', that.uploadFile);
      setTimeout(() => {
        that.uploadAswFun();
      }, 500);
    },
    //导入标准答案图片
    uploadAswFun() {
      let aswCfg = {
        method: 'post',
        url: uploadAnswerFileUrl,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: null
      };
      const fd = new FormData();
      let mis = [];
      let file = that.uploadFile.raw;
      if (!file) {
        mis.push('试卷包');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      let fileType = that.$comn.getFileType(file.name);
      aswCfg.headers.extFileName = fileType;
      fd.append("file", new Blob([file], { type: file.type }));
      aswCfg.data = fd;
      crudFun(aswCfg).then((data) => {
        if (data) {
          setTimeout(function(){
            that.removeFile(that.uploadFile);
          }, 200);
          // let data = 'Resources/answer/735ede28dbde405fa0792233fe569e8d.png';
          // let data = 'http://yj.zhifz.com/Resources/answer/735ede28dbde405fa0792233fe569e8d.png';
          let aswCont = '<p><img src="' + data + '" alt="答案"></p>';
          editor.setHtml(aswCont);
          that.$message({
            showClose: true,
            message: '答案上传成功！',
            type: 'success'
          });
          // that.uploadFile = null;
          // that.backFileUrl = data;
        }
      });
    },
    // 加载编辑器
    renderEditor() {
      let tmlx = that.timuForm.questionType;
      let stuZd = that.timuForm['学生作答'];
      if(tmlx == 4 && stuZd){
        that.timuForm['学生作答'] = stuZd.join(';');
        stuZd = that.timuForm['学生作答'];
      }
      setTimeout(() => {
        // this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
        // 创建编辑器
        editor = createEditor({
          selector: '#editor-container',
          // mode: 'simple'
        });
        // 创建工具栏
        toolbar = createToolbar({
          editor,
          selector: '#toolbar-container',
          // mode: 'simple'
        });
        // editor.setHtml('<p>模拟 Ajax 异步设置内容 HTML</p>');
        editor.setHtml(stuZd);
      }, 500);
    }
  },
  mounted() {
    if (that.passTimu) {
      let tmCopy = that.$comn.cloneObj(that.passTimu);
      let tmlxId = tmCopy.questionType;
      isModTm = tmCopy['修改题目'];
      switch (tmlxId) {
        case 1:
          break;
        case 2:
          tmCopy['学生作答'] = [];
          break;
        case 3:
          break;
        case 4:
        case 5:
          if (isModTm) {
            rendEditor = true;
            // that.renderEditor();
          }
          break;
        case 6:
          break;
      }
      timuId = tmCopy['identifier'];
      that.timuForm = tmCopy;
      that.queryTiMu();
    }
    else {
      that.$message({
        showClose: true,
        message: '缺少题目ID！',
        type: 'error'
      });
    }
  },
  created() {
    that = this;
  }
}
</script>

<style lang="less">
.modTimu {

  .el-radio__input,
  .el-checkbox__input {
    display: none !important;
  }
}

.tiGan {
  p {
    margin-top: 0;
  }
}

.tizhi {
  .xuanze {
    width: 45%;
    display: inline-flex;
    margin-bottom: 15px;
    position: relative;
  }

  .el-radio__input,
  .el-checkbox__input {
    margin-top: 3px;
  }

  .el-radio__label,
  .el-checkbox__label {
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 24px;
    vertical-align: text-top;
    // display: inline-block;
    position: relative;
  }
}

.xuanzeLetter {
  position: absolute;
  left: 10px;
  top: 0;
  // margin-top: -14px;
}

.xuanzeCont {
  padding-left: 24px;

  >p {
    margin: 0;
  }
}

.answer-cont {
  p {
    margin-top: 0 !important;
  }
  img {
    max-width: 100%;
  }
}
</style>

<style lang="less" scoped>
.timuPage .el-form-item {
  margin-bottom: 0;
}

#editor—wrapper {
  border: 1px solid #ccc;
  z-index: 100;
  /* 按需定义 */
}

#toolbar-container {
  border-bottom: 1px solid #ccc;
}

#editor-container {
  height: 240px;
}
</style>