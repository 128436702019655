import Server from "./server";
import { Message } from "element-ui";
// let that = this;
let token = localStorage.getItem('myToken');
export function crudFun(config){
    let rqUrl = config.url;
    let getTokenCount = 0;
    return new Promise(function(resolve, reject){
        if(rqUrl == '/login'){
            resolve();
        }
        else{
            token = localStorage.getItem('myToken');
            if(token){
                if(config.headers){
                    config.headers.token = token;
                }
                else{
                    config.headers = {
                        token: token
                    };
                }
                resolve();
            }
            else{
                let qryToken = function(){
                    token = localStorage.getItem('myToken');
                    if(token){
                        if(config.headers){
                            config.headers.token = token;
                        }
                        else{
                            config.headers = {
                                token: token
                            };
                        }
                        resolve();
                    }
                    else {
                        if(getTokenCount < 10){
                            setTimeout(function(){
                                getTokenCount ++;
                                qryToken();
                            }, 1000);
                        }
                        else{
                            Message({
                                message: '获取用户token失败！',
                                type: 'error',
                                duration: 5000
                            });
                            return Promise.reject({ error: '获取用户token失败！' });
                        }
                    }
                }
                qryToken();
            }
        }
    })
    .then(function(){
        // config.headers.token = token;
        return Server(config);
    });
};