<template>
  <div class="main">
    <el-container class="mainCont">
      <el-aside class="mainAside" width="200px">
        <div class="asdLogo">
          <!-- <img src="../assets/logo.png" alt=""> -->
          阅卷系统
        </div>
        <div v-show="renderComponent">
          <el-menu class="el-menu-vertical-demo" :default-active="activeIndex" :default-openeds="openMenu" unique-opened
            @select="selectMenu" background-color="#1163a6" text-color="#fff" active-text-color="#ffd04b">
            <el-menu-item v-for="menu in roleMenu" :key="menu.index" :index="menu.index + ''">
              <i class="menu-icon" :class="menu.icon"></i>{{ menu.name }}
            </el-menu-item>
            <!-- <el-submenu index="1">
              <template slot="title"></template>
                <i class="el-icon-setting"></i>
                <span slot="title">管理</span>
              </template>
              <el-menu-item index="11">单位管理</el-menu-item>
              <el-menu-item index="12">用户管理</el-menu-item>
              <el-menu-item index="13">考试项目管理</el-menu-item>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-edit-outline"></i>
                <span slot="title">评卷任务管理</span>
              </template>
              <el-menu-item index="21">
                批次管理
              </el-menu-item>
              <el-menu-item index="22">
                学生编排数据
              </el-menu-item>
              <el-submenu index="23">
                <template slot="title">
                  <i class="el-icon-files"></i>
                  <span slot="title">任务分配</span>
                </template>
                <el-menu-item index="231">项目负责人</el-menu-item>
                <el-menu-item index="232">大区负责人</el-menu-item>
                <el-menu-item index="233">科目负责人</el-menu-item>
                <el-menu-item index="234">小题组长</el-menu-item>
              </el-submenu>
              <el-menu-item index="24">进度</el-menu-item>
              <el-menu-item index="25">填空答案仲裁</el-menu-item>
            </el-submenu>
            <el-menu-item index="3">
              <i class="el-icon-document-checked"></i>
              <span slot="title">评卷</span>
            </el-menu-item>
            <el-menu-item index="4">
              <i class="el-icon-postcard"></i>
              <span slot="title">成绩</span>
            </el-menu-item> -->
          </el-menu>
        </div>
      </el-aside>
      <el-container>
        <el-header class="main-header" height="48px">
          <el-row>
            <el-col :span="18">
              <div class="itemBox" v-show="showItem">
                <el-select size="mini" v-model="sltItem" placeholder="请选择项目" @change="selectItem">
                  <el-option v-for="item in itemList" :key="item.xmid" :label="item.xmmc" :value="item.xmid">
                  </el-option>
                </el-select>
              </div>
              <div class="piciBox" v-show="showPici">
                <el-select size="mini" v-model="sltPici" placeholder="请选择批次" @change="selectPici">
                  <el-option v-for="item in piciList" :key="item.pcdm" :label="item.pcmc" :value="item.pcdm">
                  </el-option>
                </el-select>
              </div>
              <div class="kemuBox" v-show="showKemu">
                <el-select size="mini" v-model="sltKemu" placeholder="请选择科目" @change="selectKemu">
                  <el-option v-for="item in kemuList" :key="item.kmdm" :label="item.kmmc" :value="item.kmdm">
                  </el-option>
                </el-select>
              </div>
              &nbsp;
            </el-col>
            <el-col :span="5" class="algRt">
              <div v-if="user">
                <span v-if="user.sltJs">
                  {{ user.sltJs }} -
                </span>
                <span>
                  &nbsp;{{ user.userName }}
                </span>
              </div>
              <div v-else>&nbsp;</div>
            </el-col>
            <el-col :span="1" class="algRt">
              <el-button type="text" style="color: #fff;" @click="logoutFun">
                <i class="el-icon-lock"></i> 退出
              </el-button>
            </el-col>
          </el-row>
        </el-header>
        <el-main>
          <router-view @on-updateData="updateMainFun($event)"></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { crudFun } from '@/libs/crud';
import Lazy from 'lazy.js';

let that = null;
let logoutUrl = '/logout';
let getPcByXmidUrl = '/pc/getPcByXMID'; //通过项目ID查询项目所属的批次
let rwfpListXmidUrl = '/rwfp/list/'; //查询批次下面的科目
// let xmListUrl = '/xm/list'; //项目列表的查询
let userCdtlUrl = '/user/getCredentials';
// let jueseCode = ['xtgly', 'xmfzr', 'dqfzr', 'kmfzr', 'zcjs', 'tmzz', 'pjjs', 'spjs', 'kgthfy']; //角色代码排序
export default {
  name: 'MainView',
  data() {
    return {
      isCollapse: false,
      itemList: [],
      sltItem: '',
      piciList: [],
      sltPici: '',
      kemuList: [],
      sltKemu: '',
      menuArr: [
        {
          index: 11,
          name: '单位管理',
          url: '/orgmanage',
        },
        {
          index: 12,
          name: '用户管理',
          url: '/usermanage',
        },
        {
          index: 13,
          name: '考试项目管理',
          url: '/itemmanage',
        },
        {
          index: 14,
          name: '定时任务',
          url: '/timedtask',
        },
        {
          index: 21,
          name: '批次管理',
          url: '/taskmanage',
        },
        {
          index: 22,
          name: '学生编排数据',
          url: '/stumanage',
        },
        // {
        //   index: 23,
        //   name: '任务分配',
        //   url: '/taskdist',
        // },
        {
          index: 231,
          name: '项目负责人',
          url: '/taskxmfzr',
        },
        {
          index: 232,
          name: '大区负责人',
          url: '/taskdqfzr',
        },
        {
          index: 233,
          name: '科目负责人',
          url: '/taskkmfzr',
        },
        {
          index: 234,
          name: '小题组长',
          url: '/taskxtzz',
        },
        {
          index: 235,
          name: '试评',
          url: '/trymark',
        },
        {
          index: 24,
          name: '进度',
          url: '/taskschedule',
        },
        {
          index: 25,
          name: '填空答案仲裁',
          url: '/taskfillzc',
        },
        {
          index: 3,
          name: '评卷',
          url: '/netmarking',
          sub: null
        },
        {
          index: 4,
          name: '成绩',
          url: '/score',
          sub: null
        }
      ],
      activeIndex: '',
      openMenu: [],
      renderComponent: true,
      roleObj: {
        xtgly: [
          {
            index: 11,
            name: '单位管理',
            url: '/orgmanage',
            icon: 'el-icon-office-building'
          },
          {
            index: 13,
            name: '考试项目管理',
            url: '/itemmanage',
            icon: 'el-icon-setting'
          },
          {
            index: 12,
            name: '用户管理',
            url: '/usermanage',
            icon: 'el-icon-user'
          },
        ],
        xmfzr: [
          // {
          //   index: 13,
          //   name: '考试项目管理',
          //   url: '/itemmanage',
          // },
          {
            index: 21,
            name: '批次管理',
            url: '/taskmanage',
            icon: 'el-icon-setting'
          },
          {
            index: 22,
            name: '学生编排数据',
            url: '/stumanage',
            icon: 'el-icon-pie-chart'
          },
          {
            index: 231,
            name: '任务分配',
            url: '/taskxmfzr',
            icon: 'el-icon-edit-outline'
          },
          {
            index: 24,
            name: '进度',
            url: '/taskschedule',
            icon: 'el-icon-finished'
          }
        ],
        dqfzr: [
          {
            index: 232,
            name: '任务分配',
            url: '/taskdqfzr',
            icon: 'el-icon-edit-outline'
          },
          {
            index: 24,
            name: '进度',
            url: '/taskschedule',
            icon: 'el-icon-finished'
          }
        ],
        kmfzr: [
          {
            index: 233,
            name: '任务分配',
            url: '/taskkmfzr',
            icon: 'el-icon-edit-outline'
          },
          {
            index: 24,
            name: '进度',
            url: '/taskschedule',
            icon: 'el-icon-finished'
          },
          {
            index: 4,
            name: '成绩',
            url: '/score',
            icon: 'el-icon-medal-1',
            sub: null
          }
        ],
        tmzz: [
          {
            index: 234,
            name: '任务分配',
            url: '/taskxtzz',
            icon: 'el-icon-edit-outline'
          },
          {
            index: 235,
            name: '试评',
            url: '/trymark',
            icon: 'el-icon-edit'
          },
          {
            index: 24,
            name: '进度',
            url: '/taskschedule',
            icon: 'el-icon-finished'
          },
          {
            index: 25,
            name: '填空答案仲裁',
            url: '/taskfillzc',
            icon: 'el-icon-folder-checked'
          }
        ],
        pjjs: [
          {
            index: 3,
            name: '评卷',
            url: '/netmarking',
            icon: 'el-icon-edit',
            sub: null
          }
        ],
        zcjs: [
          {
            index: 24,
            name: '进度',
            url: '/taskschedule',
            icon: 'el-icon-finished'
          },
          {
            index: 25,
            name: '填空答案仲裁',
            url: '/taskfillzc',
            icon: 'el-icon-folder-checked'
          }
        ],
        spjs: [
          {
            index: 24,
            name: '进度',
            url: '/taskschedule',
            icon: 'el-icon-finished'
          },
        ],
        kgthfy: [

        ]
      },
      roleMenu: [],
      showItem: true,
      showPici: false,
      showKemu: false,
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  methods: {
    ...mapMutations([
      'updateItemInfo',
      'updatePiciInfo',
      'updateKemuInfo',
      'updatePcszInfo',
      'updateKemuArr',
      'updateUserInfo'
    ]),
    //初始化页面
    goToPage() {
      let crtUrl = location.pathname;
      if (crtUrl == '/main') {
        let usrMenu = that.roleMenu;
        if (usrMenu && usrMenu.length > 0) {
          let menuOne = usrMenu[0];
          let pathName = menuOne.url;
          that.activeIndex = menuOne.index + '';
          that.$router.push({ path: pathName });
        }
      }
    },
    //查询项目数据
    getItem(hasItem) {
      that.itemList = [];
      new Promise(function (resolve, reject) {
        if (hasItem) {
          that.itemList = that.user.jueseArr;
          resolve();
        }
        else {
          let roleCongig = {
            url: userCdtlUrl,
            method: 'get',
            params: {}
          }
          crudFun(roleCongig).then((data) => {
            if (data) {
              let xmArr = [];
              let groupByXmid = Lazy(data).groupBy('xmid').toObject();
              Lazy(groupByXmid).each(function (v, k, l) {
                let xmTmp = {
                  xmid: k,
                  xmmc: '',
                  jsdm: '',
                  jsmc: '',
                  pcArr: []
                };
                if (k != 'null' && v && v.length > 0) {
                  let groupByPcdm = Lazy(v).groupBy('pcdm').toObject();
                  xmTmp.xmmc = v[0].xmmc;
                  xmTmp.jsdm = v[0].jsdm;
                  xmTmp.jsmc = v[0].jsmc;
                  Lazy(groupByPcdm).each(function (v1, k1, l1) {
                    let pcTmp = {
                      pcdm: k1,
                      pcmc: '',
                      jsdm: '',
                      jsmc: '',
                      kmArr: []
                    }
                    if (k1 != 'null' && v1 && v1.length > 0) {
                      let groupByKmdm = Lazy(v1).groupBy('kmdm').toObject();
                      pcTmp.pcmc = v1[0].pcmc;
                      pcTmp.jsdm = v1[0].jsdm;
                      pcTmp.jsmc = v1[0].jsmc;
                      Lazy(groupByKmdm).each(function (v2, k2, l2) {
                        if (k2 != 'null' && v2 && v2.length > 0) {
                          pcTmp.kmArr.push(v2[0]);
                        }
                      });
                      xmTmp.pcArr.push(pcTmp);
                    }
                  });
                  xmArr.push(xmTmp);
                }
              });
              that.itemList = xmArr;
            }
            resolve();
          });
        }
      })
        .then(function () {
          if (that.itemList && that.itemList.length > 0) {
            if (!that.sltItem) {
              that.sltItem = that.itemList[0].xmid;
            }
            that.selectItem(that.sltItem);
          }
          else {
            that.sltItem = null;
            that.selectItem(null);
          }
        });
      // let roleCongig = {
      //   url: userCdtlUrl,
      //   method: 'get',
      //   params: {}
      // }
      // crudFun(roleCongig).then((data) => {
      //   if (data) {
      //     let itemArr = Lazy(data).filter(function (js) {
      //       return js.jsdm == that.user.sltJsdm && js.xmid;
      //     }).uniq('xmid').toArray();
      //     if (itemArr && itemArr.length > 0) {
      //       that.itemList = itemArr;
      //       if (!that.sltItem) {
      //         that.sltItem = that.itemList[0].xmid;
      //       }
      //       that.selectItem(that.sltItem);
      //     }
      //     else {
      //       that.sltItem = null;
      //       that.selectItem(null);
      //     }
      //   }
      // });
    },
    //项目选择
    selectItem(val) {
      if(!that.user){
        return ;
      }
      that.piciList = [];
      that.kemuList = [];
      that.sltPici = null;
      that.sltKemu = null;
      let usrCopy = that.$comn.cloneObj(that.user);
      let sltJsdm = null;
      let roleName = usrCopy.roles[0] || null;
      if (val) {
        let fndItem = Lazy(that.itemList).find(function (item) {
          return item.xmid == val;
        });
        if (fndItem) {
          usrCopy.sltJs = fndItem.jsmc;
          usrCopy.sltJsdm = fndItem.jsdm;
          that.piciList = fndItem.pcArr;
        }
        if (!fndItem && that.itemList && that.itemList.length > 0) {
          let sltXm = that.itemList[0];
          val = sltXm.xmid;
          that.sltItem = val;
          usrCopy.sltJs = sltXm.jsmc;
          usrCopy.sltJsdm = sltXm.jsdm;
          that.piciList = sltXm.pcArr;
        }
        let itemCopy = that.$comn.cloneObj(val);
        that.updateItemInfo(itemCopy);
        that.updateUserInfo(usrCopy);
        sltJsdm = usrCopy.sltJsdm;
        if (sltJsdm) {
          let sltMenuArr = that.roleObj[sltJsdm];
          switch (sltJsdm) {
            case 'xtgly':
              that.showItem = false;
              that.showPici = false;
              that.showKemu = false;
              break;
            case 'xmfzr':
            case 'dqfzr':
              that.showPici = true;
              that.showKemu = false;
              break;
            case 'kmfzr':
            case 'tmzz':
            case 'pjjs':
            case 'zcjs':
            case 'spjs':
              that.showPici = true;
              that.showKemu = true;
              break;
          }
          if (sltMenuArr && sltMenuArr) {
            that.roleMenu = sltMenuArr;
          }
          else {
            that.$message({
              showClose: true,
              message: '没有可以操作的模块！',
              type: 'error'
            });
            return;
          }
        }
        else {
          that.$message({
            showClose: true,
            message: '缺少角色代码！',
            type: 'error'
          });
          return;
        }
        if (that.piciList && that.piciList.length > 0) {
          that.getPici('hasPc');
        }
        else {
          that.getPici();
        }
      }
      else {
        if (roleName && roleName == 'xtgly') {
          let sltMenuArr = that.roleObj['xtgly'];
          that.roleMenu = sltMenuArr;
          usrCopy.sltJs = '系统管理员';
          usrCopy.sltJsdm = 'xtgly';
          that.updateUserInfo(usrCopy);
        }
        that.updateItemInfo(null);
        that.goToPage();
      }
    },
    //查询批次
    getPici(hasPc) {
      new Promise(function (resolve, reject) {
        if (hasPc) {
          resolve();
        }
        else {
          let gtCfg = {
            method: 'get',
            url: getPcByXmidUrl,
            params: {
              xmid: null
            }
          };
          if (that.sltItem) {
            gtCfg.params.xmid = that.sltItem;
          }
          else {
            that.$message({
              showClose: true,
              message: '请选择项目！',
              type: 'error'
            });
            return;
          }
          that.piciList = [];
          crudFun(gtCfg).then((data) => {
            if (data && data.length > 0) {
              that.piciList = data;
              // if (!that.sltPici) {
              //   that.sltPici = that.piciList[0].pcdm;
              // }
              // that.selectPici(that.sltPici);
            }
            // else {
            //   that.sltPici = null;
            //   that.selectPici(null);
            // }
            resolve();
          });
        }
      })
        .then(function () {
          if (that.piciList && that.piciList.length > 0) {
            if (!that.sltPici) {
              that.sltPici = that.piciList[0].pcdm;
            }
            that.selectPici(that.sltPici);
          }
          else {
            that.sltPici = null;
            that.selectPici(null);
          }
        });
    },
    //批次选择
    selectPici(pcVal) {
      if (pcVal) {
        let fndPc = Lazy(that.piciList).find(function (pc) {
          return pc.pcdm == pcVal;
        });
        let pcszObj = {};
        if (fndPc) {
          let pcszStr = fndPc.pcsz;
          pcszObj = that.$comn.toObj(pcszStr);
          that.kemuList = fndPc.kmArr;
        }
        else {
          if (that.piciList && that.piciList.length > 0) {
            pcVal = that.piciList[0].pcdm;
            that.sltPici = pcVal;
            that.kemuList = that.piciList[0].kmArr;
          }
        }
        that.updatePcszInfo(pcszObj);
        let piciCopy = that.$comn.cloneObj(pcVal);
        that.updatePiciInfo(piciCopy);
        if (that.kemuList && that.kemuList.length > 0) {
          that.getTask('hasKm');
        }
        else {
          that.getTask();
        }
      }
      else {
        that.updatePiciInfo(null);
        that.goToPage();
      }
    },
    // 查询批次下面的科目
    getTask(hasKm) {
      new Promise(function (resolve, reject) {
        if (hasKm) {
          resolve();
        }
        else {
          let gtCfg = {
            method: 'post',
            url: rwfpListXmidUrl,
            data: {
              "pageNo": 1,
              "pageSize": 1000
            }
          };
          if (that.sltPici) {
            gtCfg.url += that.sltPici;
          }
          else {
            that.$message({
              showClose: true,
              message: '请选择批次！',
              type: 'error'
            });
            return;
          }
          gtCfg.url += '/0';
          that.kemuList = [];
          crudFun(gtCfg).then((data) => {
            if (data) {
              let kemuArr = data.records;
              if (kemuArr && kemuArr.length > 0) {
                that.kemuList = kemuArr;
                // if (!that.sltKemu) {
                //   that.sltKemu = that.kemuList[0].kmdm;
                // }
                // that.updateKemuArr(that.kemuList);
                // that.selectKemu(that.sltKemu);
              }
              // else {
              //   that.sltKemu = null;
              //   that.selectKemu(null);
              // }
            }
            resolve();
          });
        }
      })
        .then(function () {
          if (that.kemuList && that.kemuList.length > 0) {
            if (!that.sltKemu) {
              that.sltKemu = that.kemuList[0].kmdm;
            }
            that.updateKemuArr(that.kemuList);
            that.selectKemu(that.sltKemu);
          }
          else {
            that.sltKemu = null;
            that.selectKemu(null);
          }
        });
    },
    //科目选择
    selectKemu(kmVal) {
      if (kmVal) {
        let fndKm = Lazy(that.kemuList).find(function (item) {
          return item.kmdm == kmVal;
        });
        if (!fndKm && that.kemuList && that.kemuList.length > 0) {
          kmVal = that.kemuList[0].kmdm;
          that.sltKemu = kmVal;
        }
        let kemuCopy = that.$comn.cloneObj(that.sltKemu);
        that.updateKemuInfo(kemuCopy);
      }
      else {
        that.updateKemuInfo(null);
      }
      that.goToPage();
    },
    // 菜单选择
    selectMenu(key, keyPath) {
      that.activeIndex = key;
      let fndMenu = Lazy(that.menuArr).find(function (menu) {
        return menu.index == key;
      });
      if (fndMenu) {
        let atvPath = location.pathname;
        let menuUrl = fndMenu.url;
        if (menuUrl != atvPath) {
          that.$router.push({ path: menuUrl });
        }
      }
    },
    //强制刷新dom
    forceRerender(tp) {
      that.renderComponent = false;
      that.$nextTick().then(() => {
        that.renderComponent = true;
      });
    },
    //退出登陆
    logoutFun() {
      let config = {
        url: logoutUrl,
        method: 'get'
      };
      crudFun(config).then((data) => {
        if (data) {
          that.$comn.localStg('clear');
          that.$router.push({ path: '/login' });
          // that.$router.push({ path: '/tatlogin' });
        }
      });
    },
    //更新数据
    updateMainFun(parm) {
      if (parm) {
        let upType = parm.type;
        switch (upType) {
          case 'item':
            that.getItem();
            break;
          case 'batch':
            that.getPici();
            break;
          case 'subject':
            that.getTask();
            break;
        }
      }
    }
  },
  mounted() {
    if (that.user && that.user.jueseArr && that.user.jueseArr.length > 0) {
      // that.itemList = that.jueseArr;
      that.getItem('hasItem');
    }
    else {
      that.getItem();
    }
    // that.$router.push({ path: '/orgmanage' });
  },
  created() {
    that = this;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.main,
.mainCont {
  height: 100%;
}

.mainAside {
  background-color: rgb(17, 99, 166);
}

.main-header {
  background-color: rgb(17, 99, 166);
  height: 48px;
  color: #fff;
  line-height: 48px;
}

.asdLogo {
  font-size: 32px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: #fff;
  // img {
  //   width: 94%;
  //   margin-left: 3%;
  //   margin-top: 12px;
  // }
}

.itemBox,
.piciBox,
.kemuBox {
  display: inline-block;
  margin-right: 30px;
}

.itemBox::before {
  content: '项目：';
}

.piciBox::before {
  content: '批次：';
}

.kemuBox::before {
  content: '科目：';
}

.el-menu {
  border-right: 0;
}
.menu-icon{
  float: left;
  font-size: 16px;
  margin-top: 20px;
  margin-right: 5px;
}
.is-active {
  .menu-icon{
    color: rgb(255, 208, 75) !important;
  }
}

.el-menu-vertical-demo {

  .el-submenu__title i,
  .el-menu-item i {
    color: #fff;
  }
}
</style>
