<template>
  <div class="about">
    <!-- <el-button class="ml30" size="small" type="primary" @click="getTask">查询任务</el-button> -->
    <!-- 任务详情页面 -->
    <div v-if="xtzzStep == 'rwList'">
      <div class="renwu" v-for="task in taskData" :key="task.id">
        <h3 class="rwTitle">
          <el-row>
            <el-col :span="3">任务编号 {{ task.taskNo }}</el-col>
            <el-col :span="5">
              <span v-if="task.taskStatus == 0">等待设置</span>
              <span v-else>评卷中</span>
            </el-col>
            <el-col :span="16" class="algRt">
              <el-button type="primary" size="small" v-if="task.taskStatus == 0"
                @click="startYueJuan(task)">开始</el-button>
              <el-button type="primary" size="small" v-else @click="stopYueJuan(task)">暂停评卷</el-button>
            </el-col>
          </el-row>
          <table class="mt10 dscrtTable">
            <tr>
              <td class="dscrtTd">
                题号
              </td>
              <td>
                <el-button type="text" size="mini" v-for="tm in task.questionData" :key="tm.questionNo" @click="seeTiMu(tm)">
                  {{ tm.questionNo }}
                </el-button>
                <!-- <span>{{ task.timuNo.join('、') }}</span> -->
                <!-- <el-button type="text" size="mini" class="ml15 flr" @click="seeTiMu(task)">查看原题</el-button> -->
              </td>
              <td class="dscrtTd">
                总分
              </td>
              <td>
                <span v-if="task.sumScore">{{ task.sumScore }} 分</span>
              </td>
              <td class="dscrtTd">
                分数阈值
              </td>
              <td>
                <span v-if="task.threshold">{{ task.threshold }} 分</span>
              </td>
              <td class="dscrtTd">
                任务量
              </td>
              <td>
                {{ task.taskCount }}
              </td>
            </tr>
            <tr>
              <td class="dscrtTd">
                未分配任务量
              </td>
              <td>
                {{ task.notDispatchTaskCount }}
              </td>
              <td class="dscrtTd">
                已分配任务量
              </td>
              <td>
                {{ task.dispatchTaskCount }}
              </td>
              <td class="dscrtTd">
                已完成任务量
              </td>
              <td colspan="3">
                {{ task.finishTaskCount }}
              </td>
              <!-- <td class="dscrtTd">
                试评数量
              </td>
              <td>
                <span>{{ task.spno }}</span>
                <el-button type="text" size="mini" class="ml15" @click="tryPing">试评</el-button>
              </td> -->
            </tr>
            <tr>
              <td class="dscrtTd">
                评卷教师人数
              </td>
              <td>
                <el-link class="ftz18" type="primary" @click="seePjjs(task)">
                  {{ task.scoreUser ? task.scoreUser.length : 0}}
                </el-link>
                <span> 人</span>
                <el-button type="text" size="mini" class="ml15 flr" @click="distRenWu(task)">分配任务</el-button>
              </td>
              <td>
                <el-checkbox v-model="task.allowHalf">允许 0.5 分</el-checkbox>
              </td>
              <td colspan="5">
                <el-checkbox v-model="task.fillQuestionHalf">填空题允许给半对</el-checkbox>
              </td>
            </tr>
            <tr>
              <td class="dscrtTd">
                仲裁任务量
              </td>
              <td>
                {{ task.arbitrateTaskCount || 0 }}
              </td>
              <td class="dscrtTd">
                仲裁已完成
              </td>
              <td>
                {{ task.finishArbitrateTaskCount || 0 }}
              </td>
              <td class="dscrtTd">
                仲裁教师
              </td>
              <td colspan="3">
                <span>（共 {{ task.arbitrateUser ? task.arbitrateUser.length : 0 }} 人）</span>
                <p class="dpIb m0" v-if="task.arbitrateUser">
                  <span v-for="(usr, idx) in task.arbitrateUser" :key="usr.id">
                    {{ usr.xm }}<font v-if="task.arbitrateUser.length - 1 != idx">、</font>
                  </span>
                </p>
                <el-button type="text" size="mini" class="ml15 flr" @click="setZcSpJsPop('zcjs', task)">设定仲裁教师</el-button>
              </td>
            </tr>
            <!-- <tr v-if="task.threeTaskCount"> -->
            <tr>
              <td class="dscrtTd">
                三评任务量
              </td>
              <td>
                {{ task.threeTaskCount || 0}}
              </td>
              <td class="dscrtTd">
                三评已完成
              </td>
              <td>
                {{ task.finishThreeTaskCount || 0}}
              </td>
              <td class="dscrtTd">
                三评教师
              </td>
              <td colspan="3">
                <span>（共 {{ task.threeUser ? task.threeUser.length : 0 }} 人）</span>
                <p class="dpIb m0" v-if="task.threeUser">
                  <span v-for="(usr, idx) in task.threeUser" :key="usr.id">
                    {{ usr.xm }}<font v-if="task.threeUser.length - 1 != idx">、</font>
                  </span>
                </p>
                <el-button type="text" size="mini" class="ml15 flr" @click="setZcSpJsPop('spjs', task)">设定三评教师</el-button>
              </td>
            </tr>
          </table>
        </h3>
      </div>
    </div>
    <!-- 分配任务 -->
    <div v-if="xtzzStep == 'rwDist'">
      <div>
        <span class="ftz18 fll" style="margin-top: 3px;">任务编号 {{ selectTask.taskNo }}</span>
        <span class="fll ml30" style="margin-top: 7px;">可调配任务量： {{ fprwNums }}</span>
        <!-- <el-button class="ml30" size="small" type="primary" @click="seeTiMu">查看原题</el-button> -->
        <el-button class="ml30" size="small" type="primary" @click="recoveryUndoTask(selectTask)">回收未完成任务</el-button>
        <el-button class="ml15" size="small" type="primary" @click="divideTaskEqually(selectTask)">平分调配任务</el-button>
        <el-button class="flr" size="small" @click="backTo('rwList', true)">返回</el-button>
      </div>
      <el-table class="mt15" border :data="fprwUsrData" style="width: 100%;" @selection-change="handleTechChange">
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column prop="xm" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="jsmc" label="工作性质" align="center">
        </el-table-column>
        <el-table-column prop="taskCount" label="评卷任务" align="center">
        </el-table-column>
        <el-table-column prop="finishTaskCount" label="已完成任务" align="center">
        </el-table-column>
        <el-table-column prop="notFinishTaskCount" label="未完成任务" align="center">
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button @click="handAdjustTask(scope.row)" type="text" size="small">手动调节</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 查看评卷教师名单 -->
    <div v-if="xtzzStep == 'rwTech'">
      <div>
        <span class="ftz18" style="margin-top: 3px;">任务编号 {{ selectTask.taskNo }}</span>
        <!-- <el-button class="ml30" size="small" type="primary">查看原题</el-button> -->
        <el-button class="flr" size="small" @click="backTo('rwList')">返回</el-button>
      </div>
      <el-table class="mt15" border :data="pjjsUsrData" style="width: 100%;">
        <el-table-column prop="xm" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="jsmc" label="角色" align="center">
        </el-table-column>
        <el-table-column prop="finishTaskCount" label="已完成任务" align="center">
        </el-table-column>
        <el-table-column prop="" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.enabled == 1">启用</span>
            <span v-else>停用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button @click="teacherUsed(scope.row)" type="text" size="small">
              <span v-if="scope.row.enabled == 1">停用</span>
              <span v-else>启用</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <!-- 手动调整任务 -->
      <el-dialog title="调整任务" modal :close-on-click-modal="false" :show-close="false" :visible.sync="tzrwVisible">
        <el-form :model="tzrwParm" label-width="140px" style="width: 90%;">
          <el-form-item label="评卷任务">
            <span>{{ tzrwParm.taskCount }}</span>
          </el-form-item>
          <el-form-item label="已完成">
            <span>{{ tzrwParm.finishTaskCount }}</span>
          </el-form-item>
          <el-form-item label="未完成">
            {{ tzrwParm.notFinishTaskCount }}
          </el-form-item>
          <el-form-item label="调整任务" v-if="selectTask">
            <el-input-number size="small" v-model="handTzrwVal" :min="1" :max="fprwNums"></el-input-number>
            <span> 0 ~ {{ fprwNums }} </span>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handAdjustTask('cancel')">取 消</el-button>
          <el-button class="ml15" type="primary" @click="saveTaskAdjust()">保 存</el-button>
        </div>
      </el-dialog>
      <!-- 引入题目子组件 -->
      <el-dialog title="查看题目" :visible.sync="tiMuVisible" width="50%" center>
        <TimuView v-if="tiMuVisible" :passTimu="selectTimu" @timuEvent="timuPageClose"></TimuView>
      </el-dialog>
      <!-- 设定人员列表 -->
      <el-dialog title="" modal :close-on-click-modal="false" :show-close="false" :visible.sync="addTechVisible">
        <div slot="title">
          <span class="ftz18" v-if="setSltJueSe == 'zcjs'">设定仲裁教师</span>
          <span class="ftz18" v-else>设定三评教师</span>
        </div>
        <div>
          <el-table ref="techTable" :data="setUsrData" tooltip-effect="dark" style="width: 100%"
            @selection-change="handleTechChange" height="400">
            <el-table-column type="selection" width="55" :selectable="handleDisable">
            </el-table-column>
            <el-table-column prop="xm" label="姓名" width="140">
            </el-table-column>
            <el-table-column prop="jsmc" label="角色" show-overflow-tooltip>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveSetSelectUsr()">确 定</el-button>
          <el-button class="ml15" @click="setZcSpJsPop('cancel')">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Lazy from 'lazy.js';
import TimuView from './TimuView.vue';
import { crudFun } from '@/libs/crud';

let that = null;
let getTaskInfoUrl = '/paper/getTaskInfo'; //小题组长任务分配-获取任务信息
// let getTaskInfoUrl = '/paper/getDispatchTeacherInfo'; //小题组长任务分配-获取任务信息
let setZcAndSpUsrUrl = '/paper/setArbitrateAndThreeUser'; //设置仲裁教师和三评教师
let getTaskTeachersUrl = '/paper/getTaskTeachers'; //获取评卷人员名单
let startYueJuanUrl = '/paper/grading'; //开始阅卷
let stopYueJuanUrl = '/paper/stopGrading'; //暂停阅卷
let getTaskTechInfoUrl = '/paper/getDispatchTeacherInfo'; //查看评卷教师名单接口
// let getTaskTechInfoUrl = '/paper/getTaskTeacherInfo'; //查看评卷教师名单接口
let startFzrUrl = '/paper/startFZR'; //阅卷教师启用
let stopFzrUrl = '/paper/stopFZR'; //阅卷教师停用
let recoveryTaskUrl = '/paper/recoveryTask'; //回收未完成任务
let bisectTaskUrl = '/paper/bisectTask'; //平分任务
export default {
  name: 'TaskXtzzView',
  components: {
    TimuView
  },
  data() {
    return {
      taskData: [],
      fprwUsrData: [],
      fprwNums: 0, //可分配的任务量
      pjjsUsrData: [],
      xtzzStep: 'rwList', // 任务详情（rwList）; 分配任务（rwDist）; 查看教师（rwTech）
      tzrwVisible: false,
      tzrwParm: {},
      tiMuVisible: false,
      selectTimu: null,
      setSltJueSe: null,
      addTechVisible: false,
      mtplSltUsr: [], //选中的用户
      setUsrData: [], //设定教师的用户数据
      selectTask: null, //选中的任务
      handTzrwVal: null //手动调整任务数量
    }
  },
  computed: {
    ...mapState([
      'mvSltPici',
      'mvSltKemu'
    ])
  },
  methods: {
    //查询自己的任务
    getTask() {
      let tkCfg = {
        method: 'post',
        url: getTaskInfoUrl,
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu
        }
      };
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      that.taskData = [];
      crudFun(tkCfg).then((data) => {
        if (data) {
          Lazy(data).each(function (task) {
            task.timuNo = [];
            if (task.questionData) {
              task.questionData = that.$comn.toObj(task.questionData);
              task.timuNo = Lazy(task.questionData).map(function (tm) {
                return tm.questionNo;
              }).toArray();
            }
            if (task.groupUser) {
              task.groupUser = that.$comn.toObj(task.groupUser);
            }
            if (task.scoreUser) {
              task.scoreUser = that.$comn.toObj(task.scoreUser);
            }
            if (task.arbitrateUser) {
              task.arbitrateUser = that.$comn.toObj(task.arbitrateUser);
            }
            if (task.threeUser) {
              task.threeUser = that.$comn.toObj(task.threeUser);
            }
            task.allowHalf = task.allowHalf == 1 ? true : false;
            task.fillQuestionHalf = task.fillQuestionHalf == 1 ? true : false;
          });
          that.taskData = Lazy(data).sortBy('taskNo').toArray();
        }
      });
    },
    // 分配任务
    distRenWu(task) {
      let pjCfg = {
        method: 'post',
        url: getTaskTechInfoUrl,
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu,
          "taskNo": ""
        }
      };
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (!task) {
        mis.push('任务信息');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      that.mtplSltUsr = [];
      that.selectTask = task;
      pjCfg.data.taskNo = task.taskNo;
      crudFun(pjCfg).then((data) => {
        if (data) {
          that.fprwNums = data.dispatchTaskCount;
          that.fprwUsrData = Lazy(data.teachers).filter(function(tech){
            return tech.jsdm == 'pjjs';
          }).toArray();
        }
        that.xtzzStep = 'rwDist';
      });
    },
    //查询仲裁教师和三评教师
    getZcSpTech() {
      let gzsCfg = {
        method: 'post',
        url: getTaskTeachersUrl,
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu,
          "taskNo": ""
        }
      };
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (!that.selectTask) {
        mis.push('任务信息');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      gzsCfg.data.taskNo = that.selectTask.taskNo;
      crudFun(gzsCfg).then((data) => {
        if (data) {
          let suitUsrs = [];
          if (that.setSltJueSe == 'zcjs') {
            // that.setUsrData = data;
            setTimeout(function () {
              Lazy(data).each(function (usr) {
                if (usr.jsdm == 'tmzz') {
                  suitUsrs.push(usr);
                  that.$refs.techTable.toggleRowSelection(usr);
                }
                if (usr.jsdm == 'zcjs') {
                  suitUsrs.push(usr);
                  that.$refs.techTable.toggleRowSelection(usr);
                }
              });
            }, 200);
          }
          else if (that.setSltJueSe == 'spjs') {
            // that.setUsrData = Lazy(data).reject(function (usr) {
            //   return usr.jsdm == 'tmzz';
            // }).toArray();
            // that.setUsrData = Lazy(data).filter(function (usr) {
            //   return usr.jsdm == 'spjs';
            // }).toArray();
            setTimeout(function () {
              Lazy(data).each(function (usr) {
                if (usr.jsdm == 'spjs') {
                  suitUsrs.push(usr);
                  that.$refs.techTable.toggleRowSelection(usr);
                }
              });
            }, 200);
          }
          that.setUsrData = suitUsrs;
        }
      })
    },
    //设定仲裁教师和三评教师
    setZcSpJsPop(tp, task) {
      if (tp && tp == 'cancel') {
        that.selectTask = null;
        that.addTechVisible = false;
        that.mtplSltUsr = [];
      }
      else {
        that.setSltJueSe = tp;
        that.selectTask = task;
        that.addTechVisible = true;
        that.mtplSltUsr = [];
        that.getZcSpTech();
      }
    },
    //选择负责人
    handleTechChange(val) {
      that.mtplSltUsr = val;
    },
    //三评仲裁教师选择保存
    saveSetSelectUsr() {
      let szCfg = {
        method: 'post',
        url: setZcAndSpUsrUrl,
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu,
          "taskNo": "",
          "jsdm": that.setSltJueSe,
          "ids": ""
        }
      };
      let mtpSuCopy = that.$comn.cloneObj(that.mtplSltUsr);
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (!that.setSltJueSe) {
        mis.push('角色代码');
      }
      if (!that.selectTask) {
        mis.push('任务信息');
      }
      // if (mtpSuCopy && mtpSuCopy.length > 0) {
      //   if (that.setSltJueSe == 'zcjs') {
      //     mtpSuCopy = Lazy(mtpSuCopy).reject(function (usr) {
      //       return usr.jsdm == 'tmzz';
      //     }).toArray();
      //     if (!(mtpSuCopy && mtpSuCopy.length > 0)) {
      //       mis.push('用户信息');
      //     }
      //   }
      // }
      // else {
      //   mis.push('用户信息');
      // }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      szCfg.data.taskNo = that.selectTask.taskNo;
      szCfg.data.ids = Lazy(mtpSuCopy).map(function (usr) {
        return usr.id;
      }).toArray();
      crudFun(szCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '设置成功！',
            type: 'success'
          });
          that.setZcSpJsPop('cancel');
          that.getTask();
        }
      })
    },
    //设置表格的不可编辑
    handleDisable(row, index) {
      if (row.jsdm == 'tmzz') {
        return false
      } else {
        return true
      }
    },
    //返回
    backTo(tp, bl) {
      that.xtzzStep = tp;
      if(bl){
        that.getTask();
      }
    },
    // 手动调整任务
    handAdjustTask(row) {
      if (row == 'cancel') {
        that.tzrwVisible = false;
      }
      else {
        that.tzrwParm = row;
        that.tzrwParm.tzrwVal = '';
        if (!that.selectTask) {
          that.$message({
            showClose: true,
            message: '缺少任务信息！',
            type: 'error'
          });
          return;
        }
        that.tzrwVisible = true;
      }
    },
    //保存任务调整
    saveTaskAdjust() {
      let rcCfg = {
        method: 'post',
        url: bisectTaskUrl,
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu,
          "taskNo": "",
          "dispatchCount": ""
        }
      };
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (!that.selectTask) {
        mis.push('任务信息');
      }
      if (that.tzrwParm && that.tzrwParm.userId) {
        rcCfg.data.userIds = [];
        rcCfg.data.userIds.push(that.tzrwParm.userId);
      }
      else {
        mis.push('用户信息');
      }
      if(that.handTzrwVal){
        rcCfg.data.dispatchCount = that.handTzrwVal;
      }
      else{
        mis.push('任务数量');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      rcCfg.data.taskNo = that.selectTask.taskNo;
      crudFun(rcCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '任务调整成功！',
            type: 'success'
          });
        }
        that.distRenWu(that.selectTask);
        that.tzrwVisible = false;
        that.handTzrwVal = null;
      });
    },
    //查看题目
    seeTiMu(row) {
      // let row = task.questionData[0];
      // let row = task.questionData[0];
      row['修改题目'] = false;
      row.hasTmnr = true; //是否有题目内容
      // that.timuPageClose();
      that.selectTimu = row;
      that.tiMuVisible = true;
    },
    //题目页面关闭
    timuPageClose(childValue) {
      that.selectTimu = null;
      that.tiMuVisible = false;
    },
    //查看评卷教师
    seePjjs(task) {
      let pjCfg = {
        method: 'post',
        url: getTaskTechInfoUrl,
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu,
          "taskNo": ""
        }
      };
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (!task) {
        mis.push('任务信息');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      that.selectTask = task;
      pjCfg.data.taskNo = task.taskNo;
      crudFun(pjCfg).then((data) => {
        if (data) {
          that.pjjsUsrData = data;
        }
        that.xtzzStep = 'rwTech';
      });
    },
    //开启阅卷
    startYueJuan(task) {
      let stCfg = {
        method: 'get',
        url: startYueJuanUrl,
        params: {
          "taskId": "",
          "allowHalf": "",
          "fillQuestionHalf": ""
        }
      };
      if (task) {
        stCfg.params.taskId = task.id;
        stCfg.params.allowHalf = task.allowHalf ? 1 : 0;
        stCfg.params.fillQuestionHalf = task.fillQuestionHalf ? 1 : 0;
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择任务',
          type: 'error'
        });
        return;
      }
      crudFun(stCfg).then((data) => {
        if (data) {
          task.taskStatus = 1;
          that.$message({
            showClose: true,
            message: '阅卷任务已开启！',
            type: 'success'
          });
        }
      });
    },
    //暂停阅卷
    stopYueJuan(task) {
      let stCfg = {
        method: 'get',
        url: stopYueJuanUrl,
        params: {
          "taskId": ""
        }
      };
      if (task) {
        stCfg.params.taskId = task.id;
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择任务',
          type: 'error'
        });
        return;
      }
      crudFun(stCfg).then((data) => {
        if (data) {
          task.taskStatus = 0;
          that.$message({
            showClose: true,
            message: '阅卷任务已暂停！',
            type: 'success'
          });
        }
      });
    },
    //评卷教师的启用和停用
    teacherUsed(row) {
      let thCfg = {
        method: 'get',
        url: '',
        params: {
          id: ''
        }
      };
      if (row) {
        thCfg.params.id = row.id;
        thCfg.url = row.enabled == 1 ? stopFzrUrl : startFzrUrl;
        crudFun(thCfg).then((data) => {
          if (data) {
            row.enabled = !row.enabled;
            that.$message({
              showClose: true,
              message: '操作成功！',
              type: 'success'
            });
          }
        });
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择人员',
          type: 'error'
        });
      }
    },
    // 回收未完任务
    recoveryUndoTask(task) {
      let rcCfg = {
        method: 'post',
        url: recoveryTaskUrl,
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu,
          "taskNo": "",
          "userIds": ""
        }
      };
      let mis = [];
      let sltUsrArr = that.mtplSltUsr;
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (!task) {
        mis.push('任务信息');
      }
      if (sltUsrArr && sltUsrArr.length > 0) {
        rcCfg.data.userIds = Lazy(sltUsrArr).map(function (usr) {
          return usr.userId;
        }).toArray();
      }
      else {
        mis.push('用户信息');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      rcCfg.data.taskNo = task.taskNo;
      crudFun(rcCfg).then((data) => {
        if (data) {
          // Lazy(that.fprwUsrData).each(function (usr) {
          //   let fndUsr = Lazy(that.mtplSltUsr).find(function (susr) {
          //     return susr.id == usr.id;
          //   });
          //   if (fndUsr) {
          //     usr.taskCount -= usr.notFinishTaskCount;
          //     usr.notFinishTaskCount = 0;
          //   }
          // });
          that.distRenWu(that.selectTask);
          that.$message({
            showClose: true,
            message: '任务回收成功！',
            type: 'success'
          });
        }
      });
    },
    //调配任务
    divideTaskEqually(task) {
      let rcCfg = {
        method: 'post',
        url: bisectTaskUrl,
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu,
          "taskNo": "",
          "dispatchCount": ""
        }
      };
      let mis = [];
      let sltUsrArr = that.mtplSltUsr;
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (!task) {
        mis.push('任务信息');
      }
      if (sltUsrArr && sltUsrArr.length > 0) {
        rcCfg.data.userIds = Lazy(sltUsrArr).map(function (usr) {
          return usr.userId;
        }).toArray();
      }
      else {
        mis.push('用户信息');
      }
      if(that.fprwNums){
        rcCfg.data.dispatchCount = Math.ceil(that.fprwNums / (sltUsrArr.length || 1));
      }
      else{
        mis.push('可分配数量');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      rcCfg.data.taskNo = task.taskNo;
      crudFun(rcCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '任务平均分配成功！',
            type: 'success'
          });
          that.distRenWu(that.selectTask);
        }
      });
    }
  },
  mounted() {
    setTimeout(function () {
      that.getTask();
    }, 500);
  },
  created() {
    that = this;
  }
}
</script>

<style lang="less" scoped>
.dscrtTable {
  width: 100%;
  border: 1px solid #ebeef5;
  font-weight: normal;
  border-collapse: collapse;

  td {
    padding: 10px;
    border: 1px solid #ebeef5;
    font-size: 14px;

    .el-button {
      font-size: 16px;
    }
  }

  .dscrtTd {
    color: #585858;
    background: #fafafa;

  }
}

.renwu {
  margin-bottom: 30px;

  .el-button--mini,
  .el-button--mini.is-round {
    padding: 0;
  }
}
</style>