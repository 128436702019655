<template>
  <div class="about">
    <h1>任务分配</h1>
  </div>
</template>

<script>
// import { mapState, mapMutations } from 'vuex';
import Lazy from 'lazy.js';
let that = null;
export default {
  name: 'TaskDistView',
  data() {
    return {
      
    }
  },
  computed: {
    // ...mapState([
    //   'user'
    // ])
  },
  methods: {
    
  },
  mounted() {

  },
  created() {
    that = this;
  }
}
</script>

<style lang="less" scoped>

</style>