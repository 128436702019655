<template>
  <div class="about">
    <div class="yueJuanPage">
      <div class="timuWrap">
        <div class="tmwTiHao">
          <span>题号 {{ selectTimu ? selectTimu.questionNo : '' }}</span>
        </div>
        <ul class="zdxhList" v-if="selectTimu.questionType != 4">
          <li v-for="(zd, idx) in zuoDaArr" :key="zd.id" @click="clickDaTiList(zd)"
            :class="{ activeFont: sltZdIdx == idx }">
            <span class="zdxhTitle">作答ID</span>
            <span>
              {{ zd.id }}
            </span>
          </li>
        </ul>
      </div>
      <div class="zuodaWrap">
        <!-- 填空题 -->
        <div v-if="selectTimu && selectTimu.questionType == 4">
          <div class="tianKong" v-for="tk in zuoDaArr" :key="tk.id">
            <div class="zdNum">
              <span>共 {{ tk.stuHashCount }} 人作答</span>
              <span class="ml30" v-if="tk.arbitrateScore === 0 || tk.arbitrateScore > 0">
                仲裁分数: {{ tk.arbitrateScore }} 分
              </span>
              <span class="ml30" v-if="tk.markscr === 0 || tk.markscr > 0">
                阅卷分数: <span class="clRed">{{ tk.markscr }} </span> 分
                <em class="clRed" v-if="tk.arbitrateScore === 0 || tk.arbitrateScore > 0">
                  （ 注：有仲裁分数时，阅卷分数 = 仲裁分数 ）
                </em>
              </span>
            </div>
            <div class="tkCont">
              <div class="stuZuoDa">
                <div class="kong" v-for="(kong, idx) in selectTimu.responses" :key="idx"
                  v-html="tk.stuAnswerData[idx] || '&nbsp;'">
                </div>
              </div>
              <!-- <div class="tkBtns">
                <el-select class="scr-box" size="small" v-model="tk.fillScr" placeholder="请选择" @change="fillMarkScore(tk)">
                  <el-option v-for="num in tk.scrNums" :key="num" :label="num" :value="num">
                  </el-option>
                </el-select>
                <el-button size="small" @click="fillHalfMarkScr(0.5, tk)">+0.5</el-button>
                <el-button size="small" @click="fillHalfMarkScr(-0.5, tk)">-0.5</el-button>
              </div> -->
              <div class="tkBtns">
                <el-button-group>
                  <el-button size="small" type="primary" @click="fillMarkScore('right', tk)"
                    :class="{ hasMarked: tk.fillScr === 'right' }">对</el-button>
                  <el-button size="small" type="primary" @click="fillMarkScore('half', tk)" v-if="tk.fillQuestionHalf"
                    :class="{ hasMarked: tk.fillScr === 'half' }">半对</el-button>
                  <el-button size="small" type="primary" @click="fillMarkScore('wrong', tk)"
                    :class="{ hasMarked: tk.fillScr === 'wrong' }">错</el-button>
                </el-button-group>
                <el-select class="scrSelect" size="small" v-if="tk.showOpt" v-model="tk.markscr" placeholder="请选择"
                  @change="fillHalfMarkScr(tk)">
                  <el-option v-for="num in tk.scrNums" :key="num" :label="num" :value="num">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <!-- 解答题 -->
        <div class="zgt-wrap" v-if="selectTimu && selectTimu.questionType == 5">
          <div v-if="selectZuoDa">
            <div class="stu-asw" v-for="(zd, idx) in selectZuoDa.stuAnswerData" :key="idx" v-html="zd">
            </div>
          </div>
          <div class="tch-score" v-if="selectZuoDa && selectZuoDa.markscr > -1">
            <span class="clRed">{{ selectZuoDa.markscr }}</span>
          </div>
        </div>
        <!-- 疑难 -->
        <div class="yn-cont" v-if="selectZuoDa && selectZuoDa.problemRemark" v-html="selectZuoDa.problemRemark">
        </div>
      </div>
      <div class="oprateWrap">
        <div class="deal-type" v-if="selectZuoDa">
          <span v-if="selectZuoDa.finishStatus == 2">疑难处理</span>
          <span v-else>分差处理</span>
        </div>
        <div class="mt5">
          <el-button type="text" @click="seeTiMu()">查看原题</el-button>
          <el-button type="text" class="flr" style="color: #ff0000" @click="backToTiMu()">返回</el-button>
        </div>
        <!-- <div class="mb15">
          <el-select size="small" v-model="selectNpp" placeholder="请选择" @change="changeTiKongNum">
            <el-option v-for="item in numPerPage" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <div v-if="selectTimu.questionType != 4">
          <el-button type="primary" size="small" class="scoreBtn" v-for="(scr, idx) in scoreArr" :key="idx"
            :class="{ flr: idx % 2 == 1, hasMarked: selectZuoDa && selectZuoDa.markscr === scr }"
            @click="zgtMarkScr(scr)">
            {{ scr }}
          </el-button>
          <div v-if="selectZuoDa && selectZuoDa.allowHalf">
            <el-button type="primary" size="small" class="scoreBtn" @click="zgtMarkScr(0.5)">
              + 0.5
            </el-button>
            <el-button type="primary" size="small" class="scoreBtn flr" @click="zgtMarkScr(-0.5)">
              - 0.5
            </el-button>
          </div>
        </div>
        <div class="mt15" v-if="zgtLimit != 1">
          <!-- 填空题翻页 -->
          <el-button-group style="width: 100%;" v-if="selectTimu.questionType == 4">
            <el-button style="width: 50%;" size="small" icon="el-icon-arrow-left" type="primary"
              @click="fillTurnPage(-1)">
              上一题
            </el-button>
            <el-button style="width: 50%;" size="small" type="primary" @click="fillTurnPage(1)">
              下一题<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </el-button-group>
          <!-- 问题题翻页 -->
          <el-button-group style="width: 100%;" v-else>
            <el-button style="width: 50%;" size="small" icon="el-icon-arrow-left" type="primary" 
              @click="turnPage(-1)">
              上一题
            </el-button>
            <el-button style="width: 50%;" size="small" type="primary" @click="turnPage(1)">
              下一题<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </el-button-group>
        </div>
        <div class="mt15" v-else>
          <!-- 填空题翻页 -->
          <el-button-group style="width: 100%;" v-if="selectTimu.questionType == 4">
            <el-button style="width: 100%;" size="small" type="primary" @click="fillTurnPage(1)">
              下一题<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </el-button-group>
          <!-- 问题题翻页 -->
          <el-button-group style="width: 100%;" v-else>
            <el-button style="width: 100%;" size="small" type="primary" @click="turnPage(1)">
              下一题<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </el-button-group>
        </div>
      </div>
    </div>
    <!-- 引入题目子组件 -->
    <el-dialog title="查看题目" :visible.sync="tiMuVisible" width="50%" center>
      <TimuView v-if="tiMuVisible" :passTimu="selectTimu" @timuEvent="timuPageClose"></TimuView>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Lazy from 'lazy.js';
import { crudFun } from '@/libs/crud';
import TimuView from '@/components/TimuView.vue';

let that = null;
let getTeacherTaskInfosUrl = '/grading/getTeacherTaskInfos'; //获取教师任务列表
// let gradingLineTaskUrl = '/grading/line/getTask'; //获取题目的作答
let commitQuestionTaskUrl = '/grading/line/commitQuestionTask'; //添加作答
let commitFillTaskkUrl = '/grading/line/commitFillTask'; //填空题提交
let getThreeOrArbitrateTaskUrl = '/arbitrate/getThreeOrArbitrateTask'; //获取仲裁和三评任务列表
let tiXingObj = {
  1: '单选题',
  2: '多选题',
  3: '判断题',
  4: '填空题',
  5: '问答题',
  6: '选择填空题',
  7: '复合题'
};
export default {
  name: 'ArbitrationView',
  props: {
    passTimu: Object
  },
  components: {
    TimuView
  },
  data() {
    return {
      taskData: [],
      taskTimuData: [],
      zuoDaArr: [],
      scoreArr: [],
      numPerPage: [
        {
          value: 1,
          label: '每页显示 1 个'
        },
        {
          value: 2,
          label: '每页显示 2 个'
        },
        {
          value: 3,
          label: '每页显示 3 个'
        },
        {
          value: 4,
          label: '每页显示 4 个'
        },
        {
          value: 5,
          label: '每页显示 5 个'
        }
      ],
      selectNpp: 1,
      selectTimu: {
        questionType: null
      },
      tiMuVisible: false,
      forceFresh: true,
      zgtLimit: 1, //主观题每次查询多少题
      zuoDaUnit: [],
      selectZuoDa: null, //选中的学生作答
      sltZdIdx: 0, //当前选中的作答索引
      saveSuccess: true
    }
  },
  computed: {
    ...mapState([
      'mvSltPici',
      'mvSltKemu'
    ])
  },
  methods: {
    //查询自己的任务
    getTask() {
      let tkCfg = {
        method: 'post',
        url: getTeacherTaskInfosUrl,
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu
        }
      };
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      that.taskData = [];
      that.taskTimuData = [];
      crudFun(tkCfg).then((data) => {
        if (data) {
          let tmArr = [];
          Lazy(data).sortBy('taskNo').each(function (task) {
            if (task.questionData) {
              task.questionData = that.$comn.toObj(task.questionData);
              Lazy(task.questionData).sortBy('questionNo').each(function (tm, idx) {
                tm.taskId = task.id;
                tm.questionName = tiXingObj[tm.questionType];
                tm.rspan = 0;
                if (idx == 0) {
                  tm.rspan = task.questionData.length;
                }
                if (tm.responses) {
                  tm.responses = that.$comn.toObj(tm.responses);
                }
                tmArr.push(tm);
              });
            }
          });
          that.taskData = data;
          that.taskTimuData = tmArr;
        }
      });
    },
    //table的跨行和列
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (row.rspan > 0) {
          return {
            rowspan: row.rspan,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    //填空题每页显示多少
    changeTiKongNum() {
      that.zgtLimit = that.selectNpp;
    },
    //通过题目查询作答
    getZuoDaFromTimu(frsIn, resolve) {
      let qryMaxNum = 10;
      let count = 0;
      let timuType = null;
      let doFun = function () {
        let tmObj = {
          method: 'post',
          // url: gradingLineTaskUrl,
          url: getThreeOrArbitrateTaskUrl,
          data: {
            taskId: "",
            questionId: "",
            taskStatus: "",
            limit: that.zgtLimit
          }
        };
        if (that.selectTimu) {
          tmObj.data.taskId = that.selectTimu.taskId;
          tmObj.data.questionId = that.selectTimu.identifier;
          tmObj.data.taskStatus = that.selectTimu.taskStatus;
          timuType = that.selectTimu.questionType;
        }
        else {
          that.$message({
            showClose: true,
            message: '缺少题目信息！',
            type: 'error'
          });
          return;
        }
        count++;
        if (count == qryMaxNum) {
          that.$message({
            showClose: true,
            message: '已尝试查询学生作答 ' + qryMaxNum + ' 次，没有查出作答数据，请重新',
            type: 'error'
          });
          return;
        }
        crudFun(tmObj).then((data) => {
          if (data) {
            if (data.length > 0) {
              Lazy(data).each(function (zd) {
                zd.markscr = -1;
                zd.saved = false;
                zd.fillScr = null;
                zd.scrNums = Lazy.generate(function (i) { return i + 1; }, +zd.score).toArray();
                // zd.scrNums.unshift(0);
                if (zd.questionData) {
                  zd.questionData = that.$comn.toObj(zd.questionData);
                  if (zd.questionData.responses) {
                    zd.questionData.responses = that.$comn.toObj(zd.questionData.responses);
                  }
                }
                if (zd.stuAnswerData) {
                  zd.stuAnswerData = that.$comn.toObj(zd.stuAnswerData);
                }
                if (zd.standardAnswerData) {
                  zd.standardAnswerData = that.$comn.toObj(zd.standardAnswerData);
                }
              });
              that.zuoDaUnit.push(data);
              if (frsIn) {
                that.zuoDaArr = that.zuoDaUnit.shift();
                if (timuType == 4) {
                  that.fillTurnPage(0, 'firstIn');
                }
                else {
                  that.turnPage(0);
                }
              }
              else {
                if (resolve) {
                  resolve();
                }
              }
            }
            else {
              that.zuoDaArr = [];
              if (frsIn) {
                that.$message({
                  showClose: true,
                  message: '没有阅卷任务！',
                  type: 'warning'
                });
                that.backToTiMu();
              }
              if (resolve) {
                resolve();
              }
              // setTimeout(function () {
              //   doFun();
              // }, 2000);
            }
          }
          else {
            that.zuoDaArr = [];
            setTimeout(function () {
              doFun();
            }, 2000);
          }
        });
      }
      doFun();
    },
    //打开评卷页面
    dealPuzzle(row) {
      if (row) {
        let scr = row.score || 0;
        that.scoreArr = Lazy.generate(function (i) { return i + 1; }, scr).toArray();
        that.scoreArr.unshift(0);
        row['修改题目'] = false;
        that.selectTimu = row;
        that.getZuoDaFromTimu('fstIn');
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择题目！',
          type: 'error'
        });
      }
    },
    //返回需要阅的题目列表
    backToTiMu() {
      that.zuoDaArr = [];
      that.selectZuoDa = null;
      that.sltZdIdx = 0;
      that.zuoDaUnit = [];
      that.$emit('timuEvent', that.saveSuccess);
    },
    //查看题目
    seeTiMu() {
      that.tiMuVisible = true;
    },
    //题目页面关闭
    timuPageClose(isMod) {
      that.tiMuVisible = false;
    },
    //填空题给分
    fillMarkScore(tp, zd) {
      // fillMarkScore(zd) {
      // if(zd){
      //   zd.markscr = +zd.markscr;
      //   if(zd.markscr == -1){
      //     zd.markscr = 0;
      //   }
      //   zd.markscr += zd.fillScr;
      // }
      zd.showOpt = false;
      switch (tp) {
        case 'right':
          zd.markscr = zd.score;
          break;
        case 'half':
          if (zd.markscr == -1) {
            zd.markscr = null;
          }
          zd.showOpt = true;
          that.$forceUpdate(); //解决第二次点击不展示下拉列表的问题
          break;
        case 'wrong':
          zd.markscr = 0;
          break;
      };
      zd.fillScr = tp;
      if (zd.arbitrateScore) {
        zd.markscr = zd.arbitrateScore;
      }
    },
    //显示题目分数列表
    fillHalfMarkScr(zd) {
      if (zd) {
        if (zd.arbitrateScore) {
          zd.markscr = zd.arbitrateScore;
        }
        zd.showOpt = false;
        // zd.markscr = +zd.markscr;
        // if(zd.markscr == -1){
        //   zd.markscr = 0;
        // }
        // zd.markscr += scr;
        // if (zd.markscr < 0) {
        //   zd.markscr = 0;
        // }
        // if (zd.markscr > zd.score) {
        //   zd.markscr = zd.score;
        // }
      }
    },
    //主观题给分
    zgtMarkScr(scr) {
      if (that.selectZuoDa) {
        if (Math.abs(scr) == 0.5) {
          that.selectZuoDa.markscr += scr;
        }
        else {
          that.selectZuoDa.markscr = scr;
        }
        if (that.selectZuoDa.markscr < 0) {
          that.selectZuoDa.markscr = 0;
        }
        if (that.selectZuoDa.markscr > that.selectZuoDa.score) {
          that.selectZuoDa.markscr = that.selectZuoDa.score;
        }
      }
      // if (that.selectZuoDa) {
      //   that.selectZuoDa.markscr = scr;
      //   // that.forceRerender();
      // }
    },
    //问答题，翻页
    turnPage(dir) {
      dir = +dir;
      new Promise(function (resolve, reject) {
        let sltZd = that.selectZuoDa;
        let zdArrLen = that.zuoDaArr ? that.zuoDaArr.length : 0;
        if (sltZd && sltZd.markscr > -1) {
          let scrCfg = {
            method: 'post',
            url: commitQuestionTaskUrl,
            data: {
              "teacherTaskDetailId": sltZd.id,
              "stuScore": sltZd.markscr
            }
          };
          crudFun(scrCfg).then((data) => {
            if (data) {
              that.selectZuoDa.saved = true;
              if (that.zuoDaArr && zdArrLen == that.zgtLimit) {
                let fndSaved = Lazy(that.zuoDaArr).filter(function (zd) {
                  return zd.saved;
                }).toArray();
                if (fndSaved.length == that.zgtLimit) {
                  // that.selectZuoDa = null;
                  return that.getZuoDaFromTimu(false, resolve);
                }
                else {
                  resolve();
                }
              }
              else {
                // let fndSaved = Lazy(that.zuoDaArr).filter(function (zd) {
                //   return zd.saved;
                // }).toArray();
                // if (fndSaved.length == zdArrLen && dir != -1) {
                //   reject('此题目已没有任务！');
                // }
                // else {
                //   resolve();
                // }
                resolve();
              }
            }
            else {
              reject(data);
            }
          });
        }
        else {
          resolve();
        }
      })
        .then(function () {
          return new Promise(function (resolve, reject) {
            that.sltZdIdx += dir;
            //到第一题了
            if (that.sltZdIdx < 0) {
              that.sltZdIdx = 0;
              resolve();
            }
            //到最后一题了
            else if (that.sltZdIdx >= that.zgtLimit) {
              if (that.zuoDaUnit && that.zuoDaUnit.length > 0) {
                that.zuoDaArr = that.zuoDaUnit.shift();
                that.sltZdIdx = 0;
                resolve();
              }
              else {
                that.sltZdIdx = that.zgtLimit - 1;
                // that.zuoDaArr = [];
                reject('此题目已没有任务！');
              }
            }
            else {
              resolve();
            }
          });
        })
        .then(function () {
          if (that.zuoDaArr && that.zuoDaArr.length > 0) {
            // that.selectZuoDa = that.zuoDaArr[that.sltZdIdx];
            let sltZuoDa = that.zuoDaArr[that.sltZdIdx];
            if (sltZuoDa) {
              let stuAswDataTmp = sltZuoDa.stuAnswerData;
              let newAsws = [];
              if (stuAswDataTmp && stuAswDataTmp.length > 0) {
                let stuAswData = that.$comn.cloneObj(sltZuoDa.stuAnswerData);
                let dealImg = function (zsds) {
                  let zd = zsds.shift();
                  // let idxOf = zd.indexOf('<img');
                  let regex = /<img.*?src="(.*?)"/g;
                  let match;
                  let srcList = [];
                  while (match = regex.exec(zd)) {
                    //match返回一个数组，数组中有两个数值
                    //第一个是img整个标签字段，第二个是src中匹配的内容
                    srcList.push(match[1]);
                  }
                  if (srcList && srcList.length > 0) {
                    // let zdBase = zd.slice(10, -2);
                    let zdBase = srcList[0];
                    let imgNew = new Image();
                    imgNew.onload = function () {
                      let imgWd = imgNew.width;
                      let imgHg = imgNew.height;
                      let elHas = document.querySelector('#imgCanvas');
                      if (elHas) {
                        elHas.remove();
                      }
                      let dstCanvas = document.createElement('canvas');
                      dstCanvas.id = 'imgCanvas';
                      dstCanvas.width = imgWd;
                      dstCanvas.height = imgHg;
                      let dstContext = dstCanvas.getContext('2d');
                      if (imgWd && imgHg) {
                        dstContext.drawImage(imgNew, 0, 0, imgWd, imgHg, 0, 0, imgWd, imgHg);
                        let imgData = dstContext.getImageData(0, 0, imgWd, imgHg);
                        let imgDtDot = imgData.data;
                        let index = 0;
                        let yVal = 0;
                        for (var y = 0; y < imgHg; y++) {
                          yVal = 0;
                          for (var x = 0; x < imgWd; x++) {
                            index = (y * imgWd + x) * 4;
                            if (imgDtDot[index] != 255 || imgDtDot[index + 1] != 255 || imgDtDot[index + 2] != 255) {
                              let imgTmp = '<img src="' + zdBase + '" style="margin-top:-' + y + 'px">';
                              newAsws.push(imgTmp);
                              yVal = y;
                              break;
                            }
                          }
                          if (yVal) {
                            break;
                          }
                        }
                      }
                      if (zsds && zsds.length > 0) {
                        dealImg(zsds);
                      }
                      else {
                        sltZuoDa.stuAnswerData = newAsws;
                        that.selectZuoDa = sltZuoDa;
                      }
                    };
                    imgNew.src = zdBase;
                  }
                  else {
                    newAsws.push(zd);
                    if (zsds && zsds.length > 0) {
                      dealImg(zsds);
                    }
                    else {
                      sltZuoDa.stuAnswerData = newAsws;
                      that.selectZuoDa = sltZuoDa;
                    }
                  }
                }
                dealImg(stuAswData);
              }
              else {
                that.selectZuoDa = sltZuoDa;
              }
            }
            else{
              that.$message({
                showClose: true,
                message: '此题目已没有任务！',
                type: 'warning'
              });
              that.backToTiMu();
            }
          }
          else {
            that.$message({
              showClose: true,
              message: '此题目已没有任务！',
              type: 'warning'
            });
            that.backToTiMu();
          }
        })
        .catch(function (err) {
          that.$message({
            showClose: true,
            message: err,
            type: 'warning'
          });
          if (err == '此题目已没有任务！') {
            that.backToTiMu();
          }
        });
    },
    //填空题，翻页
    fillTurnPage(dir, firstIn) {
      dir = +dir;
      new Promise(function (resolve, reject) {
        if (firstIn) {
          resolve();
        }
        else {
          let sltZd = that.zuoDaArr;
          if (sltZd && sltZd.length > 0) {
            let scrCfg = {
              method: 'post',
              url: commitFillTaskkUrl,
              data: {
                "taskId": "",
                "questionId": "",
                "fillScoreBos": []
              }
            };
            if (that.selectTimu) {
              scrCfg.data.taskId = that.selectTimu.taskId;
              scrCfg.data.questionId = that.selectTimu.identifier;
            }
            let pyArr = [];
            Lazy(sltZd).each(function (zd) {
              let pyTmp = {
                "stuAnswerHash": "",
                "stuScore": ""
              };
              if (zd.markscr != null && zd.markscr > -1) {
                pyTmp.stuAnswerHash = zd.stuAnswerHash;
                pyTmp.stuScore = zd.markscr;
                pyArr.push(pyTmp);
              }
            });
            if (sltZd.length == pyArr.length) {
              scrCfg.data.fillScoreBos = pyArr;
            }
            else {
              reject('页面题目全部给分以后才能进行下一题！');
              return;
            }
            crudFun(scrCfg).then((data) => {
              if (data) {
                return that.getZuoDaFromTimu(false, resolve);
              }
              else {
                reject(data);
              }
            });
          }
          else {
            reject('没有任何学生作答数据！');
          }
        }
      })
        .then(function () {
          if (!firstIn) {
            if (that.zuoDaUnit && that.zuoDaUnit.length > 0) {
              that.zuoDaArr = that.zuoDaUnit.shift();
            }
            else {
              that.$message({
                showClose: true,
                message: '此题目已没有任务！',
                type: 'warning'
              });
              that.backToTiMu();
            }
          }
        })
        .catch(function (err) {
          that.$message({
            showClose: true,
            message: err,
            type: 'warning'
          });
        });
    },
    //点击答题ID
    clickDaTiList(zd) {
    },
    //提交疑难
    submitPuzzle() {
      let plObj = {
        method: 'post',
        url: '',
        data: {

        }
      };
      crudFun(plObj).then((data) => {
        if (data) {

        }
      });
    },
    //提交批注
    submitComment() {
      let cmObj = {
        method: 'post',
        url: '',
        data: {

        }
      };
      crudFun(cmObj).then((data) => {
        if (data) {

        }
      });
    },
    //强制刷新dom
    forceRerender: function (tp) {
      that.forceFresh = false;
      that.$nextTick().then(() => {
        that.forceFresh = true;
      });
    }
  },
  mounted() {
    if (that.passTimu) {
      let psTmObj = that.$comn.cloneObj(that.passTimu);
      if (psTmObj.responses) {
        psTmObj.responses = that.$comn.toObj(psTmObj.responses);
      }
      that.dealPuzzle(psTmObj);
    }
    else {
      that.$message({
        showClose: true,
        message: '缺少题目ID！',
        type: 'error'
      });
    }
  },
  created() {
    that = this;
  }
}
</script>

<style lang="less" scoped>
.yueJuanPage {
  position: fixed;
  width: 100%;
  top: 48px;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: #ffffff;

  // display: none;
  .timuWrap {
    position: absolute;
    background-color: #1163a6;
    width: 200px;
    top: 0;
    bottom: 0;
    color: #ffffff;
    padding: 15px;
    box-sizing: border-box;
  }

  .tmwTiHao {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    border-bottom: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
    box-sizing: border-box;
    background-color: #00458d;
  }

  .zdxhList {
    list-style: none;
    margin: 0;
    padding: 10px 0;
    position: absolute;
    top: 40px;
    right: 0px;
    left: 15px;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;

    li {
      padding: 5px 0;
      cursor: pointer;
    }

    .zdxhTitle {
      padding-right: 10px;
    }
  }

  .zdxhList::-webkit-scrollbar {
    width: 6px;
  }

  .zdxhList::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
  }

  .zdxhList::-webkit-scrollbar-track {
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.8);
  }

  .oprateWrap {
    position: absolute;
    border-left: 1px solid #949494;
    width: 200px;
    top: 0;
    bottom: 0;
    right: 0;
    // color: #ffffff;
    padding: 15px;
    box-sizing: border-box;
    overflow-y: auto;

    .el-button+.el-button {
      margin-left: 0;
    }
  }

  .scoreBtn {
    width: 45%;
    font-size: 16px;
    margin-top: 10px;
  }

  .zuodaWrap {
    position: absolute;
    top: 0px;
    left: 215px;
    right: 215px;
    bottom: 0;
    padding: 15px 0;
    overflow-y: auto;
    // width: 100%;
  }

  .tianKong {
    margin-top: 20px;
  }

  .zdNum {
    font-size: 16px;
    padding-left: 10px;
  }

  .tkCont {
    margin-top: 10px;
    padding: 10px;
    position: relative;
    min-height: 24px;
    background-color: #ebebeb;
    line-height: 32px;
    padding-right: 270px;
    border-radius: 6px;

    .kong {
      margin-right: 15px;
      display: inline-block;
      padding: 0 10px;
      border-bottom: 1px solid #000;
    }

    .tkBtns {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -16px;
    }
  }

  .tkBtns {
    .el-button {
      width: 80px;
    }
  }

  .scrSelect {
    position: absolute !important;
    left: 80px;
    top: 35px;
    right: 60px;
  }

  .tch-score {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 48px;
  }

  .deal-type {
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid #a1a1a1;
    padding-bottom: 5px;
  }

  .yn-cont {
    position: absolute;
    bottom: 10px;
    color: red;
    z-index: 10;
  }
}
</style>