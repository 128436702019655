<template>
  <div class="about">
    <!-- <div>
      <el-button size="small" type="primary" @click="addManagePop()" v-if="mvSltPcsz">
        <span v-if="mvSltPcsz.rwfpkxjs">指派大区负责人</span>
        <span v-else>指派科目负责人</span>
      </el-button>
      <span v-else>请选择批次！</span>
    </div> -->
    <!-- <div class="mt15"> -->
    <div>
      <el-table ref="keMuTable" :data="keMuData" tooltip-effect="dark" style="width: 100%" border stripe>
        <!-- @selection-change="handleKeMuChange" border stripe> -->
        <!-- <el-table-column type="selection" width="55">
        </el-table-column> -->
        <el-table-column prop="kmmc" label="科目名称" align="center">
        </el-table-column>
        <el-table-column prop="kmdm" label="科目代码" align="center">
        </el-table-column>
        <el-table-column prop="bmrs" label="报名人数" align="center" width="160px">
        </el-table-column>
        <el-table-column prop="skrs" label="实考人数" align="center" width="160px">
        </el-table-column>
        <el-table-column prop="sjkgts" label="试卷客观题数" align="center" width="140px">
        </el-table-column>
        <el-table-column prop="sjzgts" label="试卷主观题数" align="center" width="140px">
        </el-table-column>
        <el-table-column prop="fzrname" v-if="mvSltPcsz && mvSltPcsz.rwfpkxjs" label="大区负责人" align="center">
        </el-table-column>
        <el-table-column prop="fzrname" v-else label="科目负责人" align="center">
        </el-table-column>
        <el-table-column label="操作" width="160" align="center">
          <template slot-scope="scope">
            <el-button @click="setFzrPop('set', scope.row)" type="text" size="small" class="ml15">设定负责人</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <!-- 添加负责人 -->
      <el-dialog title="" modal :close-on-click-modal="false" :show-close="false" :visible.sync="addManageVisible">
        <div slot="title">
          <span class="ftz18" v-if="mvSltPcsz && mvSltPcsz.rwfpkxjs">指派大区负责人</span>
          <span class="ftz18" v-else>指派科目负责人</span>
          <a class="tplAstyle" v-if="mvSltPcsz && mvSltPcsz.rwfpkxjs" href="/template/大区负责人模板.xlsx" download="大区负责人模板.xlsx">
            大区负责人模板.xlsx
          </a>
          <a class="tplAstyle" v-else href="/template/科目负责人模板.xlsx" download="科目负责人模板.xlsx">
            科目负责人模板.xlsx
          </a>
          <el-upload class="upload-demo flr" ref="upload" action="" :on-remove="removeFile" :on-change="getFileInfo"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">
              <span v-if="mvSltPcsz && mvSltPcsz.rwfpkxjs">导入大区负责人</span>
              <span v-else>导入科目负责人</span>
            </el-button>
          </el-upload>
        </div>
        <div>
          <el-table ref="manageTable" :data="manageData" tooltip-effect="dark" style="width: 100%"
            @selection-change="handleMagChange" height="400">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="xm" label="姓名" width="140">
            </el-table-column>
            <el-table-column prop="dwmc" label="单位" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="sjh" label="手机" width="160">
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="dialog-footer">
          <!-- <el-button @click="addManagePop('cancel')">取 消</el-button> -->
          <el-button @click="setFzrPop('cancel')">取 消</el-button>
          <el-button class="ml15" type="primary" @click="saveManageSelect()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { crudFun } from '@/libs/crud';
import Lazy from 'lazy.js';

let that = null;
let rwfpListXmidUrl = '/rwfp/list/'; //查询批次下面的科目
// let rwfpGetFzrAllUrl = '/rwfp/getFZRAll'; //获取大区负责人
let rwfpGetFzrUrl = '/rwfp/getFzr'; //获取科目负责人，全部
let rwfpSaveFzrUrl = '/rwfp/saveFzr'; //保存任务负责人
let rwfpImportFzr = '/rwfp/importFzr'; //导入负责人
export default {
  name: 'TaskXmfzrView',
  data() {
    return {
      keMuData: [],
      addManageVisible: false,
      manageData: [],
      mtplSltMage: [],
      uploadFile: null,
      page: {
        total: 0,
        size: 10,
        current: 1
      },
      selectKeMu: null
    }
  },
  computed: {
    ...mapState([
      // 'mvSltItem',
      'mvSltPici',
      'mvSltPcsz',
      'mvSltKemu',
    ])
  },
  methods: {
    // 查询批次下面的科目
    getKeMu(resolve) {
      let gtCfg = {
        method: 'post',
        url: rwfpListXmidUrl + that.mvSltPici + '/0',
        data: {
          "pageNo": 1,
          "pageSize": 20
        }
      };
      crudFun(gtCfg).then((data) => {
        if (data) {
          let kemuArr = data.records;
          Lazy(kemuArr).each(function(km){
            km.fzrname = '';
            if(km.fzr){
              km.fzr = that.$comn.toObj(km.fzr);
              let fzrArr = [];
              Lazy(km.fzr).each(function(fzr){
                if(fzr.jsdm == 'dqfzr'){
                  fzrArr.push(fzr.xm);
                }
              });
              km.fzrname = fzrArr.join('、');
            }
          });
          that.keMuData = kemuArr;
          if (resolve) {
            resolve();
          }
        }
      });
    },
    //查询大区或者科目负责人
    getFzr(row) {
      let gtCfg = {
        method: 'post',
        url: rwfpGetFzrUrl,
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": "",
          "jsdm": ""
        }
      };
      let users = [];
      if (!that.mvSltPici) {
        that.$message({
          showClose: true,
          message: '请选择批次！',
          type: 'error'
        });
        return;
      }
      if (row) {
        gtCfg.data.kmdm = row.kmdm;
        if(row.fzr && row.fzr.length > 0){
          users = that.$comn.cloneObj(row.fzr);
        }
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择科目！',
          type: 'error'
        });
        return;
      }
      if(that.mvSltPcsz){
        gtCfg.data.jsdm = that.mvSltPcsz.rwfpkxjs ? 'dqfzr' : 'kmfzr';
      }
      else{
        that.$message({
          showClose: true,
          message: '缺少批次设置！',
          type: 'error'
        });
        return;
      }
      crudFun(gtCfg).then((data) => {
        if (data) {
          that.manageData = data;
          setTimeout(function () {
            Lazy(users).each(function (usr) {
              let fndRow = Lazy(that.manageData).find(function (row) {
                return row.id == usr.id;
              });
              if (fndRow) {
                that.$refs.manageTable.toggleRowSelection(fndRow);
              }
            });
          }, 100);
        }
      });
    },
    //添加项目负责人
    addManagePop(tp) {
      that.addManageVisible = !that.addManageVisible;
    },
    //设定负责人弹出
    setFzrPop(tp, row) {
      if (tp && tp == 'set') {
        that.selectKeMu = row;
        that.getFzr(row);
      }
      else if (tp && tp == 'cancel') {
        that.manageData = [];
        that.selectKeMu = null;
        that.uploadFile = null;
      }
      that.addManageVisible = !that.addManageVisible;
    },
    //取消项目设定
    cancelItemSet() {
      that.showItemSet = false;
    },
    //选择负责人
    handleMagChange(val) {
      that.mtplSltMage = val;
    },
    //保存项目选择人
    saveManageSelect() {
      let fzrTemp = [];
      let usrTemp = [];
      let mgCfg = {
        method: 'post',
        url: rwfpSaveFzrUrl,
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": null,
          "jsdm": null,
          "users": []
        }
      };
      if(that.selectKeMu){
        mgCfg.data.kmdm = that.selectKeMu.kmdm;
      }
      else{
        that.$message({
          showClose: true,
          message: '请选择科目！',
          type: 'error'
        });
        return;
      }
      if(that.mvSltPcsz){
        mgCfg.data.jsdm = that.mvSltPcsz.rwfpkxjs ? 'dqfzr' : 'kmfzr';
      }
      else{
        that.$message({
          showClose: true,
          message: '缺少批次设置！',
          type: 'error'
        });
        return;
      }
      Lazy(that.mtplSltMage).each(function (fzr) {
        let usrObj = {
          id: fzr.id,
          xm: fzr.xm
        };
        fzrTemp.push(fzr.xm);
        usrTemp.push(usrObj);
      });
      that.selectKeMu.fzrname = fzrTemp.join('、');
      that.selectKeMu.fzr = usrTemp;
      mgCfg.data.users = usrTemp;
      crudFun(mgCfg).then((data) => {
        if (data) {
          that.setFzrPop('cancel');
        }
      });
    },
    //删除选中的文件
    removeFile(file) {
      that.uploadFile = null;
      let upFileElm = document.querySelector('.el-upload-list');
      if(upFileElm){
        upFileElm.remove();
      }
    },
    //得到文件详细信息
    getFileInfo(file) {
      that.uploadFile = file;
      setTimeout(() => {
        that.importFzr();
      }, 1000);
    },
    //导入负责人
    importFzr() {
      let flCfg = {
        method: "post",
        url: rwfpImportFzr,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: null
      };
      const fd = new FormData();
      if (!that.mvSltPici) {
        that.$message({
          showClose: true,
          message: '请选择批次！',
          type: 'error'
        });
        return;
      }
      if (!that.selectKeMu) {
        that.$message({
          showClose: true,
          message: '请选择科目！',
          type: 'error'
        });
        return;
      }
      let tpVal = that.mvSltPcsz.rwfpkxjs ? 'dqfzr' : 'kmfzr';
      let kmdmVal = that.selectKeMu.kmdm;
      let file = that.uploadFile.raw;
      // fd.append("type", tpVal);
      fd.append("jsdm", tpVal);
      fd.append("pcdm", that.mvSltPici);
      fd.append("kmdm", kmdmVal);
      fd.append("file", new Blob([file], { type: file.type }));
      flCfg.data = fd;
      crudFun(flCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '负责人导入成功！',
            type: 'success'
          });
          that.removeFile(that.uploadFile);
          that.getFzr(that.selectKeMu);
        }
      });
    }
    // //科目的选择
    // handleKeMuChange() {
    // }
  },
  mounted() {
    if (that.mvSltPici) {
      that.getKeMu();
    }
    else {
      that.userData = [];
      that.$message({
        showClose: true,
        message: '请选择批次！',
        type: 'error'
      });
    }
  },
  created() {
    that = this;
    // if (that.mvSltPcsz) {
    //   //执行代码
    // }
    // else {
    //   that.$message({
    //     showClose: true,
    //     message: '请选择批次！',
    //     type: 'error'
    //   });
    // }
  },
  watch: {
    mvSltPici: {
      handler(newVal, oldVal) {
        // 数据发生变化时执行的操作
        if (that.mvSltPici) {
          that.getKeMu();
        }
      },
      deep: true, // 是否深度监听
      immediate: true, // 是否在组件创建时立即执行回调函数
    }
  }
}
</script>

<style lang="less" scoped></style>