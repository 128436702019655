<template>
  <div class="about">
    <div>
      <el-button size="small" type="primary" @click="addNewUser('new')">单个添加</el-button>
      <el-button size="small" type="primary" class="ml15" @click="importUser('pop')">批量导入</el-button>
      <el-input placeholder="请输入内容" size="small" v-model="searchVal" class="input-with-select">
        <el-select v-model="selectUsrType" slot="prepend" placeholder="请选择类型" style="width: 180px;" @change="resetPage">
          <el-option v-for="tp in typeData" :key="tp.rylx" :label="tp.rylxStr" :value="tp.rylx"></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search" @click="queryUser()"></el-button>
      </el-input>
      <el-button size="small" type="default" plain class="ml15" @click="resetSearch">重置</el-button>
    </div>
    <div class="mt15">
      <el-table border :data="userData" style="width: 100%;">
        <el-table-column prop="xm" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="rylxStr" label="类型" align="center">
        </el-table-column>
        <el-table-column prop="zjhm" label="证件号" align="center">
        </el-table-column>
        <el-table-column prop="sjh" label="手机号" align="center">
        </el-table-column>
        <el-table-column prop="dwmc" label="单位" align="center">
        </el-table-column>
        <!-- <el-table-column prop="usrAvatar" label="照片" align="center">
        </el-table-column> -->
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button @click="addNewUser('mod', scope.row)" type="text" size="small">修改</el-button>
            <el-popover placement="top" width="160" v-model="scope.row.visible" class="ml15">
              <p>确定要删除此用户吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="scope.row.visible = false">取消</el-button>
                <el-button type="primary" class="ml15" size="mini" @click="deleteUser(scope.row)">确定</el-button>
              </div>
              <el-button slot="reference" type="text" size="small">删除</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt15 algRt">
        <el-pagination background layout="total, prev, pager, next" :total="page.total" :page-size="page.size"
          :current-page.sync="page.current" @current-change="queryUser()">
        </el-pagination>
      </div>
    </div>
    <div>
      <!-- 单个添加用户 -->
      <el-dialog title="用户管理" width="40%" modal :close-on-click-modal="false" :show-close="false"
        :visible.sync="usrFormVisible" center>
        <el-form :model="userParm" :rules="usrRules" ref="userParm" label-width="160px" style="width: 80%;">
          <el-form-item label="姓名" prop="xm">
            <el-input v-model="userParm.xm"></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="rylx">
            <el-select v-model="userParm.rylx" placeholder="请选择类型">
              <el-option v-for="tp in typeData" :key="tp.rylx" :label="tp.rylxStr" :value="tp.rylx">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号" prop="sjh">
            <el-input v-model="userParm.sjh" :disabled="isModUsr"></el-input>
          </el-form-item>
          <el-form-item label="证件号" prop="zjhm">
            <el-input v-model="userParm.zjhm"></el-input>
          </el-form-item>
          <el-form-item label="单位" prop="dwid">
            <el-select v-model="userParm.dwid" placeholder="请选择单位">
              <el-option v-for="org in orgData" :key="org.id" :label="org.dwmc" :value="org.id">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="照片">
            <el-button size="small" type="primary">选取文件</el-button>
          </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addNewUser('cancel')">取 消</el-button>
          <el-button class="ml30" type="primary" @click="saveUsrMod('userParm')">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 批量添加用户 -->
      <el-dialog title="导入用户" width="40%" modal :rules="batchRules" :close-on-click-modal="false" :show-close="false"
        :visible.sync="imptFormVisible">
        <el-form :model="batchPar" label-width="160px">
          <el-form-item label="类型" prop="rylx">
            <el-select v-model="batchPar.rylx" placeholder="请选择类型">
              <el-option v-for="tp in typeData" :key="tp.rylx" :label="tp.rylxStr" :value="tp.rylx"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单位" prop="dwid">
            <el-select v-model="batchPar.dwid" placeholder="请选择单位">
              <el-option v-for="org in orgData" :key="org.id" :label="org.dwmc" :value="org.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文件">
            <el-upload class="upload-demo" ref="upload" action="" :on-remove="removeFile" :on-change="getFileInfo"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
              <a class="tplAstyle" v-if="batchPar.rylx==2" href="/template/项目负责人模板.xlsx" download="项目负责人模板.xlsx">
                项目负责人模板.xlsx
              </a>
              <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button> -->
              <div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="importUser('cancel')">取 消</el-button>
          <el-button class="ml15" type="primary" @click="batchImportUsr()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Lazy from 'lazy.js';
import { crudFun } from '@/libs/crud';

let that = null;
let roleListUrl = '/role/list/'; //查询角色
let userListUrl = '/user/list'; //查询用户
let userDelUrl = '/user/del'; //删除用户
let userAddUrl = '/user/add'; //添加用户
let userUpdateUrl = '/user/update'; //修改用户
let orgListUrl = '/dw/list'; //单位查询
let importUserUrl = '/user/importUser'; //批量导入用户
export default {
  name: 'TaskScheduleView',
  data() {
    return {
      searchVal: null,
      selectUsrType: null,
      usrFormVisible: false,
      imptFormVisible: false,
      userData: [],
      userParm: {
        xm: null,
        sjh: null,
        zjhm: null,
        dwid: null,
        rylx: null
      },
      jueseData: [],
      typeData: [
        {
          'rylx': 1,
          'rylxStr': '系统管理员'
        },
        {
          'rylx': 2,
          'rylxStr': '项目负责人'
        }
      ],
      orgData: [],
      uploadFile: null,
      page: {
        total: 0,
        size: 10,
        current: 1
      },
      newUsrJs: null, //添加单个用户时选中的角色
      selectUsr: null, //修改用户时选中的用户 
      isModUsr: false,
      usrRules: {
        xm: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        sjh: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        zjhm: [
          { required: true, message: '请输入证件号', trigger: 'blur' }
        ],
        rylx: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ]
      },
      batchPar: {
        rylx: null,
        dwid: null
      },
      batchRules: {
        rylx: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        dwid: [
          { required: true, message: '请选择单位', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapState([
      'jueseArr',
      'mvSltItem',
      'user'
    ])
  },
  methods: {
    ...mapMutations([
      'updateJueseInfo'
    ]),
    //单位查询
    getOrg(resolve) {
      let gtCfg = {
        method: 'post',
        url: orgListUrl,
        data: {
          "pageNo": 1,
          "pageSize": 100 //先查询100条，后续商量如何处理
        }
      };
      crudFun(gtCfg).then((data) => {
        if (data) {
          let orgArr = data.records;
          that.orgData = orgArr;
          if (resolve) {
            resolve();
          }
        }
      });
    },
    //查询用户列表
    queryUser() {
      let usrCfg = {
        method: 'post',
        url: userListUrl,
        data: {
          pageNo: that.page.current,
          pageSize: that.page.size,
          rylx: 0
        }
      };
      if (that.selectUsrType) {
        usrCfg.data.rylx = that.selectUsrType;
      }
      if (that.searchVal) {
        usrCfg.data.ssnr = that.searchVal;
      }
      crudFun(usrCfg).then((data) => {
        if (data) {
          let usrArr = data.records;
          Lazy(usrArr).each(function (usr) {
            usr.visible = false;
          });
          that.page.total = +data.total;
          that.userData = usrArr;
        }
      });
    },
    //添加和修改用户
    addNewUser(tp, row) {
      new Promise(function (resolve, reject) {
        if (that.orgData && that.orgData.length > 0) {
          resolve();
        }
        else {
          that.getOrg(resolve);
        }
      })
        // .then(function () {
        //   return new Promise(function (resolve, reject) {
        //     if (that.jueseArr && that.jueseArr.length > 0) {
        //       resolve();
        //     }
        //     else {
        //       that.queryJuese(true, resolve);
        //     }
        //   });
        // })
        .then(function () {
          that.selectUsr = null;
          that.isModUsr = false;
          that.userParm = {
            xm: null,
            sjh: null,
            zjhm: null,
            dwid: null,
            rylx: null
          };
          if (tp && tp == 'mod') {
            let fndLx = Lazy(that.typeData).find(function (js) {
              return js.rylxStr == row.rylxStr;
            });
            if (fndLx) {
              that.userParm.rylx = fndLx.rylx;
            }
            that.selectUsr = row;
            that.isModUsr = true;
            that.userParm.xm = row.xm;
            that.userParm.sjh = row.sjh;
            that.userParm.zjhm = row.zjhm;
            that.userParm.dwid = row.dwid;
          }
          that.usrFormVisible = !that.usrFormVisible;
        });
    },
    //删除用户
    deleteUser(row) {
      let delCfg = {
        method: 'get',
        url: userDelUrl,
        params: {
          "id": null
        }
      };
      if (row) {
        delCfg.params.id = row.id;
        crudFun(delCfg).then((data) => {
          if (data) {
            row.visible = false;
            that.$message({
              showClose: true,
              message: '删除成功！',
              type: 'success'
            });
          }
          that.queryUser();
        });
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择要删除的用户！',
          type: 'error'
        });
      }
    },
    //导入用户
    importUser(tp) {
      if (tp && tp == 'pop') {
        new Promise(function (resolve, reject) {
          if (that.orgData && that.orgData.length > 0) {
            resolve();
          }
          else {
            that.getOrg(resolve);
          }
        })
          // .then(function () {
          //   return new Promise(function (resolve, reject) {
          //     if (that.jueseArr && that.jueseArr.length > 0) {
          //       resolve();
          //     }
          //     else {
          //       that.queryJuese(true, resolve);
          //     }
          //   });
          // })
          .then(function () {
            that.imptFormVisible = !that.imptFormVisible;
          });
      }
      else {
        that.batchPar = {
          rylx: null,
          dwid: null
        };
        that.uploadFile = null;
        that.imptFormVisible = !that.imptFormVisible;
      }
    },
    //保存用户修改
    saveUsrMod(formName) {
      that.$refs[formName].validate((valid) => {
        if (valid) {
          let svCfg = {
            method: 'post',
            url: null,
            data: {
              id: "",
              xm: "",
              dwid: "",
              zjhm: "",
              rylx: ""
            }
          };
          // if (that.userParm && that.userParm.rylx) {
          //   let jsdm = that.userParm.rylx;
          //   if (jsdm == 'xmfzr') {
          //     if (that.mvSltItem) {
          //       svCfg.data.xmid = that.mvSltItem;
          //     }
          //     else {
          //       that.$message({
          //         showClose: true,
          //         message: '请选择项目或者新建一个项目！',
          //         type: 'error'
          //       });
          //       return;
          //     }
          //   }
          //   svCfg.data.roles.push(jsdm);
          // }
          // else {
          //   that.$message({
          //     showClose: true,
          //     message: '请选择用户角色！',
          //     type: 'error'
          //   });
          //   return; //654301198812659706
          // }
          let usrPamCopy = that.$comn.cloneObj(that.userParm);
          // delete usrPamCopy.rylx;
          svCfg.data.xm = usrPamCopy.xm;
          svCfg.data.dwid = usrPamCopy.dwid;
          svCfg.data.zjhm = usrPamCopy.zjhm;
          svCfg.data.rylx = usrPamCopy.rylx;
          if (that.selectUsr) {
            svCfg.url = userUpdateUrl;
            svCfg.data.id = that.selectUsr.id;
          }
          else {
            svCfg.url = userAddUrl;
            svCfg.data.sjh = usrPamCopy.sjh;
          }
          crudFun(svCfg).then((data) => {
            if (data) {
              that.$message({
                showClose: true,
                message: '用户操作成功！',
                type: 'success'
              });
              that.addNewUser('cancel');
              that.queryUser();
            }
          });
        } else {
          return false;
        }
      });
    },
    //删除选中的文件
    removeFile(file) {
      that.uploadFile = null;
      let upFileElm = document.querySelector('.el-upload-list');
      if(upFileElm){
        upFileElm.remove();
      }
    },
    //得到文件详细信息
    getFileInfo(file) {
      that.uploadFile = file;
    },
    //批量导入用户
    batchImportUsr() {
      let flCfg = {
        method: "post",
        url: importUserUrl,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: null
      };
      const fd = new FormData();
      if (that.batchPar.rylx) {
        fd.append("rylx", that.batchPar.rylx);
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择类型！',
          type: 'error'
        });
        return;
      }
      if (that.batchPar.dwid) {
        fd.append("dwid", that.batchPar.dwid);
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择单位！',
          type: 'error'
        });
        return;
      }
      if (!that.uploadFile) {
        that.$message({
          showClose: true,
          message: '请选择要上传的文件！',
          type: 'error'
        });
        return;
      }
      let file = that.uploadFile.raw;
      fd.append("file", new Blob([file], { type: file.type }));
      flCfg.data = fd;
      crudFun(flCfg).then((data) => {
        if (data) {
          that.removeFile();
          that.$message({
            showClose: true,
            message: '用户导入成功！',
            type: 'success'
          });
          that.importUser('cancel');
          that.queryUser();
        }
      });
    },
    //查询角色
    queryJuese(qry, rsl) {
      new Promise(function (resolve, reject) {
        if (qry) {
          let jsCfg = {
            method: 'post',
            url: roleListUrl + '0',
            data: {
              "pageNo": 1,
              "pageSize": 1000
            }
          };
          crudFun(jsCfg).then((data) => {
            if (data) {
              let jsArr = data.records;
              that.updateJueseInfo(jsArr);
              resolve();
            }
          });
        }
        else {
          resolve();
        }
      })
        .then(function () {
          that.jueseData = Lazy(that.jueseArr).filter(function (js) {
            return js.jslx == 1 || js.jslx == 2;
          }).sortBy('jslx').toArray();
          if (rsl) {
            rsl();
          }
        });
    },
    //重置搜索
    resetSearch() {
      that.searchVal = null;
      that.selectUsrType = null;
      that.queryUser();
    },
    //重置page
    resetPage() {
      that.page = {
        total: 0,
        size: 10,
        current: 1
      };
    }
  },
  mounted() {
    that.queryUser();
  },
  created() {
    that = this;
  }
}
</script>

<style lang="less" scoped>
.input-with-select {
  width: 500px;
  margin-left: 15px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.dialog-footer {
  text-align: center;
}
</style>