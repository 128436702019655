<template>
  <div class="about">
    <div class="algRt">
      <el-button type="primary" size="mini" @click="reEvaluateKgt">客观题重评</el-button>
      <el-button class="ml15" type="primary" size="mini" @click="exportKgt">导出</el-button>
      <el-button class="ml15" type="primary" size="mini" @click="publishScore">发布</el-button>
      <el-button class="ml15" type="primary" size="mini" @click="makePdf">生成PDF</el-button>
      <el-button class="ml15" type="primary" size="mini" @click="downloadPdf">下载PDF</el-button>
    </div>
    <div>
      <el-table class="mt15" border :data="studentData" style="width: 100%;">
        <el-table-column prop="stuName" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="stuTickerCard" label="准考证号" align="center">
        </el-table-column>
        <el-table-column prop="correctQuestionCount" label="客观题正确题数" align="center">
        </el-table-column>
        <el-table-column prop="objectiveScore" label="客观题成绩" align="center">
        </el-table-column>
        <el-table-column prop="subjectivityScore" label="主观题成绩" align="center">
        </el-table-column>
        <!-- <el-table-column label="查看原卷" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="seePaper(scope.row)">查看</el-button>
          </template>
        </el-table-column> -->
        <el-table-column prop="stuScore" label="总分" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="downloadStuPdf(scope.row)">考试PDF</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt15 algRt">
        <el-pagination background layout="total, prev, pager, next" :total="page.total" :page-size="page.size"
          :current-page.sync="page.current" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import Lazy from 'lazy.js';
import { crudFun } from '@/libs/crud';
import { saveAs } from 'file-saver';

let that = null;
let scoreListUrl = '/score/list'; //查询成绩的接口
let stuScoreRemarkingUrl = '/score/stuScoreRemarking'; //学生成绩重评
let excelDownloadUrl = '/score/excelDownload'; //学生成绩重评
let publishStuScoreUrl = '/score/publishStuScore'; //学生成绩发布
let makePdfUrl = '/score/downLoadPdf'; // 请求作答pdf文件生成
let downlaodPdfZipUrl = '/score/downLoadPdfByPlanSubject'; // 下载pdf作答信息zip包
let downloadStuPdfUrl = '/score/downLoadPdfByStu'; // 下载pdf作答信息
export default {
  name: 'ScoreView',
  data() {
    return {
      studentData: [],
      page: {
        total: 0,
        size: 10,
        current: 1
      },
    }
  },
  computed: {
    ...mapState([
      'mvSltPici',
      'mvSltKemu'
    ])
  },
  methods: {
    //查询学生
    getStudent() {
      let stCfg = {
        method: 'get',
        url: scoreListUrl,
        params: {
          pcdm: that.mvSltPici,
          kmdm: that.mvSltKemu,
          pageNo: that.page.current,
          pageSize: that.page.size
        }
      };
      if (!that.mvSltPici) {
        that.$message({
          showClose: true,
          message: '请选择批次！',
          type: 'error'
        });
        return;
      }
      if (!that.mvSltKemu) {
        that.$message({
          showClose: true,
          message: '请选择科目！',
          type: 'error'
        });
        return;
      }
      crudFun(stCfg).then((data) => {
        if (data) {
          let stuArr = data.records;
          that.page.total = +data.total;
          that.studentData = stuArr;
        }
      });
    },
    //当前页面切换
    handleCurrentChange(val) {
      that.page.current = val;
      that.getStudent();
    },
    //客观题重评
    reEvaluateKgt() {
      let reCfg = {
        method: 'get',
        url: stuScoreRemarkingUrl,
        params: {
          pcdm: that.mvSltPici,
          kmdm: that.mvSltKemu
        }
      };
      if (!that.mvSltPici) {
        that.$message({
          showClose: true,
          message: '请选择批次！',
          type: 'error'
        });
        return;
      }
      if (!that.mvSltKemu) {
        that.$message({
          showClose: true,
          message: '请选择科目！',
          type: 'error'
        });
        return;
      }
      crudFun(reCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '客观题重评成功！',
            type: 'success'
          });
          that.getStudent();
        }
      });
    },
    // },
    //导出客观题
    exportKgt() {
      let psCfg = {
        method: 'get',
        url: excelDownloadUrl,
        params: {
          pcdm: that.mvSltPici,
          kmdm: that.mvSltKemu,
        },
        responseType: 'blob'
      };
      if (!that.mvSltPici) {
        that.$message({
          showClose: true,
          message: '请选择批次！',
          type: 'error'
        });
        return;
      }
      if (!that.mvSltKemu) {
        that.$message({
          showClose: true,
          message: '请选择科目！',
          type: 'error'
        });
        return;
      }
      crudFun(psCfg).then((res) => {
        if (res) {
          let fileStr = res.headers['content-disposition'];
          let fileArr = fileStr.split('=');
          let fileName = fileArr[1];
          const blob = new Blob([res.data]);
          saveAs(blob, fileName);
        }
      });
    },
    download(data, fileName) {
      if (!data) {
        return ;
      }
      let url = window.URL.createObjectURL(new Blob([data], {type: 'Blob类型'}));
      let link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    },
    //发布成功
    publishScore() {
      let psCfg = {
        method: 'get',
        url: publishStuScoreUrl,
        params: {
          pcdm: that.mvSltPici,
          kmdm: that.mvSltKemu,
        }
      };
      if (!that.mvSltPici) {
        that.$message({
          showClose: true,
          message: '请选择批次！',
          type: 'error'
        });
        return;
      }
      if (!that.mvSltKemu) {
        that.$message({
          showClose: true,
          message: '请选择科目！',
          type: 'error'
        });
        return;
      }
      crudFun(psCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '成绩发布成功！',
            type: 'success'
          });
        }
      });
    },
    //生成pdf
    makePdf() {
      let spCfg = {
        method: 'get',
        url: makePdfUrl,
        // params: {
        //   planCode: that.mvSltPici,
        //   subject: that.mvSltKemu,
        // }
      };
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      spCfg.url += '/' + that.mvSltPici + '/' + that.mvSltKemu;
      crudFun(spCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '请求已发送，pfd生成中，请15分钟后尝试点击"下载PDF"',
            type: 'success'
          });
        }
      });
    },
    //下载pdf
    downloadPdf() {
      let spCfg = {
        method: 'get',
        url: downlaodPdfZipUrl,
        params: {
          "下载": true
        //   planCode: that.mvSltPici,
        //   subject: that.mvSltKemu,
        },
        responseType: 'blob'
      };
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      spCfg.url += '/' + that.mvSltPici + '/' + that.mvSltKemu;
      crudFun(spCfg).then((res) => {
        if (res) {
          //下载代码
          let fileStr = res.headers['content-disposition'];
          let fileArr = fileStr.split('=');
          let fileName = decodeURI(fileArr[1]);
          let typeVal = res.headers['content-type'];
          let blob = new Blob([res.data]);
          saveAs(blob, fileName, {type: typeVal});
        }
      });
    },
    //下载学生的单个pdf
    downloadStuPdf(row) {
      console.log(row);
      if(!row){
        that.$message({
          showClose: true,
          message: '请选择学生！',
          type: 'error'
        });
        return;
      }
      let spCfg = {
        method: 'get',
        url: downloadStuPdfUrl,
        responseType: 'blob',
        params: {
          "下载": true
        //   planCode: that.mvSltPici,
        //   subject: that.mvSltKemu,
        //   examCardNo: row.stuTickerCards
        }
      };
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      spCfg.url += '/' + that.mvSltPici + '/' + that.mvSltKemu + '/' + row.stuTickerCard;
      crudFun(spCfg).then((res) => {
        if (res) {
          let fileStr = res.headers['content-disposition'];
          let fileArr = fileStr.split('=');
          let fileName = decodeURI(fileArr[1]);
          // that.download(res.data, '1111.pdf');
          let typeVal = res.headers['content-type'];
          let blob = new Blob([res.data]);
          saveAs(blob, fileName, {type: typeVal});
        }
      });
    },
    // //查看原卷
    // seePaper(row) {
    //   let spCfg = {
    //     method: 'post',
    //     url: '',
    //     data: {

    //     }
    //   };
    //   crudFun(spCfg).then((data) => {
    //     if (data) {

    //     }
    //   });
    // }
  },
  mounted() {
    that.getStudent();
  },
  created() {
    that = this;
  }
}
</script>

<style lang="less" scoped></style>