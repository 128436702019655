<template>
  <div class="about">
    <!-- 项目负责人 / 大区负责人 -->
    <div v-if="jueseCode == '项目负责人'">
      <el-table class="mt15" border :data="scheduleData" style="width: 100%;">
        <el-table-column prop="kmmc" label="科目名称" align="center">
        </el-table-column>
        <el-table-column prop="kmdm" label="科目代码" align="center">
        </el-table-column>
        <el-table-column prop="realStuCount" label="实考人数" align="center">
        </el-table-column>
        <!-- <el-table-column prop="state" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">等待设置</span>
            <span v-if="scope.row.status == 1">评卷中</span>
            <span v-if="scope.row.status == 3">成绩已提交</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="schedule" label="进度" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.percent + '%' }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 科目负责人 -->
    <div v-if="jueseCode == '科目负责人'">
      <el-table class="mt15" border :data="scheduleData" style="width: 100%;">
        <el-table-column prop="taskNo" label="任务编号" align="center">
        </el-table-column>
        <el-table-column prop="" label="题号" align="center">
          <template slot-scope="scope">
            <span v-for="(qd, idx) in scope.row.questionData" :key="qd.id">
              {{ qd.questionNo }}<font v-if="scope.row.questionData.length - 1 != idx">、</font>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="小题组长" align="center">
          <template slot-scope="scope">
            <span v-for="(zz, idx) in scope.row.groupUser" :key="zz.id">
              {{ zz.xm }}<font v-if="scope.row.groupUser.length - 1 != idx">、</font>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="taskCount" label="任务量" align="center">
        </el-table-column>
        <el-table-column prop="schedule" label="进度" align="center">
          <template slot-scope="scope">
            <span>{{ (scope.row.finishCount / (scope.row.taskCount || 1) * 100).toFixed(1) + '%' }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="detail" label="详情" align="center">
        </el-table-column> -->
      </el-table>
    </div>
    <!-- 小题负责人 -->
    <div v-if="jueseCode == '小题负责人' || jueseCode == '三评仲裁'">
      <div v-if="!showPuzzleList">
        <div v-for="sd in scheduleData" :key="sd.id">
          <el-row>
            <el-col :span="12" class="ftz18">
              <span>任务编号 {{ sd.taskNo }}</span>
              <span class="ml30">整体进度 {{ (sd.finishCount / ((sd.finishCount + sd.notFinishCount) || 1) * 100).toFixed(1) +
                '%' }}</span>
              <span class="ml30">待处理仲裁 {{ sd.arbitrateTaskCount }}</span>
            </el-col>
            <el-col :span="12" class="algRt">
              <el-button type="primary" size="mini" @click="dealPuzzleTimu(sd)">处理仲裁</el-button>
            </el-col>
          </el-row>
          <el-table class="mt15" border :data="sd.groupUserTaskDetails" style="width: 100%;">
            <el-table-column prop="teacherName" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="taskCount" label="评卷任务" align="center">
            </el-table-column>
            <el-table-column prop="finishTaskCount" label="已完成任务" align="center">
            </el-table-column>
            <el-table-column prop="" label="未完成任务" align="center">
              <template slot-scope="scope">
                <span>{{ +scope.row.taskCount - +scope.row.finishTaskCount }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="schedule" label="进度" align="center">
              <template slot-scope="scope">
                <span>{{ (scope.row.finishTaskCount / (scope.row.taskCount || 1) * 100).toFixed(1) + '%' }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 疑难题目列表 -->
      <div v-if="showPuzzleList">
        <div class="algRt" v-if="jueseCode != '三评仲裁'">
          <el-button size="mini" @click="backToList()">返回疑难列表</el-button>
        </div>
        <el-table class="mt15" border :data="puzzleData" style="width: 100%;">
          <!-- <el-table-column prop="zdId" label="作答ID" align="center">
          </el-table-column> -->
          <el-table-column prop="questionNo" label="题号" align="center">
          </el-table-column>
          <el-table-column prop="" label="仲裁类型" align="center">
            分差
          </el-table-column>
          <el-table-column prop="" label="状态" align="center">
            未处理
            <!-- <template slot-scope="scope">
              <span v-if="scope.row.state == 1"></span>
              <span v-if="scope.row.state == 2">已处理</span>
            </template> -->
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="dealPuzzle(scope.row)">去处理</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 仲裁教师和三评教师的阅卷页面 -->
    <ArbitrationView v-if="tiMuVisible" :passTimu="selectZcTm" @timuEvent="zcspPageClose"></ArbitrationView>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Lazy from 'lazy.js';
import { crudFun } from '@/libs/crud';
import ArbitrationView from '@/components/ArbitrationView.vue';

let that = null;
let xmfzrProgressUrl = '/progress/getXMFZRProgress'; //项目负责人/大区负责人查看进度
let kmfzrProgressUrl = '/progress/getKMFZRProgress'; //科目负责人
let xtzzProgressUrl = '/progress/getXTZZProgress'; //小题组长任务列表
let getThreeOrArbitrateTaskListUrl = '/arbitrate/getThreeOrArbitrateTaskList'; //获取仲裁和三评任务列表
let getArbitrateFillTaskListUrl = '/arbitrate/getArbitrateFillTaskList'; //获取填空仲裁和三评任务列表
let usrRole = null;
export default {
  name: 'TaskScheduleView',
  components: {
    ArbitrationView
  },
  data() {
    return {
      jueseCode: '小题负责人',
      scheduleData: [],
      tiMuVisible: false,
      showPuzzleList: false,
      puzzleData: [],
      selectZcTm: null //选中的仲裁题目
    }
  },
  computed: {
    ...mapState([
      'user',
      'mvSltPici',
      'mvSltKemu'
    ])
  },
  methods: {
    //查询进度
    getSchedule() {
      let jdCgf = {
        method: 'get',
        url: '',
        params: {
          pcdm: that.mvSltPici
        }
      };
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      switch (usrRole) {
        case 'xmfzr':
        case 'dqfzr':
          jdCgf.url = xmfzrProgressUrl;
          that.jueseCode = '项目负责人';
          break;
        case 'kmfzr':
          jdCgf.url = kmfzrProgressUrl;
          that.jueseCode = '科目负责人';
          if (!that.mvSltKemu) {
            mis.push('科目代码');
          }
          jdCgf.params.kmdm = that.mvSltKemu;
          break;
        case 'tmzz':
          // case 'zcjs':
          // case 'spjs':
          jdCgf.url = xtzzProgressUrl;
          that.jueseCode = '小题负责人';
          if (!that.mvSltKemu) {
            mis.push('科目代码');
          }
          jdCgf.params.kmdm = that.mvSltKemu;
          break;
        default:
          that.$message({
            showClose: true,
            message: '没有权限查看阅卷进度！',
            type: 'warining'
          });
          return;
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      crudFun(jdCgf).then((data) => {
        if (data) {
          if (that.jueseCode == '项目负责人') {
            Lazy(data).each(function (sc) {
              sc.percent = +(sc.finishCount / (sc.taskCount || 1) * 100).toFixed(1);
            });
          }
          else if (that.jueseCode == '科目负责人') {
            Lazy(data).each(function (sc) {
              if (sc.groupUser) {
                sc.groupUser = that.$comn.toObj(sc.groupUser);
              }
              if (sc.questionData) {
                sc.questionData = that.$comn.toObj(sc.questionData);
              }
              if (sc.scoreUser) {
                sc.scoreUser = that.$comn.toObj(sc.scoreUser);
              }
            });
          }
          that.scheduleData = data;
        }
      });
    },
    //疑难题目列表
    dealPuzzleTimu() {
      let ynCfg = {
        method: 'get',
        url: getThreeOrArbitrateTaskListUrl,
        params: {
          taskType: usrRole == "spjs" ? 2 : 3
        }
      }
      // that.jueseCode = '小题负责人';
      that.jueseCode = '三评仲裁';
      that.puzzleData = [];
      crudFun(ynCfg).then((data) => {
        if (data) {
          let newData = [];
          Lazy(data).each(function (zc) {
            if (zc.questionData) {
              zc.questionData = that.$comn.toObj(zc.questionData);
              Lazy(zc.questionData).each(function (tm) {
                tm.taskId = zc.id;
                newData.push(tm);
              });
            }
          });
          that.puzzleData = newData;
          that.showPuzzleList = true;
        }
      });
    },
    //处理疑难
    dealPuzzle(row) {
      row.taskStatus = usrRole == "spjs" ? 2 : 3;
      that.selectZcTm = row;
      that.tiMuVisible = true;
    },
    //返回疑难列表
    backToList() {
      that.showPuzzleList = false;
    },
    //题目页面关闭
    zcspPageClose() {
      that.selectZcTm = null;
      that.tiMuVisible = false;
      setTimeout(function(){
        that.dealPuzzleTimu();
      }, 500);
    },
    //测试函数
    testFun() {
      let yndCfg = {
        method: 'get',
        url: getArbitrateFillTaskListUrl,
        params: {
          pcdm: that.mvSltPici,
          kmdm: that.mvSltKemu,
          pageSize: 100,
          pageNo: 1
        }
      }
      crudFun(yndCfg).then((data) => {
        if (data) {
          // that.puzzleData = data;
          // that.showPuzzleList = true;
        }
      });
    }
  },
  mounted() {
    if (usrRole == 'zcjs' || usrRole == 'spjs') {
      that.dealPuzzleTimu();
    }
    else {
      that.getSchedule();
    }
  },
  created() {
    that = this;
    if (that.user) {
      usrRole = that.user.sltJsdm;
    }
  }
}
</script>

<style lang="less" scoped></style>