import axios from "axios";
import { Message, Loading } from "element-ui";

let loadingInstance = null; //loading的初始化
if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = '/api';
}
else if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = '/yj';
}

let that = this;

//创建axios实例
const instance = axios.create({
    // baseURL: 'https://some-domain.com/api/',
    timeout: 60000,
    headers: {
        // 'X-Custom-Header': 'foobar',
        'Content-Type': 'application/json;charset=UTF-8'
    }
});

//添加请求拦截器
instance.interceptors.request.use(function (config) {
    let loadingOption = {
        lock: true,
        text: '加载中~~~',
        background: 'none'
    };
    loadingInstance = Loading.service(loadingOption);
    return config;
}, function (error) {
    console.log('请求错误：', error);
    return Promise.reject(error);
});

//添加响应拦截器
instance.interceptors.response.use(function (response) {
    loadingInstance.close();
    if (response.status == 200) {
        let urlStr = response.config.url;
        let result = response.data;
        let paramVal = response.config.params;
        if (urlStr == '/score/excelDownload' || urlStr == '/score/downloadAnswerExcelTemplate' || (paramVal && paramVal['下载'])){
            return response;
        }
        else {
            if (result.code == 200) {
                return result.data || true;
            }
            else {
                let errInfo = '错误信息：' + result.data || result.msg;
                Message({
                    message: errInfo,
                    type: 'error',
                    duration: 5000
                });
                if(result.code == 401 || result.code == 10021){
                    location.href = '/login';
                }
                return false;
                // return Promise.reject({ error: errInfo });
            }
        }
    }
    else {
        let errInfo = '错误状态码：' + response.status;
        Message({
            message: errInfo,
            type: 'error',
            duration: 5000
        });
        return false;
        // return Promise.reject({ error: errInfo });
    }

}, function (error) {
    console.log('响应错误：', error);
    Message({
        message: error,
        type: 'error',
        duration: 5000
    });
    loadingInstance.close();
    return Promise.reject(error);
});

export default instance;