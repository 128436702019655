<template>
  <div class="about">
    <div v-if="!showPiciSet">
      <el-button size="small" type="primary" @click="addNewPiciPop('new')">新建批次</el-button>
    </div>
    <div class="mt15" v-if="!showPiciSet">
      <el-table border :data="piciData" style="width: 100%;">
        <el-table-column prop="pcmc" label="批次名称" align="center">
        </el-table-column>
        <el-table-column prop="pcdm" label="批次代码" align="center">
        </el-table-column>
        <el-table-column prop="pcsm" label="说明" align="center">
        </el-table-column>
        <el-table-column prop="cjsj" label="创建时间" align="center">
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button @click="addNewPiciPop('mod', scope.row)" type="text" size="small">修改</el-button>
            <el-button @click="piciSetPop('set', scope.row)" type="text" size="small" class="ml15">批次设定</el-button>
            <el-popover placement="top" width="180" v-model="scope.row.visible" class="ml15">
              <p>确定要删除此批次吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="scope.row.visible = false">取消</el-button>
                <el-button type="primary" class="ml15" size="mini" @click="deletePici(scope.row)">确定</el-button>
              </div>
              <el-button slot="reference" type="text" size="small">删除</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="showPiciSet">
      <!-- 批次设定 -->
      <el-form ref="piciForm" :model="piciForm" label-width="140px" style="width: 50%;">
        <el-form-item>
          <h1 class="ftz18">批次设定</h1>
        </el-form-item>
        <el-form-item label="项目负责人" prop="xmfzr">
          <el-row>
            <!-- <el-col :span="4">
              <el-button type="text" @click="addPiciManagePop">添加负责人</el-button>
            </el-col> -->
            <el-col :span="20">
              <!-- {{ piciForm.xmfzr }} -->
              <span v-for="(fzr, idx) in piciForm.xmfzr" :key="fzr.id">
                {{ fzr.xm }} <font v-if="idx != piciForm.xmfzr.length - 1">、</font>
              </span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="任务分配模式">
          <el-radio-group v-model="piciForm.rwfpms" prop="rwfpms">
            <el-radio :label="1">指派任务</el-radio>
            <!-- <el-radio :label="2">队列式任务</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="任务分配可选角色" prop="rwfpkxjs">
          <el-checkbox v-model="piciForm.rwfpkxjs">大区负责人</el-checkbox>
          <el-checkbox v-model="checked" disabled>科目负责人</el-checkbox>
          <el-checkbox v-model="checked" disabled>小题组长</el-checkbox>
          <el-checkbox v-model="checked" disabled>评卷教师</el-checkbox>
          <!-- <el-checkbox-group v-model="piciForm.rwfpkxjs">
            <el-checkbox v-for="js in jueseData" :key="js.id" :label="js.id">
              {{ js.jueseName }}
            </el-checkbox>
          </el-checkbox-group> -->
        </el-form-item>
        <!-- <el-form-item label="匿名" prop="sfnm">
          <el-radio-group v-model="piciForm.nim">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="阅卷模式" prop="yjms">
          <el-radio-group v-model="piciForm.yjms">
            <el-radio :label="1">单评</el-radio>
            <el-radio :label="2">双评</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分差阈值的解决办法" prop="fcyzjjbf" v-if="piciForm.yjms==2">
          <el-radio-group v-model="piciForm.fcyzjjbf">
            <el-radio :label="1">仲裁</el-radio>
            <el-radio :label="2">三评</el-radio>
            <!-- 双评之后，才有此行选项 -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="开启人脸识别登录">
          <el-radio-group v-model="piciForm.kqrlsbdl" prop="kqrlsbdl">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="开启人脸识别登录">
          <el-radio-group v-model="piciForm.kqsjwspjk" prop="kqsjwspjk">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="savePiciSet('piciForm')">保存</el-button>
          <el-button class="ml30" @click="piciSetPop('cancel')">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <!-- 新建批次 -->
      <el-dialog :title="modPiciTitle" modal :close-on-click-modal="false" :show-close="false" :visible.sync="piciFormVisible" center>
        <el-form ref="piciParm" :model="piciParm" :rules="pcNewRule" label-width="140px" style="width: 90%;">
          <el-form-item label="批次名称" prop="pcmc">
            <el-input v-model="piciParm.pcmc"></el-input>
          </el-form-item>
          <el-form-item label="批次代码" prop="pcdm">
            <el-input v-model="piciParm.pcdm" :disabled="selectPici"></el-input>
          </el-form-item>
          <el-form-item label="批次说明" prop="pcsm">
            <el-input type="textarea" :rows="6" v-model="piciParm.pcsm"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addNewPiciPop('cancel')">取 消</el-button>
          <el-button class="ml15" type="primary" @click="savePici('piciParm')">保 存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Lazy from 'lazy.js';
import { crudFun } from '@/libs/crud';

let that = null;
let pcListUrl = '/pc/list'; //查询批次
let getPcByXmidUrl = '/pc/getPcByXMID'; //通过项目ID查询批次
let pcDelUrl = '/pc/del'; //删除批次
let pcAddUrl = '/pc/add'; //新增批次
let pcUpdateUrl = '/pc/update'; //修改批次
let pcUpdatePcszUrl = '/pc/updatePCSZ/'; //报错批次设定
// let xmListUrl = '/xm/list'; //项目列表的查询
export default {
  name: 'TaskManageView',
  data() {
    return {
      showPiciSet: false,
      piciFormVisible: false,
      piciData: [],
      page: {
        total: 0,
        size: 10,
        current: 1
      },
      piciParm: {
        pcdm: null,
        pcmc: null,
        pcsm: null
      },
      selectPici: null,
      piciForm: {
        xmfzr: '',
        rwfpms: 1,
        rwfpkxjs: false,
        sfnm: 0,
        yjms: 1,
        fcyzjjbf: 1,
        kqrlsbdl: 1,
        kqsjwspjk: 1,
        ids: null,
        xmid: null
      },
      addManageVisible: false,
      manageData: [],
      mtplSltMage: [],
      uploadFile: null,
      pcNewRule: {
        pcdm: [
          { required: true, message: '请输入批次代码！', trigger: 'blur' }
        ],
        pcmc: [
          { required: true, message: '请输入批次名称！', trigger: 'blur' }
        ],
        pcsm: [
          { required: true, message: '请输入批次说明！', trigger: 'blur' }
        ]
      },
      checked: true,
      modPiciTitle: '新建批次'
    }
  },
  computed: {
    ...mapState([
      'mvSltItem'
    ])
  },
  methods: {
    //查询批次
    getPici() {
      let gtCfg = {
        method: 'get',
        url: getPcByXmidUrl,
        params: {
          "xmid": that.mvSltItem
        }
      };
      crudFun(gtCfg).then((data) => {
        if (data) {
          let piciArr = data;
          Lazy(piciArr).each(function (item) {
            item.visible = false;
            item.cjsj = that.$comn.dateToLc(item.cjsj);
            if(item.pcsz){
              item.pcsz = that.$comn.toObj(item.pcsz);
              if(item.pcsz.xmfzr){
                item.pcsz.xmfzr = that.$comn.toObj(item.pcsz.xmfzr);
              }
            }
          });
          // that.page.total = +data.total;
          that.piciData = piciArr;
        }
      });
      // let gtCfg = {
      //   method: 'post',
      //   url: pcListUrl,
      //   data: {
      //     "pageNo": that.page.current,
      //     "pageSize": that.page.size
      //   }
      // };
      // crudFun(gtCfg).then((data) => {
      //   if (data) {
      //     let piciArr = data.records;
      //     Lazy(piciArr).each(function (item) {
      //       item.visible = false;
      //     });
      //     that.page.total = +data.total;
      //     that.piciData = piciArr;
      //   }
      // });
    },
    //新建批次
    addNewPiciPop(tp, row) {
      that.selectPici = null;
      that.modPiciTitle = '新建批次';
      if (tp && tp == 'cancel') {
        that.piciParm = {
          pcdm: null,
          pcmc: null,
          pcsm: null
        };
      }
      else if (tp && tp == 'mod') {
        that.modPiciTitle = '修改批次';
        let pcCopy = that.$comn.cloneObj(row);
        that.piciParm = {
          pcdm: pcCopy.pcdm,
          pcmc: pcCopy.pcmc,
          pcsm: pcCopy.pcsm
        };
        that.selectPici = pcCopy;
      }
      that.piciFormVisible = !that.piciFormVisible;
    },
    //设定批次
    piciSetPop(tp, row) {
      if (tp && tp == 'set') {
        that.piciForm.xmid = row.id;
        that.selectPici = that.$comn.cloneObj(row);
        let pcsz = that.selectPici.pcsz;
        if (pcsz) {
          let pcszCopy = that.$comn.toObj(pcsz);
          if(Object.keys(pcszCopy).length > 0){
            that.piciForm = that.$comn.toObj(pcsz);
            that.piciForm.rwfpkxjs = that.piciForm.rwfpkxjs ? true : false;
          }
        }
      }
      else if (tp && tp == 'cancel') {
        that.selectPici = null;
        that.piciForm = {
          xmfzr: '',
          rwfpms: 1,
          rwfpkxjs: false,
          sfnm: 1,
          yjms: 1,
          fcyzjjbf: 1,
          kqrlsbdl: 1,
          kqsjwspjk: 1,
          ids: null,
          xmid: null
        }
      }
      that.showPiciSet = !that.showPiciSet;
    },
    //删除项目
    deletePici(row) {
      let delCfg = {
        method: 'get',
        url: pcDelUrl,
        params: {
          "pcdm": null
        }
      };
      if (row) {
        delCfg.params.pcdm = row.pcdm;
        crudFun(delCfg).then((data) => {
          if (data) {
            row.visible = false;
            that.$message({
              showClose: true,
              message: '删除成功！',
              type: 'success'
            });
            that.getPici();
          }
        });
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择要删除的批次！',
          type: 'error'
        });
      }
    },
    //保存批次
    savePici(formName) {
      that.$refs[formName].validate((valid) => {
        if (valid) {
          let svCfg = {
            method: 'post',
            url: pcAddUrl,
            data: that.piciParm
          };
          if (that.selectPici) {
            svCfg.url = pcUpdateUrl;
          }
          if (that.mvSltItem) {
            svCfg.data.xmid = that.mvSltItem;
          }
          else {
            that.$message({
              showClose: true,
              message: '请选择项目！',
              type: 'error'
            });
          }
          crudFun(svCfg).then((data) => {
            if (data) {
              that.$message({
                showClose: true,
                message: '成功！',
                type: 'success'
              });
              that.addNewPiciPop('cancel');
              that.getPici();
              that.$emit('on-updateData', {type: 'batch'});
            }
          });
        }
        else {
          return false;
        }
      });
    },
    //添加项目负责人
    addPiciManagePop() {
      that.addManageVisible = !that.addManageVisible;
    },
    //取消项目设定
    cancelItemSet() {
      that.showItemSet = false;
    },
    //保存批次设定
    savePiciSet(formName) {
      that.$refs[formName].validate((valid) => {
        if (valid) {
          let setCfg = {
            method: 'post',
            url: pcUpdatePcszUrl,
            data: {}
          };
          if (that.selectPici) {
            setCfg.url += that.selectPici.pcdm;
          }
          else {
            that.$message({
              showClose: true,
              message: '缺少批次代码！',
              type: 'error'
            });
            return;
          }
          let copySet = that.$comn.cloneObj(that.piciForm);
          copySet.rwfpkxjs = that.piciForm.rwfpkxjs ? 1 : 0;
          delete copySet.xmfzr;
          setCfg.data = copySet;
          crudFun(setCfg).then((data) => {
            if (data) {
              that.$message({
                showClose: true,
                message: '批次设定成功！',
                type: 'success'
              });
              that.piciSetPop('cancel');
              that.getPici();
              that.$emit('on-updateData', {type: 'batch'});
            }
          });
        } else {
          that.$message({
            showClose: true,
            message: '带 * 的为必选项，请选择！',
            type: 'error'
          });
          return false;
        }
      });
    }
  },
  mounted() {
    if (that.mvSltItem) {
      that.getPici();
    }
    else {
      that.$message({
        showClose: true,
        message: '请选择项目！',
        type: 'error'
      });
    }
  },
  created() {
    that = this;
  },
  watch: {
    mvSltItem: {
      handler(newVal, oldVal) {
        // 数据发生变化时执行的操作
        if (that.mvSltItem) {
          that.getPici();
        }
      },
      deep: true, // 是否深度监听
      immediate: true, // 是否在组件创建时立即执行回调函数
    },
  }
}
</script>

<style lang="less" scoped></style>