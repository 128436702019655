<template>
  <div class="about">
    <div>
      <!-- <el-button type="text" @click="dealPuzzle()">测试</el-button> -->
      <div class="clRed">
        注意：“已提交数”表示已阅部分已经提交，阅卷分数不允许修改；“未提交数”表示已阅部分为未提交，阅卷分数允许修改。
      </div>
      <el-table class="mt15" border :data="taskTimuData" style="width: 100%;" :span-method="objectSpanMethod">
        <el-table-column prop="taskNo" label="任务编号" align="center">
        </el-table-column>
        <el-table-column prop="questionNo" label="题号" align="center">
        </el-table-column>
        <el-table-column prop="questionName" label="题型" align="center">
        </el-table-column>
        <el-table-column prop="totalCount" label="任务数量" align="center">
        </el-table-column>
        <el-table-column prop="finishCount" label="已提交数" align="center">
        </el-table-column>
        <el-table-column prop="draftCount" label="未提交数" align="center">
        </el-table-column>
        <el-table-column prop="" label="未完成数" align="center">
          <template slot-scope="scope">
            {{ (scope.row.totalCount || 0) - (scope.row.finishCount || 0) - ( scope.row.draftCount || 0) }}
          </template>
        </el-table-column>
        <el-table-column prop="" label="完成率" align="center">
          <template slot-scope="scope">
            {{ scope.row.wcl + '%' }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="zcType" label="仲裁类型" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.zcType == 1">分差</span>
            <span v-if="scope.row.zcType == 2">疑难</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">未处理</span>
            <span v-if="scope.row.state == 2">已处理</span>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="dealPuzzle(scope.row)">评卷</el-button>
            <el-popover placement="top" width="260" v-if="scope.row.draftCount" v-model="scope.row.visible" class="ml15">
              <p>提交后已阅部分的成绩不允许修改，确定提交吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="scope.row.visible = false">取消</el-button>
                <el-button type="primary" class="ml15" size="mini" @click="savePartMark(scope.row)">确定</el-button>
              </div>
              <el-button slot="reference" type="text" size="small">提交本次阅卷</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="yueJuanPage" v-if="showYeJuan" :class="{ timuWrapFold: !unfold }">
      <div class="timuWrap">
        <div class="tmwTiHao">
          <span v-if="unfold">
            题号 {{ selectTimu ? selectTimu.questionNo : '' }}
          </span>
          <span class="foldBtn" v-if="unfold" @click="foldFun()">
            <i class="el-icon-arrow-left"></i>折叠
          </span>
        </div>
        <span class="unfoldBtn" v-if="!unfold" @click="foldFun()">
          展<br>开<br><i class="el-icon-arrow-right"></i>
        </span>
        <ul class="zdxhList" v-if="selectTimu.questionType != 4 && unfold">
          <li v-for="zd in zuoDaList" :key="zd.teacherTaskDetailId" @click="clickDaTiList(zd)"
            :class="{ activeFont: selectZuoDa && selectZuoDa.id == zd.teacherTaskDetailId }">
            <span class="zdxhTitle">保密号</span>
            <span class="zdxhTitle" v-if="zd.stuScore === 0 || zd.stuScore > 0">
              ( {{ zd.stuScore }} 分 )
            </span>
            <span class="zdxhTitle clRed" v-if="zd.stuScore === -1">
              ( 疑难 )
            </span>
            <p class="m0"> 
              {{ zd.secrecyNo }}
            </p>
          </li>
        </ul>
      </div>
      <div class="zuodaWrap">
        <!-- 填空题 -->
        <div v-if="selectTimu && selectTimu.questionType == 4">
          <div class="tianKong" v-for="tk in zuoDaArr" :key="tk.id">
            <div class="zdNum">
              <span>共 {{ tk.stuHashCount }} 人作答</span>
              <span class="ml30" v-if="tk.arbitrateScore === 0 || tk.arbitrateScore > 0">
                仲裁分数: {{ tk.arbitrateScore }} 分
              </span>
              <span class="ml30" v-if="tk.markscr === 0 || tk.markscr > 0">
                阅卷分数: <span class="clRed">{{ tk.markscr }} </span> 分
                <em class="clRed" v-if="tk.arbitrateScore === 0 || tk.arbitrateScore > 0">
                  （ 注：有仲裁分数时，阅卷分数 = 仲裁分数 ）
                </em>
              </span>
            </div>
            <div class="tkCont">
              <div class="stuZuoDa">
                <div class="kong" v-for="(kong, idx) in selectTimu.responses" :key="idx"
                  v-html="tk.stuAnswerData[idx] || '&nbsp;'">
                </div>
              </div>
              <!-- <div class="tkBtns">
                <el-select class="scrSelect" size="small" v-model="tk.fillScr" placeholder="请选择" @change="fillMarkScore(tk)">
                  <el-option v-for="num in tk.scrNums" :key="num" :label="num" :value="num">
                  </el-option>
                </el-select>
                <el-button size="small" @click="fillHalfMarkScr(0.5, tk)">+0.5</el-button>
                <el-button size="small" @click="fillHalfMarkScr(-0.5, tk)">-0.5</el-button>
              </div> -->
              <div class="tkBtns">
                <el-button-group>
                  <el-button size="small" type="primary" @click="fillMarkScore('right', tk)"
                    :class="{ hasMarked: tk.fillScr === 'right' }">对</el-button>
                  <el-button size="small" type="primary" @click="fillMarkScore('half', tk)" v-if="tk.fillQuestionHalf"
                    :class="{ hasMarked: tk.fillScr === 'half' }">半对</el-button>
                  <el-button size="small" type="primary" @click="fillMarkScore('wrong', tk)"
                    :class="{ hasMarked: tk.fillScr === 'wrong' }">错</el-button>
                </el-button-group>
                <el-select class="scrSelect" size="small" v-if="tk.showOpt" v-model="tk.markscr" placeholder="请选择"
                  @change="fillHalfMarkScr(tk)">
                  <el-option v-for="num in tk.scrNums" :key="num" :label="num" :value="num">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <!-- 解答题 -->
        <div class="zgt-wrap" v-if="selectTimu && selectTimu.questionType == 5">
          <div v-if="selectZuoDa">
            <div class="stu-asw" v-for="(zd, idx) in selectZuoDa.stuAnswerData" :key="idx" v-html="zd">
            </div>
          </div>
          <div class="tch-score" v-if="selectZuoDa && selectZuoDa.markscr > -1">
            <span class="clRed">{{ selectZuoDa.markscr }}</span>
          </div>
        </div>
      </div>
      <div class="oprateWrap">
        <!-- <div>
          <el-button type="danger" size="mini" style="width: 100%;" @click="backToTiMu()">返回</el-button>
        </div> -->
        <div>
          <el-button type="text" @click="seeTiMu()">查看原题</el-button>
          <el-button type="text" class="flr" @click="backToTiMu()" style="color: #ff0000">返回</el-button>
        </div>
        <div class="mb15" v-if="selectTimu && selectTimu.questionType == 4">
          <span>每页显示：</span>
          <el-radio-group v-model="selectNpp" size="small" @change="changeTiKongNum">
            <el-radio-button :label="5">5个</el-radio-button>
            <el-radio-button :label="10">10个</el-radio-button>
            <el-radio-button :label="20">20个</el-radio-button>
          </el-radio-group>
          <!-- <el-select size="small" v-model="selectNpp" placeholder="请选择" @change="changeTiKongNum">
            <el-option v-for="item in numPerPage" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
        </div>
        <div v-if="selectTimu && selectTimu.questionType == 5">
          <el-button-group style="width: 100%;">
            <el-button style="width: 100%;" size="small" type="primary" @click="addYnPop('pop')">提交疑难</el-button>
            <!-- <el-button style="width: 50%;" size="small" type="primary" @click="submitComment()">批注</el-button> -->
          </el-button-group>
        </div>
        <div v-if="selectTimu.questionType != 4">
          <el-button type="primary" size="small" class="scoreBtn" v-for="(scr, idx) in scoreArr" :key="idx"
            :class="{ flr: idx % 2 == 1, hasMarked: selectZuoDa && (selectZuoDa.markscr === scr || selectZuoDa.markscr - 0.5 === scr) }"
            @click="zgtMarkScr(scr)">
            {{ scr }}
          </el-button>
          <div v-if="selectZuoDa && selectZuoDa.allowHalf">
            <el-button type="primary" size="small" class="scoreBtn" @click="zgtMarkScr(0.5)">
              + 0.5
            </el-button>
            <el-button type="primary" size="small" class="scoreBtn flr" @click="zgtMarkScr(-0.5)">
              - 0.5
            </el-button>
          </div>
        </div>
        <div class="mt15">
          <!-- 填空题翻页 -->
          <el-button-group style="width: 100%;" v-if="selectTimu.questionType == 4">
            <el-button style="width: 50%;" size="small" icon="el-icon-arrow-left" type="primary"
              @click="fillTurnPage(-1)">
              上一页
            </el-button>
            <el-button style="width: 50%;" size="small" type="primary" @click="fillTurnPage(1)">
              下一页<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </el-button-group>
          <!-- 问题题翻页 -->
          <el-button-group style="width: 100%;" v-else>
            <el-button style="width: 50%;" size="small" icon="el-icon-arrow-left" type="primary" @click="turnPageNew(-1)">
              上一题
            </el-button>
            <el-button style="width: 50%;" size="small" type="primary" @click="turnPageNew(1)">
              下一题<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </el-button-group>
        </div>
        <div class="hasMark" v-if="selectTimu && selectTimu.questionType == 4">
          <span>已批阅：</span>
          <span class="clYlw"> {{ tkHasMark }} / {{ zuoDaArr.length || 0 }}</span>
        </div>
      </div>
    </div>
    <div>
      <el-dialog title="提交疑难" :visible.sync="ynFormVisible" center width="40%">
        <el-form>
          <el-form-item label="疑难" label-width="120px">
            <el-input type="textarea" :rows="4" v-model="ynVal" placeholder="请输入疑难" maxlength="150"
              show-word-limit></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addYnPop('cancel')">取 消</el-button>
          <el-button class="ml30" type="primary" @click="submitPuzzle">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 引入题目子组件 -->
    <el-dialog class="timuDialog" v-el-drag-dialog title="查看题目" :visible.sync="tiMuVisible" width="40%" :modal="false"
      :close-on-click-modal="false">
      <TimuView v-if="tiMuVisible" :passTimu="selectTimu" @timuEvent="timuPageClose"></TimuView>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Lazy from 'lazy.js';
import { crudFun } from '@/libs/crud';
import TimuView from '@/components/TimuView.vue';
import elDragDialog from '@/directive/index';

let that = null;
// let getTaskInfoUrl = '/paper/getTaskInfo'; //小题组长任务分配-获取任务信息
let getTeacherTaskInfosUrl = '/grading/getTeacherTaskInfos'; //获取教师任务列表
let gradingLineTaskUrl = '/grading/line/getTask'; //获取题目的作答
let commitQuestionTaskUrl = '/grading/line/commitQuestionTask'; //添加作答
let commitQuestionTasksUrl = '/grading/line/commitQuestionTasks'; //提交教师已阅部分
let commitFillTaskkUrl = '/grading/line/commitFillTask'; //填空题提交
let getQuestionTaskAllUrl = '/grading/getQuestionTaskAll'; //需要阅卷的列表
let getLineTaskByTeacherTaskDetailIdUrl = '/grading/getLineTaskByTeacherTaskDetailId'; //查询作答内容
let saveDraftUrl = '/grading/saveDraft'; //保存草稿
let reDoNum = 5; //重试的次数
let tiXingObj = {
  1: '单选题',
  2: '多选题',
  3: '判断题',
  4: '填空题',
  5: '问答题',
  6: '选择填空题',
  7: '复合题'
};
export default {
  name: 'MarkingView',
  components: {
    TimuView
  },
  directives: {
    elDragDialog
  },
  data() {
    return {
      taskData: [],
      taskTimuData: [],
      showYeJuan: false,
      zuoDaList: [], //作答列表
      listIndex: null, //列表选择的
      zuoDaArr: [],
      scoreArr: [],
      numPerPage: [
        {
          value: 1,
          label: '每页显示 1 个'
        },
        {
          value: 2,
          label: '每页显示 2 个'
        },
        {
          value: 3,
          label: '每页显示 3 个'
        },
        {
          value: 4,
          label: '每页显示 4 个'
        },
        {
          value: 5,
          label: '每页显示 5 个'
        }
      ],
      selectNpp: 5,
      selectTimu: null,
      tiMuVisible: false,
      forceFresh: true,
      zgtLimit: 5, //主观题每次查询多少题
      zuoDaUnit: [],
      selectZuoDa: null, //选中的学生作答
      sltZdIdx: 0, //当前选中的作答索引
      ynFormVisible: false,
      ynVal: '',
      unfold: true,
      tkHasMark: 0
    }
  },
  computed: {
    ...mapState([
      'mvSltPici',
      'mvSltKemu'
    ])
  },
  methods: {
    //查询自己的任务
    getTask() {
      let tkCfg = {
        method: 'post',
        url: getTeacherTaskInfosUrl,
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu
        }
      };
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      that.taskData = [];
      that.taskTimuData = [];
      crudFun(tkCfg).then((data) => {
        if (data) {
          let tmArr = [];
          Lazy(data).sortBy('taskNo').each(function (task) {
            if (task.questionData) {
              task.questionData = that.$comn.toObj(task.questionData);
              Lazy(task.questionData).sortBy('questionNo').each(function (tm, idx) {
                tm.taskId = task.id;
                tm.questionName = tiXingObj[tm.questionType];
                tm.rspan = 0;
                if (idx == 0) {
                  tm.rspan = task.questionData.length;
                }
                if (tm.responses) {
                  tm.responses = that.$comn.toObj(tm.responses);
                }
                let wcsTmp = 0;
                if(tm.finishCount){
                  wcsTmp += +tm.finishCount;
                }
                if(tm.draftCount){
                  wcsTmp += +tm.draftCount;
                }
                tm.wcl = ((wcsTmp / (tm.totalCount || 1)) * 100).toFixed(1);
                tmArr.push(tm);
              });
            }
          });
          that.taskData = data;
          that.taskTimuData = tmArr;
        }
      });
    },
    //查询教师阅卷任务的列表
    getZuoDaListFun(row) {
      if(!row){
        that.$message({
          showClose: true,
          message: '请选择题目！',
          type: 'error'
        });
        return ;
      }
      let gtLst = {
        method: 'get',
        url: getQuestionTaskAllUrl,
        params: {
          questionId: row.identifier,
          taskId: row.taskId,
        }
      };
      crudFun(gtLst).then((data) => {
        if(data){
          console.log(data);
          Lazy(data).each(function(zd, idx){
            zd.index = idx;
          });
          that.zuoDaList = data;
          that.showYeJuan = true;
          that.getZuoDaDetail(0, 'fstIn');
        }
        else{
          that.zuoDaList = [];
        }
      });
    },
    //table的跨行和列
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (row.rspan > 0) {
          return {
            rowspan: row.rspan,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    //填空题每页显示多少
    changeTiKongNum() {
      that.zgtLimit = that.selectNpp;
      that.getZuoDaFromTimu(true);
    },
    //通过题目查询作答
    getZuoDaFromTimu(frsIn, rsl) {
      let qryMaxNum = 10;
      let count = 0;
      let timuType = null;
      let doFun = function () {
        let tmObj = {
          method: 'post',
          url: gradingLineTaskUrl,
          data: {
            taskId: "",
            questionId: "",
            // "teacherId": "",
            limit: that.zgtLimit
          }
        };
        if (that.selectTimu) {
          tmObj.data.taskId = that.selectTimu.taskId;
          tmObj.data.questionId = that.selectTimu.identifier;
          timuType = that.selectTimu.questionType;
        }
        else {
          that.$message({
            showClose: true,
            message: '缺少题目信息！',
            type: 'error'
          });
          return;
        }
        count++;
        if (count == qryMaxNum) {
          that.$message({
            showClose: true,
            message: '已尝试查询学生作答 ' + qryMaxNum + ' 次，没有查出作答数据，请重新',
            type: 'error'
          });
          return;
        }
        crudFun(tmObj).then((data) => {
          if (data) {
            if (data.length > 0) {
              Lazy(data).each(function (zd) {
                zd.markscr = -1;
                zd.saved = false;
                zd.fillScr = null;
                zd.showOpt = false;
                zd.scrNums = Lazy.generate(function (i) { return i + 1; }, +zd.score).toArray();
                // zd.scrNums.unshift(0);
                if (zd.questionData) {
                  zd.questionData = that.$comn.toObj(zd.questionData);
                  if (zd.questionData.responses) {
                    zd.questionData.responses = that.$comn.toObj(zd.questionData.responses);
                  }
                }
                if (zd.stuAnswerData) {
                  zd.stuAnswerData = that.$comn.toObj(zd.stuAnswerData);
                }
                if (zd.standardAnswerData) {
                  zd.standardAnswerData = that.$comn.toObj(zd.standardAnswerData);
                }
              });
              that.zuoDaUnit.push(data);
              if (frsIn) {
                that.showYeJuan = true;
                that.zuoDaArr = that.zuoDaUnit.shift();
                if (timuType == 4) {
                  that.fillTurnPage(0, 'firstIn');
                }
                else {
                  that.turnPage(0);
                }
              }
              else {
                if (rsl) {
                  rsl();
                }
              }
            }
            else {
              that.zuoDaArr = [];
              if (frsIn) {
                that.$message({
                  showClose: true,
                  message: '没有需要阅的学生作答！',
                  type: 'warning'
                });
                // setTimeout(function () {
                //   doFun();
                // }, 2000);
              }
              if (rsl) {
                rsl();
              }
            }
          }
          else {
            that.zuoDaArr = [];
            setTimeout(function () {
              doFun();
            }, 2000);
          }
        });
      }
      doFun();
    },
    //查询题目详情
    getZuoDaDetail(dir, fstIn) {
      let gtZdt = {
        method: 'post',
        url: getLineTaskByTeacherTaskDetailIdUrl,
        data: ""
      };
      let bgIdx = null;
      let edIdx = null;
      if(fstIn){
        if(fstIn == 'fstIn'){
          let fndUnscr = Lazy(that.zuoDaList).find(function(zd){
            return zd.stuScore == null || zd.stuScore == 'null' || zd.stuScore === '';
          });
          if(fndUnscr){
            that.listIndex = fndUnscr.index;
          }
        }
        bgIdx = that.listIndex - that.zgtLimit;
        edIdx = that.listIndex + that.zgtLimit;
      }
      else{
        if(dir > 0){ //下翻
          let lastZd = Lazy(that.zuoDaArr).last();
          if(lastZd){
            let lastZdIdx = lastZd.index;
            bgIdx = lastZdIdx + 1;
            edIdx = bgIdx + that.zgtLimit;
          }
        }
        else if(dir < 0){ //上翻
          let lastZd = Lazy(that.zuoDaArr).first();
          if(lastZd){
            let lastZdIdx = lastZd.index;
            edIdx = lastZdIdx;
            bgIdx = lastZdIdx - that.zgtLimit;
          }
        }
      }
      if(bgIdx < 0){
        bgIdx = 0;
      }
      if(edIdx > that.zuoDaList.length - 1){
        edIdx = that.zuoDaList.length;
      }
      let suitZuDa = that.zuoDaList.slice(bgIdx, edIdx); 
      let zdIds = Lazy(suitZuDa).map(function(zd){
        return zd.teacherTaskDetailId;
      }).toArray();
      if(zdIds && zdIds.length > 0){
        gtZdt.data = zdIds;
      }
      else{
        that.$message({
          showClose: true,
          message: '缺少题目信息！',
          type: 'error'
        });
        return;
      }
      crudFun(gtZdt).then((data) => {
        if(data && data.length > 0){
          Lazy(data).each(function(zd){
            zd.markscr = zd.stuScore === 0 || zd.stuScore > 0 ? zd.stuScore : -1;
            zd.saved = false;
            zd.fillScr = null;
            zd.showOpt = false;
            zd.scrNums = Lazy.generate(function (i) { return i + 1; }, +zd.score).toArray();
            if (zd.questionData) {
              zd.questionData = that.$comn.toObj(zd.questionData);
              if (zd.questionData.responses) {
                zd.questionData.responses = that.$comn.toObj(zd.questionData.responses);
              }
            }
            if (zd.stuAnswerData) {
              zd.stuAnswerData = that.$comn.toObj(zd.stuAnswerData);
            }
            if (zd.standardAnswerData) {
              zd.standardAnswerData = that.$comn.toObj(zd.standardAnswerData);
            }
            let fndLstZd = Lazy(that.zuoDaList).find(function(lzd){
              return lzd.teacherTaskDetailId == zd.id;
            });
            if(fndLstZd){
              zd.index = fndLstZd.index;
            }
            that.zuoDaArr.push(zd);
          });
          that.zuoDaArr = Lazy(that.zuoDaArr).uniq('id').sortBy('index').toArray();
          let listSltZd = that.zuoDaList[that.listIndex] || {};
          that.sltZdIdx = that.zuoDaArr.findIndex(item => item.id == listSltZd.teacherTaskDetailId);
          if(fstIn){
            that.turnPageNew(0);
          }
        }
      });
    },
    //打开评卷页面
    dealPuzzle(row) {
      if (row) {
        let scr = row.score || 0;
        that.scoreArr = Lazy.generate(function (i) { return i + 1; }, scr).toArray();
        that.scoreArr.unshift(0);
        row['修改题目'] = false;
        that.selectTimu = row;
        // that.showYeJuan = true;
        if(row.questionType == 5){
          that.getZuoDaListFun(row);
        }
        else{
          that.getZuoDaFromTimu('fstIn');
        }
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择题目！',
          type: 'error'
        });
      }
    },
    //返回需要阅的题目列表
    backToTiMu() {
      new Promise(function(resolve, reject){
        if(that.selectTimu && that.selectTimu.questionType == 5){
          return that.zgtSaveFun(resolve);
        }
        else{
          resolve();
        }
      })
      .then(function(){
        that.showYeJuan = false;
        that.zuoDaArr = [];
        that.selectZuoDa = null;
        that.sltZdIdx = 0;
        that.zuoDaUnit = [];
        that.getTask();
      });
    },
    //查看题目
    seeTiMu() {
      that.tiMuVisible = true;
    },
    //题目页面关闭
    timuPageClose(isMod) {
      that.tiMuVisible = false;
    },
    //填空题给分
    fillMarkScore(tp, zd) {
      zd.showOpt = false;
      switch (tp) {
        case 'right':
          zd.markscr = zd.score;
          break;
        case 'half':
          if (zd.markscr == -1) {
            zd.markscr = null;
          }
          zd.showOpt = true;
          break;
        case 'wrong':
          zd.markscr = 0;
          break;
      };
      zd.fillScr = tp;
      if (zd.arbitrateScore) {
        zd.markscr = zd.arbitrateScore;
      }
      that.countTkHasMark();
    },
    //显示题目分数列表
    fillHalfMarkScr(zd) {
      if (zd) {
        if (zd.arbitrateScore) {
          zd.markscr = zd.arbitrateScore;
        }
        zd.showOpt = false;
        that.countTkHasMark();
      }
    },
    //主观题给分
    zgtMarkScr(scr) {
      if (that.selectZuoDa) {
        if(that.selectZuoDa.draftFlag == 2){
          that.$message({
            showClose: true,
            message: '阅卷分数已提交，不允许修改！',
            type: 'error'
          });
          return ;
        }
        if (Math.abs(scr) == 0.5) {
          that.selectZuoDa.markscr += scr;
        }
        else {
          that.selectZuoDa.markscr = scr;
        }
        if (that.selectZuoDa.markscr < 0) {
          that.selectZuoDa.markscr = 0;
        }
        if (that.selectZuoDa.markscr > that.selectZuoDa.score) {
          that.selectZuoDa.markscr = that.selectZuoDa.score;
        }
        // that.forceRerender();
      }
    },
    //问答题，翻页
    turnPage(dir) {
      dir = +dir;
      that.tkHasMark = 0;
      new Promise(function (resolve, reject) {
        let sltZd = that.selectZuoDa;
        let zdArrLen = that.zuoDaArr ? that.zuoDaArr.length : 0;
        if (sltZd && sltZd.markscr > -1) {
          let scrCfg = {
            method: 'post',
            url: commitQuestionTaskUrl,
            data: {
              "teacherTaskDetailId": sltZd.id,
              "stuScore": sltZd.markscr
            }
          };
          crudFun(scrCfg).then((data) => {
            if (data) {
              that.selectZuoDa.saved = true;
              if (that.zuoDaArr && zdArrLen == that.zgtLimit) {
                let fndSaved = Lazy(that.zuoDaArr).filter(function (zd) {
                  return zd.saved;
                }).toArray();
                if (fndSaved.length == that.zgtLimit) {
                  // that.selectZuoDa = null;
                  return that.getZuoDaFromTimu(false, resolve);
                }
                else {
                  resolve();
                }
              }
              else {
                // let fndSaved = Lazy(that.zuoDaArr).filter(function (zd) {
                //   return zd.saved;
                // }).toArray();
                // // if (fndSaved.length == zdArrLen && dir != -1) {
                // if (fndSaved.length == zdArrLen) {
                //   // reject('此题目已没有阅卷任务！');
                //   resolve();
                // }
                // else {
                //   resolve();
                // }
                resolve();
              }
            }
            else {
              reject(data);
            }
          });
        }
        else {
          resolve();
        }
      })
      .then(function () {
        return new Promise(function (resolve, reject) {
          that.sltZdIdx += dir;
          //到第一题了
          if (that.sltZdIdx < 0) {
            that.sltZdIdx = 0;
            resolve();
          }
          //到最后一题了
          else if (that.sltZdIdx >= that.zgtLimit) {
            if (that.zuoDaUnit && that.zuoDaUnit.length > 0) {
              that.zuoDaArr = that.zuoDaUnit.shift();
              that.sltZdIdx = 0;
              resolve();
            }
            else {
              that.sltZdIdx = that.zgtLimit - 1;
              reject('此题目已没有阅卷任务！');
            }
          }
          else {
            resolve();
          }
        });
      })
      .then(function () {
        if (that.zuoDaArr && that.zuoDaArr.length > 0) {
          let sltZuoDa = that.zuoDaArr[that.sltZdIdx];
          if (sltZuoDa) {
            let stuAswDataTmp = sltZuoDa.stuAnswerData;
            let newAsws = [];
            if (stuAswDataTmp && stuAswDataTmp.length > 0) {
              sltZuoDa.stuAnswerData = that.$comn.toObj(sltZuoDa.stuAnswerData);
              let stuAswData = that.$comn.cloneObj(sltZuoDa.stuAnswerData);
              let dealImg = function (zsds) {
                let zd = zsds.shift();
                // let idxOf = zd.indexOf('<img');
                let regex = /<img.*?src="(.*?)"/g;
                let match;
                let srcList = [];
                while (match = regex.exec(zd)) {
                  //match返回一个数组，数组中有两个数值
                  //第一个是img整个标签字段，第二个是src中匹配的内容
                  srcList.push(match[1]);
                }
                if (srcList && srcList.length > 0) {
                  // let zdBase = zd.slice(10, -2);
                  let zdBase = srcList[0];
                  let imgNew = new Image();
                  imgNew.onload = function () {
                    let imgWd = imgNew.width;
                    let imgHg = imgNew.height;
                    let elHas = document.querySelector('#imgCanvas');
                    if (elHas) {
                      elHas.remove();
                    }
                    let dstCanvas = document.createElement('canvas');
                    dstCanvas.id = 'imgCanvas';
                    dstCanvas.width = imgWd;
                    dstCanvas.height = imgHg;
                    let dstContext = dstCanvas.getContext('2d');
                    if (imgWd && imgHg) {
                      dstContext.drawImage(imgNew, 0, 0, imgWd, imgHg, 0, 0, imgWd, imgHg);
                      let imgData = dstContext.getImageData(0, 0, imgWd, imgHg);
                      let imgDtDot = imgData.data;
                      let index = 0;
                      let yVal = 0;
                      for (var y = 0; y < imgHg; y++) {
                        yVal = 0;
                        for (var x = 0; x < imgWd; x++) {
                          index = (y * imgWd + x) * 4;
                          if (imgDtDot[index] != 255 || imgDtDot[index + 1] != 255 || imgDtDot[index + 2] != 255) {
                            let imgTmp = '<img src="' + zdBase + '" style="margin-top:-' + y + 'px">';
                            newAsws.push(imgTmp);
                            yVal = y;
                            break;
                          }
                        }
                        if (yVal) {
                          break;
                        }
                      }
                    }
                    if (zsds && zsds.length > 0) {
                      dealImg(zsds);
                    }
                    else {
                      sltZuoDa.stuAnswerData = newAsws;
                      that.selectZuoDa = sltZuoDa;
                    }
                  };
                  imgNew.src = zdBase;
                }
                else {
                  newAsws.push(zd);
                  if (zsds && zsds.length > 0) {
                    dealImg(zsds);
                  }
                  else {
                    sltZuoDa.stuAnswerData = newAsws;
                    that.selectZuoDa = sltZuoDa;
                  }
                }
              }
              dealImg(stuAswData);
            }
            else {
              that.selectZuoDa = sltZuoDa;
            }
          }
          else {
            that.$message({
              showClose: true,
              message: '此题目已没有阅卷任务！',
              type: 'warning'
            });
            that.backToTiMu();
          }
        }
        else {
          that.$message({
            showClose: true,
            message: '此题目已没有阅卷任务！',
            type: 'warning'
          });
          that.backToTiMu();
        }
      })
      .catch(function (err) {
        that.$message({
          showClose: true,
          message: err,
          type: 'warning'
        });
        if (err == '此题目已没有阅卷任务！') {
          that.backToTiMu();
        }
      });
    },
    //主观题保存
    zgtSaveFun(resolve) {
      let sltZd = that.selectZuoDa;
      if (sltZd && (sltZd.markscr === 0 || sltZd.markscr > 0)) {
        let saveCount = 0;
        let _saveFun = function(){
          if(saveCount > reDoNum){
            that.$message({
              showClose: true,
              message: '已尝试保存作答 ' + reDoNum + ' 次，请重新点击“下一题”！',
              type: 'error'
            });
            return;
          }
          let scrCfg = {
            method: 'post',
            url: saveDraftUrl,
            data: {
              teacherTaskDetailId: sltZd.id,
              stuScore: sltZd.markscr
            }
          };
          crudFun(scrCfg).then((data) => {
            if (data) {
              that.selectZuoDa.saved = true;
              let fndLstZd = Lazy(that.zuoDaList).find(function(lzd){
                return lzd.teacherTaskDetailId == sltZd.id;
              });
              if(fndLstZd){
                fndLstZd.stuScore = sltZd.markscr;
              }
              if(resolve){
                resolve();
              }
            }
            else {
              saveCount ++;
              setTimeout(function(){
                _saveFun();
              }, 2000);
            }
          });
        };
        _saveFun();
      }
      else {
        if(resolve){
          resolve();
        }
      }
    },
    //问答题，翻页
    turnPageNew(dir, idx) {
      dir = +dir;
      that.tkHasMark = 0;
      new Promise(function (resolve, reject) {
        return that.zgtSaveFun(resolve);
      })
      .then(function () {
        return new Promise(function (resolve, reject) {
          that.sltZdIdx += dir;
          if(idx === 0 || idx > 0){
            that.sltZdIdx = idx;
          }
          //到第一题了
          if (that.sltZdIdx < 0) {
            that.sltZdIdx = 0;
            reject('已经到头了，此题目已没有阅卷任务！');
          }
          //到最后一题了
          else if (that.sltZdIdx >= that.zuoDaArr.length) {
            that.sltZdIdx = that.zuoDaArr.length - 1;
            reject('已经到尾了，此题目已没有阅卷任务！');
          }
          else {
            resolve();
          }
        });
      })
      .then(function () {
        if (that.zuoDaArr && that.zuoDaArr.length > 0) {
          let sltZuoDa = that.zuoDaArr[that.sltZdIdx];
          if (sltZuoDa) {
            let stuAswDataTmp = sltZuoDa.stuAnswerData;
            let newAsws = [];
            that.listIndex = sltZuoDa.index;
            if (stuAswDataTmp && stuAswDataTmp.length > 0) {
              sltZuoDa.stuAnswerData = that.$comn.toObj(sltZuoDa.stuAnswerData);
              let stuAswData = that.$comn.cloneObj(sltZuoDa.stuAnswerData);
              let dealImg = function (zsds) {
                let zd = zsds.shift();
                // let idxOf = zd.indexOf('<img');
                let regex = /<img.*?src="(.*?)"/g;
                let match;
                let srcList = [];
                while (match = regex.exec(zd)) {
                  //match返回一个数组，数组中有两个数值
                  //第一个是img整个标签字段，第二个是src中匹配的内容
                  srcList.push(match[1]);
                }
                if (srcList && srcList.length > 0) {
                  // let zdBase = zd.slice(10, -2);
                  let zdBase = srcList[0];
                  let imgNew = new Image();
                  imgNew.onload = function () {
                    let imgWd = imgNew.width;
                    let imgHg = imgNew.height;
                    let elHas = document.querySelector('#imgCanvas');
                    if (elHas) {
                      elHas.remove();
                    }
                    let dstCanvas = document.createElement('canvas');
                    dstCanvas.id = 'imgCanvas';
                    dstCanvas.width = imgWd;
                    dstCanvas.height = imgHg;
                    let dstContext = dstCanvas.getContext('2d');
                    if (imgWd && imgHg) {
                      dstContext.drawImage(imgNew, 0, 0, imgWd, imgHg, 0, 0, imgWd, imgHg);
                      let imgData = dstContext.getImageData(0, 0, imgWd, imgHg);
                      let imgDtDot = imgData.data;
                      let index = 0;
                      let yVal = 0;
                      for (var y = 0; y < imgHg; y++) {
                        yVal = 0;
                        for (var x = 0; x < imgWd; x++) {
                          index = (y * imgWd + x) * 4;
                          if (imgDtDot[index] != 255 || imgDtDot[index + 1] != 255 || imgDtDot[index + 2] != 255) {
                            let imgTmp = '<img src="' + zdBase + '" style="margin-top:-' + y + 'px">';
                            newAsws.push(imgTmp);
                            yVal = y;
                            break;
                          }
                        }
                        if (yVal) {
                          break;
                        }
                      }
                    }
                    if (zsds && zsds.length > 0) {
                      dealImg(zsds);
                    }
                    else {
                      sltZuoDa.stuAnswerData = newAsws;
                      that.selectZuoDa = sltZuoDa;
                    }
                  };
                  imgNew.src = zdBase;
                }
                else {
                  newAsws.push(zd);
                  if (zsds && zsds.length > 0) {
                    dealImg(zsds);
                  }
                  else {
                    sltZuoDa.stuAnswerData = newAsws;
                    that.selectZuoDa = sltZuoDa;
                  }
                }
              }
              dealImg(stuAswData);
            }
            else {
              that.selectZuoDa = sltZuoDa;
            }
            //查询更多作答
            if((dir > 0 && that.sltZdIdx == that.zuoDaArr.length - 3) || (dir < 0 && that.sltZdIdx == 2)){
              that.getZuoDaDetail(dir);
            }
          }
          // else {
          //   that.$message({
          //     showClose: true,
          //     message: '此题目已没有阅卷任务！',
          //     type: 'warning'
          //   });
          //   that.backToTiMu();
          // }
        }
        else {
          that.$message({
            showClose: true,
            message: '此题目已没有阅卷任务！',
            type: 'warning'
          });
          that.backToTiMu();
        }
      })
      .catch(function (err) {
        that.$message({
          showClose: true,
          message: err,
          type: 'warning'
        });
        if (err == '已经到尾了，此题目已没有阅卷任务！') {
          that.backToTiMu();
        }
      });
    },
    //填空题，翻页
    fillTurnPage(dir, firstIn) {
      dir = +dir;
      that.tkHasMark = 0;
      new Promise(function (resolve, reject) {
        if (firstIn) {
          resolve();
        }
        else {
          let sltZd = that.zuoDaArr;
          if (sltZd && sltZd.length > 0) {
            let scrCfg = {
              method: 'post',
              url: commitFillTaskkUrl,
              data: {
                "taskId": "",
                "questionId": "",
                "fillScoreBos": []
              }
            };
            if (that.selectTimu) {
              scrCfg.data.taskId = that.selectTimu.taskId;
              scrCfg.data.questionId = that.selectTimu.identifier;
            }
            let pyArr = [];
            Lazy(sltZd).each(function (zd) {
              let pyTmp = {
                "stuAnswerHash": "",
                "stuScore": ""
              };
              if (zd.markscr > -1) {
                pyTmp.stuAnswerHash = zd.stuAnswerHash;
                pyTmp.stuScore = zd.markscr;
                pyArr.push(pyTmp);
              }
            });
            if (sltZd.length == pyArr.length) {
              scrCfg.data.fillScoreBos = pyArr;
            }
            else {
              reject('页面题目全部给分以后才能进行下一题！');
              return;
            }
            crudFun(scrCfg).then((data) => {
              if (data) {
                return that.getZuoDaFromTimu(false, resolve);
              }
              else {
                reject(data);
              }
            });
          }
          else {
            reject('没有任何学生作答数据！');
          }
        }
      })
        .then(function () {
          if (!firstIn) {
            if (that.zuoDaUnit && that.zuoDaUnit.length > 0) {
              that.zuoDaArr = that.zuoDaUnit.shift();
            }
            else {
              that.$message({
                showClose: true,
                message: '此题目已没有阅卷任务！',
                type: 'warning'
              });
              that.backToTiMu();
            }
          }
        })
        .catch(function (err) {
          that.$message({
            showClose: true,
            message: err,
            type: 'warning'
          });
        });
    },
    //点击答题ID
    clickDaTiList(zd) {
      if(zd){
        let sltIdx = that.zuoDaArr.findIndex(item => item.id == zd.teacherTaskDetailId);
        if(sltIdx === 0 || sltIdx > 0){
          that.turnPageNew(0, sltIdx);
        }
        else{
          that.listIndex = zd.index;
          that.zuoDaArr = [];
          that.getZuoDaDetail(0, true);
        }
      }
      else{
        that.$message({
          showClose: true,
          message: '请选择要查看的题目！',
          type: 'warning'
        });
      }
    },
    //提交疑难
    submitPuzzle() {
      let sltZd = that.selectZuoDa;
      if (!sltZd) {
        that.$message({
          showClose: true,
          message: '缺少作答信息！',
          type: 'warning'
        });
      }
      let plObj = {
        method: 'post',
        // url: commitQuestionTaskUrl,
        url: saveDraftUrl,
        data: {
          teacherTaskDetailId: sltZd.id,
          stuScore: -1,
          problemRemark: that.ynVal
        }
      };
      crudFun(plObj).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '疑难提交成功！',
            type: 'warning'
          });
          that.addYnPop('cancel');
        }
      });
    },
    //提交批注
    submitComment() {
      let cmObj = {
        method: 'post',
        url: '',
        data: {

        }
      };
      crudFun(cmObj).then((data) => {
        if (data) {

        }
      });
    },
    //强制刷新dom
    forceRerender: function (tp) {
      that.forceFresh = false;
      that.$nextTick().then(() => {
        that.forceFresh = true;
      });
    },
    //提交疑难的弹出
    addYnPop(tp) {
      if (tp && tp == 'pop') {
        that.ynFormVisible = true;
      }
      else {
        that.ynFormVisible = false;
      }
      that.ynVal = '';
    },
    //展开和折叠
    foldFun() {
      that.unfold = !that.unfold;
    },
    //统计填空题阅了多少
    countTkHasMark() {
      let tkCount = 0;
      Lazy(that.zuoDaArr).each(function(zd){
        if(zd.markscr === 0 || zd.markscr > 0){
          tkCount ++;
        }
      });
      that.tkHasMark = tkCount;
    },
    // 保存部分阅卷
    savePartMark(row) {
      console.log(row);
      if(!row){
        that.$message({
          showClose: true,
          message: '请选择要保存的题目！',
          type: 'warning'
        });
        return ;
      }
      let ptPm = {
        method: 'get',
        url: commitQuestionTasksUrl,
        params: {
          questionId: row.identifier,
          taskId: row.taskId
        }
      };
      if(!row.identifier){
        that.$message({
          showClose: true,
          message: '缺少题目ID！',
          type: 'warning'
        });
        return ;
      }
      if(!row.taskId){
        that.$message({
          showClose: true,
          message: '缺少任务ID！',
          type: 'warning'
        });
        return ;
      }
      crudFun(ptPm).then((data) => {
        if(data){
          row.visible = false;
          that.$message({
            showClose: true,
            message: '提交本次阅卷成功！',
            type: 'success'
          });
          that.getTask();
        }
      });
    }
  },
  mounted() {
    that.getTask();
  },
  created() {
    that = this;
  },
  watch: {
    mvSltKemu: {
      handler(newVal, oldVal) {
        // 数据发生变化时执行的操作
        if (that.mvSltKemu) {
          that.getTask();
        }
      },
      deep: true, // 是否深度监听
      // immediate: true, // 是否在组件创建时立即执行回调函数
    },
  }
}
</script>

<style lang="less" scoped>
.yueJuanPage {
  position: fixed;
  width: 100%;
  top: 48px;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: #ffffff;

  // display: none;
  .timuWrap {
    position: absolute;
    background-color: #1163a6;
    width: 200px;
    top: 0;
    bottom: 0;
    color: #ffffff;
    padding: 15px;
    box-sizing: border-box;
  }

  .tmwTiHao {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    border-bottom: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
    box-sizing: border-box;
    background-color: #00458d;
  }

  .zdxhList {
    list-style: none;
    margin: 0;
    padding: 10px 0;
    position: absolute;
    top: 40px;
    right: 0px;
    left: 15px;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;

    li {
      padding: 5px 0;
      cursor: pointer;
    }

    .zdxhTitle {
      padding-right: 10px;
    }
  }

  .zdxhList::-webkit-scrollbar {
    width: 6px;
  }

  .zdxhList::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(146, 146, 146, 0.8);
  }

  .zdxhList::-webkit-scrollbar-track {
    border-radius: 6px;
    background: rgba(206, 206, 206, 0.8);
  }

  .oprateWrap {
    position: absolute;
    border-left: 1px solid #949494;
    width: 200px;
    top: 0;
    bottom: 0;
    right: 0;
    // color: #ffffff;
    padding: 15px;
    box-sizing: border-box;
    overflow-y: auto;

    .el-button+.el-button {
      margin-left: 0;
    }
  }

  .scoreBtn {
    width: 45%;
    font-size: 16px;
    margin-top: 10px;
  }

  .zuodaWrap {
    position: absolute;
    top: 0px;
    left: 215px;
    right: 215px;
    bottom: 0;
    padding: 15px 0;
    overflow-y: auto;
    // width: 100%;
  }

  .tianKong {
    margin-top: 20px;
  }

  .zdNum {
    font-size: 16px;
    padding-left: 10px;
  }

  .tkCont {
    margin-top: 10px;
    padding: 10px;
    position: relative;
    min-height: 24px;
    background-color: #ebebeb;
    line-height: 32px;
    padding-right: 270px;
    border-radius: 6px;

    .kong {
      margin-right: 15px;
      display: inline-block;
      padding: 0 10px;
      border-bottom: 1px solid #000;
    }

    .tkBtns {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -16px;
    }
  }

  .tkBtns {
    .el-button {
      width: 80px;
    }
  }

  .scrSelect {
    position: absolute !important;
    left: 80px;
    top: 35px;
    right: 60px;
  }

  .tch-score {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 48px;
  }

  .stu-asw img {
    max-width: 800px;
  }

  .foldBtn {
    cursor: pointer;
    float: right;
  }

  .unfoldBtn {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 45%;
    left: 6px;
  }
  .hasMark{
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
  }
}

.timuWrapFold {
  .timuWrap {
    width: 30px;
  }

  .zuodaWrap {
    left: 40px;
  }
}
</style>