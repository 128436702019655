<template>
  <div class="about">
    <!-- <el-button size="small" type="primary" @click="getPjryFun()">查询评卷人员</el-button> -->
    <!-- 是否选择科目 -->
    <div class="ftz20 clRed" v-if="!mvSltKemu">
      请选择科目！
    </div>
    <!-- 科目详情 -->
    <div class="kemuInfo" v-if="pageNav == 'kemu'">
      <table class="el-table" style="width: 50%;" v-if="kemuParm">
        <tr>
          <td>科目：{{ kemuParm.kmmc }}</td>
          <td>科目代码：{{ kemuParm.kmdm }}</td>
        </tr>
        <tr>
          <td>报名人数：{{ kemuParm.bmrs || 0 }}</td>
          <td>实考人数：{{ kemuParm.skrs || 0 }}</td>
        </tr>
        <tr>
          <td>客观题数量：{{ kemuParm.sjkgts || 0 }}</td>
          <td>主观题数量：{{ kemuParm.sjzgts || 0 }}</td>
        </tr>
        <tr>
          <td>
            是否录入标答：
            <span v-if="kemuParm.bddrzt == 1">是</span>
            <span v-else>否</span>
          </td>
          <td>
            <el-button size="small" type="primary" @click="pageGoTo('biaoda')">标答上传</el-button>
          </td>
        </tr>
        <tr>
          <td>任务划分：
            <span v-if="kemuParm.rwhfzt == 1">完成</span>
            <span v-else>未完成</span>
          </td>
          <td>
            <el-button size="small" type="primary" @click="pageGoTo('renwu')">任务划分</el-button>
          </td>
        </tr>
      </table>
    </div>
    <!-- 标答上传 -->
    <div v-if="pageNav == 'biaoda'">
      <el-row>
        <el-col :span="2">
          <span class="ftz18 fll" style="margin-top: 3px;">标答上传</span>
        </el-col>
        <el-col :span="20">
          <div v-if="renderComponent">
            <!-- 暂时注销 -->
            <!-- <el-button class="fll" size="small" type="primary" @click="selectMethod(1)">导入CQTI标答包</el-button>
            <span class="separator">|</span> -->
            <el-upload class="upload-demo fll" ref="upload" action="" :on-remove="removeFile" :on-change="getFileInfoZip"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">导入CQTI试卷包</el-button>
            </el-upload>
            <el-upload class="upload-demo fll ml15" ref="upload" action="" :on-remove="removeFile"
              :on-change="getFileInfoExcel" :auto-upload="false">
              <!-- :auto-upload="false" v-if="upMethod == 2"> -->
              <el-button slot="trigger" size="small" type="primary">客观题答案导入</el-button>
            </el-upload>
            <el-button class="fll ml15" size="small" type="text" @click="downloadKgtTemplate">下载客观题答案模板</el-button>
          </div>
        </el-col>
        <el-col :span="2">
          <el-button class="flr" size="small" @click="pageGoTo('kemu')">返回</el-button>
        </el-col>
      </el-row>
      <el-table class="mt15" border :data="timuData" style="width: 100%;">
        <el-table-column prop="paperId" label="试卷ID" align="center">
        </el-table-column>
        <el-table-column prop="questionNo" label="题号" align="center">
        </el-table-column>
        <el-table-column prop="questionName" label="题型" align="center">
        </el-table-column>
        <el-table-column prop="score" label="分值" align="center">
        </el-table-column>
        <el-table-column prop="" label="答案/评分标准" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.answerdeal }}</span>
            <!-- <span v-if="scope.row.questionType < 4">
              {{ scope.row.answers.answerContent || '未添加答案' }}
            </span>
            <span v-else>已写入</span> -->
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button @click="seeAnswer(scope.row)" type="text" size="small">查看</el-button>
            <el-button @click="modifyAnswer(scope.row)" type="text" size="small">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt15 algRt">
        <el-pagination background layout="total, prev, pager, next" :total="page.total" :page-size="page.size"
          :current-page.sync="page.current" @current-change="getTiMu()">
        </el-pagination>
      </div>
      <!-- 引入题目子组件 -->
      <el-dialog title="查看题目" :visible.sync="tiMuVisible" width="50%" center>
        <TimuView v-if="tiMuVisible" :passTimu="selectTimu" @timuEvent="timuPageClose"></TimuView>
      </el-dialog>
    </div>
    <!-- 任务划分 -->
    <div v-if="pageNav == 'renwu'">
      <div class="algRt">
        <span class="ftz18 ftw fll" style="margin-top: 3px;">任务划分</span>
        <el-button size="small" @click="pageGoTo('kemu')">返回</el-button>
      </div>
      <ul class="listStyle" v-if="kemuParm">
        <li>
          <span class="liLabel">科目：</span>
          <span>{{ kemuParm.kmmc }}</span>
        </li>
        <li>
          <span class="liLabel">报名人数：</span>
          <span>{{ kemuParm.bmrs }} 人</span>
        </li>
        <li>
          <span class="liLabel">实考人数：</span>
          <span>{{ kemuParm.skrs || 0 }} 人</span>
        </li>
        <li>
          <span class="ftz16 ftw">客观题任务</span>
        </li>
        <li>
          <span class="liLabel">客观题核分员：</span>
          <span v-if="kemuParm.kgthfy">
            <span v-for="(hfy, idx) in kemuParm.kgthfy" :key="hfy.id">
              {{ hfy.xm }} <font v-if="kemuParm.kgthfy.length - 1 != idx">、</font>
            </span>
          </span>
          <el-upload class="upload-demo upload-ilb ml15" ref="upload" action="" :on-remove="removeFile"
            :on-change="getFileInfoKgthfy" :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">导入客观题核分员</el-button>
          </el-upload>
          <a class="tplAstyle" href="/template/客观题核分员模板.xlsx" download="客观题核分员模板.xlsx">
            客观题核分员模板.xlsx
          </a>
        </li>
        <!-- <li>
          <span class="liLabel">多选题评分标准：</span>
          <el-radio-group v-model="dxtPfbz">
            <div class="ptb5">
              <el-radio label="1">评分标准一</el-radio>
            </div>
            <div class="ptb5">
              <el-radio label="2">评分标准二</el-radio>
            </div>
            <div class="ptb5">
              <el-radio label="3">评分标准三</el-radio>
            </div>
            <div class="ptb5">
              <el-radio label="4">评分标准四</el-radio>
            </div>
          </el-radio-group>
        </li> -->
        <li>
          <span class="ftz16 ftw">主观题任务</span>
        </li>
        <li>
          <el-button size="small" type="primary" @click="mergePjTask" v-if="kemuParm.rwhfzt != 1">合并评卷任务</el-button>
          <el-button class="ml15" size="small" type="primary" @click="unmergePjTask" v-if="kemuParm.rwhfzt != 1">取消合并</el-button>
          <el-button class="ml15" size="small" type="primary" @click="divideDone" v-if="kemuParm.rwhfzt != 1">发布任务</el-button>
          <!-- <el-button class="ml15" size="small" type="primary" @click="setThresholdPop('pop')">批量设定阈值</el-button> -->
          <el-upload class="upload-demo upload-ilb ml15" ref="upload" action="" :on-remove="removeFile"
            :on-change="getFileInfoPjry" :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">导入评卷人员</el-button>
          </el-upload>
          <a class="tplAstyle" href="/template/评卷人员模板.xlsx" download="评卷人员模板.xlsx">
            评卷人员模板.xlsx
          </a>
        </li>
        <li>
          <el-table class="mt15 zgt-table" border :data="zgtTimuData" style="width: 100%;" ref="multipleTable"
            @selection-change="handleSelectionChange" :span-method="objectSpanMethod">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="questionNo" label="题号" align="center">
            </el-table-column>
            <el-table-column prop="questionName" label="题型" align="center">
            </el-table-column>
            <!-- <el-table-column prop="sjId" label="预估作答" align="center">
            </el-table-column> -->
            <el-table-column prop="score" label="分值" align="center">
            </el-table-column>
            <el-table-column prop="tmScore" label="考生作答" align="center">
            </el-table-column>
            <el-table-column prop="taskNo" label="任务编号" align="center">
            </el-table-column>
            <el-table-column prop="threshold" label="分差阈值" align="center">
            </el-table-column>
            <el-table-column prop="" label="小题组长" align="center">
              <template slot-scope="scope">
                <p v-for="xtzz in scope.row.groupUsers" :key="xtzz.userId">
                  {{ xtzz.xm }}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="pjjs" label="评卷教师" align="center">
              <template slot-scope="scope">
                共 {{ scope.row.scoreUsers.length || 0 }} 人
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
              <template slot-scope="scope">
                <el-button @click="setThresholdPop('pop', scope.row)" type="text" size="small">设定阈值</el-button>
                <el-button @click="modPjryPop('pop', scope.row)" type="text" size="small">修改评卷人员</el-button>
              </template>
            </el-table-column>
          </el-table>
        </li>
      </ul>
    </div>
    <div>
      <!-- 修改阈值 -->
      <el-dialog title="设定阈值" :visible.sync="thresholdVisible" width="500px" center>
        <el-form>
          <el-form-item label="任务编号" label-width="120px">
            <span>{{ selectTaskNo.join('、') }}</span>
          </el-form-item>
          <el-form-item label="阈值" label-width="120px">
            <el-input style="width: 200px;" v-model="thresholdVal" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="setThresholdPop('cancel')">取 消</el-button>
          <el-button size="small" class="ml15" type="primary" @click="batchSetThcreshold">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 修改评卷人员 -->
      <el-dialog title="修改评卷人员" :visible.sync="pjryTableVisible" width="80%" :close-on-click-modal="false"
        :close-on-press-escape="false" :show-close="false" :center="true">
        <el-dialog width="30%" title="修改用户" :visible.sync="innerVisible" append-to-body :close-on-click-modal="false"
          :close-on-press-escape="false" :show-close="false">
          <el-form :model="userParm" :rules="usrRules" ref="userParm" label-width="160px" style="width: 80%;">
            <el-form-item label="姓名" prop="xm">
              <el-input v-model="userParm.xm"></el-input>
            </el-form-item>
            <el-form-item label="角色" prop="jsdm">
              <el-select v-model="userParm.jsdm" placeholder="请选择角色">
                <el-option v-for="juese in jueseData" :key="juese.jsdm" :label="juese.jsmc" :value="juese.jsdm">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号" prop="sjh">
              <el-input v-model="userParm.sjh"></el-input>
            </el-form-item>
            <el-form-item label="证件号" prop="zjh">
              <el-input v-model="userParm.zjh"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" size="small" @click="saveUserInfo('userParm')">保存</el-button>
            <el-button class="ml15" size="small" @click="modUserPop('cancel')">取消</el-button>
          </div>
        </el-dialog>
        <div>
          <div class="ftz18 mt3 mr30 fll" v-if="selectTimu">
            任务编号：{{ selectTimu.taskNo }}
          </div>
          <el-upload class="upload-demo fll" ref="upload" action="" :on-remove="removeFile" :on-change="getFileInfoNewPjy"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">导入新增人员</el-button>
          </el-upload>
          <el-button size="small" class="ml15" type="primary" @click="deletePjryFun('all')">清空所有人员</el-button>
          <a class="tplAstyle" href="/template/新增人员模板.xlsx" download="新增人员模板.xlsx">
            新增人员模板.xlsx
          </a>
        </div>
        <el-table class="mt15" border :data="timuPjryArr" style="width: 100%;" ref="pjryTable">
          <el-table-column prop="xm" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="taskNo" label="任务编号" align="center">
          </el-table-column>
          <el-table-column prop="jsmc" label="角色" align="center">
          </el-table-column>
          <el-table-column prop="sjh" label="手机号" align="center">
          </el-table-column>
          <!-- <el-table-column prop="zjhm" label="证件号码" align="center">
          </el-table-column> -->
          <el-table-column prop="taskCount" label="已分配任务" align="center">
          </el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-button @click="modUserPop('pop', scope.row)" type="text" size="small">修改</el-button>
              <el-popover placement="top" width="160" v-model="scope.row.visible" class="ml15"
                v-show="!scope.row.taskCount">
                <p>确定要删除此用户吗？</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="scope.row.visible = false">取消</el-button>
                  <el-button type="primary" class="ml15" size="mini" @click="deletePjryFun(scope.row)">确定</el-button>
                </div>
                <el-button slot="reference" type="text" size="small">删除</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="modPjryPop('cancel')">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Lazy from 'lazy.js';
import TimuView from './TimuView.vue';
import { crudFun } from '@/libs/crud';
import { saveAs } from 'file-saver';

let that = null;
let importPaperPackageUrl = '/paper/importPaperPackage'; //导入试卷包
let importAnswerExcelUrl = '/paper/importAnswerExcel'; //导入标准答案excel
let questionListUrl = '/paper/questionList'; //获取题目列表
// let getStatisticsInfoUrl = '/paper/getStatisticsInfo'; //任务划分-获取统计的基础信息
let listZGQuestionsUrl = '/paper/listZGQuestions'; //任务划分-查询主观题数据list表
let importKmhfyUrl = '/paper/importKMHFY'; //任务划分-导入科目核分员
let importPjyUrl = '/paper/importPJY'; //任务划分-导入评卷员
let saveTaskUrl = '/paper/saveTask'; //保存任务
let setThresholdUrl = '/paper/setThreshold'; //设置阈值
// let getPjyInfoUrl = '/paper/getPJYInfo'; //任务划分-查询评卷人员去查看修改页面
let getPjyInfosUrl = '/paper/getPJYInfos'; //任务划分-查询评卷人员去查看修改页面
let updatePjyUrl = '/paper/updatePJY'; //任务划分-修改评卷员
let delPjyUrl = '/paper/delPJY'; //任务划分-删除/清空所有评卷人员
let rwfpListXmidUrl = '/rwfp/list/'; //查询批次下面的科目
let downloadAswTemplate = '/score/downloadAnswerExcelTemplate'; //下载客观题模板
let taskDivideFinishUrl = '/paper/taskDivideFinish'; //完成分配
let tiXingObj = {
  1: '单选题',
  2: '多选题',
  3: '判断题',
  4: '填空题',
  5: '问答题',
  6: '选择填空题',
  7: '复合题'
};
export default {
  name: 'TaskKmfzrView',
  components: {
    TimuView
  },
  data() {
    return {
      pageNav: 'kemu',
      upMethod: 1,
      timuData: [],
      kemuParm: null,
      tiMuVisible: false,
      dxtPfbz: 1, //多选题评分标准
      uploadFile: null,
      renderComponent: true,
      daufltRender: true,
      zgtTimuData: [],
      page: {
        total: 0,
        size: 10,
        current: 1
      },
      multipleSelection: [],
      thresholdVal: null,
      thresholdVisible: false,
      selectTaskNo: [],
      pjryTableVisible: false,
      timuPjryArr: [],
      innerVisible: false,
      selectTimu: null,
      selectUsr: null,
      userParm: {},
      usrRules: {
        xm: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        sjh: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        zjh: [
          { required: true, message: '请输入证件号', trigger: 'blur' }
        ],
        jsdm: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ]
      },
      jueseData: [
        {
          'jsdm': 'tmzz',
          'jsmc': '小题组长',
        },
        {
          'jsdm': 'pjjs',
          'jsmc': '评卷教师',
        },
      ]
    }
  },
  computed: {
    ...mapState([
      // 'mvSltItem',
      'mvSltPici',
      'mvSltKemu',
      'mainKemuArr'
    ])
  },
  methods: {
    // 业内导航
    pageGoTo(tp) {
      that.pageNav = tp ? tp : 'kemu';
      if (tp == 'kemu') {
      }
      else if (tp == 'biaoda') {
        that.getTiMu();
      }
      else if (tp == 'renwu') {
        that.getZgyList();
      }
    },
    //标答上传方式选择
    selectMethod(tp) {
      that.upMethod = tp;
    },
    //导入试卷包
    importPaperPackageFun() {
      let ppCfg = {
        method: 'post',
        url: importPaperPackageUrl,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: null
      };
      const fd = new FormData();
      let mis = [];
      let file = that.uploadFile.raw;
      if (!file) {
        mis.push('试卷包');
      }
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      fd.append("file", new Blob([file], { type: file.type }));
      fd.append("pcdm", that.mvSltPici);
      fd.append("kmdm", that.mvSltKemu);
      ppCfg.data = fd;
      crudFun(ppCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '试卷包导入成功！',
            type: 'success'
          });
          that.removeFile(that.uploadFile);
          that.getTiMu();
        }
      });
    },
    //查询题目
    getTiMu() {
      let tmCfg = {
        url: questionListUrl,
        method: 'post',
        data: {
          "size": that.page.size,
          "current": that.page.current,
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu,
        }
      }
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      that.timuData = [];
      crudFun(tmCfg).then((data) => {
        if (data) {
          let tmArr = data.records;
          Lazy(tmArr).each(function (tm) {
            let txid = tm.questionType;
            tm.questionName = tiXingObj[tm.questionType];
            tm.answerdeal = '未添加答案';
            if (tm.answers && tm.answers.length > 0) {
              switch (txid) {
                case 1:
                case 2:
                case 3:
                  tm.answerdeal = Lazy(tm.answers).map(function (asw) {
                    return asw.answerContent;
                  }).toArray().join('、');
                  break;
                case 4:
                case 5:
                  tm.answerdeal = '已写入';
                  break;
              }
            }
          });
          that.page.total = +data.total;
          that.timuData = tmArr;
        }
      });
    },
    //导入客观题标准答案
    importKgtDaan() {
      let bdCfg = {
        method: 'post',
        url: importAnswerExcelUrl,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: null
      };
      const fd = new FormData();
      let mis = [];
      let file = that.uploadFile.raw;
      if (!file) {
        mis.push('Excel文件');
      }
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      fd.append("file", new Blob([file], { type: file.type }));
      fd.append("pcdm", that.mvSltPici);
      fd.append("kmdm", that.mvSltKemu);
      bdCfg.data = fd;
      crudFun(bdCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '客观题答案导入成功！',
            type: 'success'
          });
          that.removeFile(that.uploadFile);
          that.getTiMu();
        }
      });
    },
    //查看标准答案
    seeAnswer(row) {
      row['修改题目'] = false;
      that.selectTimu = row;
      that.tiMuVisible = true;
    },
    //修改标准答案
    modifyAnswer(row) {
      row['修改题目'] = true;
      that.selectTimu = row;
      that.tiMuVisible = true;
    },
    //题目页面关闭
    timuPageClose(isMod) {
      // childValue就是子组件传过来的值
      that.selectTimu = null;
      that.tiMuVisible = false;
      if (isMod) {
        that.getTiMu();
      }
    },
    //删除选中的文件
    removeFile(file) {
      that.uploadFile = null;
      let upFileElm = document.querySelector('.el-upload-list');
      if(upFileElm){
        upFileElm.remove();
      }
      that.forceRerender();
    },
    //得到试卷包文件详细信息
    getFileInfoZip(file) {
      that.uploadFile = file;
      // that.selectMethod(2);
      setTimeout(() => {
        that.importPaperPackageFun();
      }, 500);
    },
    //得到客观题答案详细信息
    getFileInfoExcel(file) {
      that.uploadFile = file;
      // that.selectMethod(2);
      setTimeout(() => {
        that.importKgtDaan();
      }, 500);
    },
    //得到客观题核分员文件详情
    getFileInfoKgthfy(file) {
      that.uploadFile = file;
      // that.selectMethod(2);
      setTimeout(() => {
        that.importKgthfyFun();
      }, 500);
    },
    //得到评卷人员详情
    getFileInfoPjry(file) {
      that.uploadFile = file;
      // that.selectMethod(2);
      setTimeout(() => {
        that.importPjryFun();
      }, 500);
    },
    // 新增评卷人员
    getFileInfoNewPjy(file) {
      that.uploadFile = file;
      setTimeout(() => {
        that.importPjryFun();
      }, 500);
    },
    //导入客观题核分员
    importKgthfyFun() {
      let hfyCfg = {
        method: 'post',
        url: importKmhfyUrl,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: null
      };
      const fd = new FormData();
      let mis = [];
      let file = that.uploadFile.raw;
      if (!file) {
        mis.push('Excel文件');
      }
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      fd.append("file", new Blob([file], { type: file.type }));
      fd.append("pcdm", that.mvSltPici);
      fd.append("kmdm", that.mvSltKemu);
      hfyCfg.data = fd;
      crudFun(hfyCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '客观题核分员导入成功！',
            type: 'success'
          });
          that.removeFile(that.uploadFile);
          // that.$emit('on-updateData', { type: 'subject' });
          that.getKeMu();
        }
      });
    },
    //导入评卷人员
    importPjryFun() {
      let pjyCfg = {
        method: 'post',
        url: importPjyUrl,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: null
      };
      const fd = new FormData();
      let mis = [];
      let file = that.uploadFile.raw;
      if (!file) {
        mis.push('Excel文件');
      }
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      fd.append("file", new Blob([file], { type: file.type }));
      fd.append("pcdm", that.mvSltPici);
      fd.append("kmdm", that.mvSltKemu);
      pjyCfg.data = fd;
      crudFun(pjyCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '评卷员导入成功！',
            type: 'success'
          });
          that.removeFile(that.uploadFile);
          that.getZgyList();
        }
      });
    },
    //查询主观题题目数
    getZgyList() {
      let zgtCfg = {
        url: listZGQuestionsUrl,
        method: 'post',
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu,
        }
      }
      let mis = [];
      let tmArr = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      that.zgtTimuData = [];
      new Promise(function (resolve, reject) {
        crudFun(zgtCfg).then((data) => {
          if (data) {
            let taskNum = [];
            let lastNum = 0;
            Lazy(data).each(function (tm, idx) {
              tm.groupUsers = [];
              tm.scoreUsers = [];
              tm.questionName = tiXingObj[tm.questionType];
              if (!tm.taskNo) {
                tm.taskNo = lastNum + 1;
              }
              taskNum.push(tm.taskNo);
              lastNum = Lazy(taskNum).sort().uniq().last();
            });
            tmArr = Lazy(data).sortBy('taskNo').toArray();;
            resolve(taskNum);
          }
          else {
            reject('没有主观题数据！');
          }
        });
      })
        .then(function (taskNos) {
          return new Promise(function (resolve, reject) {
            return that.getPjryFun(resolve, taskNos);
          });
        })
        .then(function (pjArr) {
          let sltTimu = null;
          let tmGroupObj = Lazy(tmArr).groupBy('taskNo').toObject();
          Lazy(tmArr).each(function (tm, idx) {
            tm.groupUsers = [];
            tm.scoreUsers = [];
            let fndPjjs = Lazy(pjArr).find(function (pj) {
              return pj.taskNo == tm.taskNo;
            });
            if (fndPjjs) {
              tm.groupUsers = fndPjjs.groupUsers;
              tm.scoreUsers = fndPjjs.scoreUsers;
            }
            let taskTmNumArr = Lazy(tmGroupObj[tm.taskNo]).sortBy('questionNo').toArray();
            tm.rspan = 0;
            if (tm.id == taskTmNumArr[0].id) {
              tm.rspan = taskTmNumArr.length;
            }
            if (that.selectTimu) {
              if (that.selectTimu.questionNo == tm.questionNo) {
                sltTimu = tm;
              }
            }
          });
          that.zgtTimuData = tmArr;
          //题目存在说明是修改评卷人员页面
          if (sltTimu) {
            that.modPjryPop('pop', sltTimu);
          }
        })
        .catch(function (err) {
          that.$message({
            showClose: true,
            message: err,
            type: 'error'
          });
        });
    },
    //合并评卷任务
    mergePjTask() {
      let sltTmArr = that.multipleSelection;
      if (sltTmArr && sltTmArr.length > 0) {
        let taskNum = [];
        let lastNum = 0;
        let sltTmNum = sltTmArr[0].taskNo;
        Lazy(that.zgtTimuData).each(function (tm, idx) {
          let fndTm = Lazy(that.multipleSelection).find(function (stm) {
            return stm.identifier == tm.identifier;
          });
          if (fndTm) {
            tm.taskNo = sltTmNum;
          }
          else {
            if (tm.taskNo > sltTmNum) {
              tm.taskNo = lastNum + 1;
            }
          }
          taskNum.push(tm.taskNo);
          lastNum = Lazy(taskNum).sort().last();
        });
        that.zgtTimuData = Lazy(that.zgtTimuData).sortBy('taskNo').toArray();
        setTimeout(function () {
          that.savePjTask();
        }, 200);
      }
    },
    //取消合并评卷任务
    unmergePjTask() {
      let sltTmArr = that.multipleSelection;
      if (sltTmArr && sltTmArr.length > 0) {
        let sltTmTaskNo = Lazy(sltTmArr).map(function (tm) {
          return tm.taskNo;
        }).uniq().toArray();
        if (sltTmTaskNo.length != 1) {
          that.$message({
            showClose: true,
            message: '请选择任务编号相同的题目！',
            type: 'error'
          });
          return;
        }
        that.zgtTimuData = Lazy(that.zgtTimuData).sortBy('questionNo').toArray();
        let taskNum = [];
        let lastNum = 0;
        let sltTmNum = sltTmArr[0].taskNo;
        let hasFnd = false;
        Lazy(that.zgtTimuData).each(function (tm, idx) {
          let fndTm = Lazy(that.multipleSelection).find(function (stm) {
            return stm.identifier == tm.identifier;
          });
          if (fndTm) {
            if (hasFnd) {
              tm.taskNo = lastNum + 1;
            }
            else {
              hasFnd = true;
              tm.taskNo = sltTmNum;
            }
          }
          else {
            if (tm.taskNo > sltTmNum) {
              tm.taskNo = lastNum + 1;
            }
          }
          taskNum.push(tm.taskNo);
          lastNum = Lazy(taskNum).sort().last();
        });
        that.zgtTimuData = Lazy(that.zgtTimuData).sortBy('taskNo').toArray();
        setTimeout(function () {
          that.savePjTask();
        }, 200);
      }
    },
    //保存评卷任务
    savePjTask() {
      let mgCfg = {
        method: 'post',
        url: saveTaskUrl,
        data: {
          "paperId": "",
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu,
          "taskInfos": []
        }
      };
      let mis = [];
      let paperId = null;
      let zgtTmlen = that.zgtTimuData.length;
      for (var i = 0; i < zgtTmlen; i++) {
        let idxTm = that.zgtTimuData[i];
        if (idxTm.paperId) {
          paperId = idxTm.paperId;
          break;
        }
      }
      if (!that.kemuParm) {
        mis.push('批次代码');
      }
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (!paperId) {
        mis.push('试卷ID');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      mgCfg.data.paperId = paperId;
      let groupObj = Lazy(that.zgtTimuData).groupBy('taskNo').toObject();
      let taskArr = [];
      Lazy(groupObj).each(function (v, k, l) {
        let taskTemp = {
          "taskNo": k,
          "questionIds": []
        };
        if (v && v.length > 0) {
          let tmIds = [];
          Lazy(v).each(function (tm) {
            let tmId = tm.identifier;
            if (tmId) {
              tmIds.push(tmId);
            }
          });
          if (tmIds.length > 0) {
            taskTemp.questionIds = tmIds;
            taskArr.push(taskTemp);
          }
        }
      });
      if (taskArr.length > 0) {
        mgCfg.data.taskInfos = taskArr;
        crudFun(mgCfg).then((data) => {
          if (data) {
            that.$message({
              showClose: true,
              message: '评卷任务保存成功！',
              type: 'success'
            });
            that.getZgyList();
          }
        });
      }
      else {
        that.$message({
          showClose: true,
          message: '没有任务！',
          type: 'error'
        });
      }
    },
    //table选中行
    handleSelectionChange(val) {
      // if (val && val.length > 0) {
      //   let valCopy = that.$comn.cloneObj(val);
      //   that.multipleSelection = Lazy(valCopy).sortBy('questionNo').toArray();
      // }
      let valCopy = that.$comn.cloneObj(val);
      that.multipleSelection = Lazy(valCopy).sortBy('questionNo').toArray();
    },
    //取消table选择
    clearSelection() {
      that.$refs.multipleTable.clearSelection();
    },
    //强制刷新dom
    forceRerender(tp) {
      if (tp && tp == 'dft') {
        that.daufltRender = false;
        that.$nextTick().then(() => {
          that.daufltRender = true;
        });
      }
      else {
        that.renderComponent = false;
        that.$nextTick().then(() => {
          that.renderComponent = true;
        });
      }
    },
    //设定批量阈值弹出
    setThresholdPop(tp, row) {
      if (tp && tp == 'pop') {
        if (row) {
          that.multipleSelection = [];
          that.multipleSelection.push(row);
        }
        let sltTmArr = that.multipleSelection;
        if (sltTmArr && sltTmArr.length > 0) {
          that.selectTaskNo = Lazy(sltTmArr).map(function (tm) {
            return tm.taskNo;
          }).uniq().toArray();
          that.thresholdVal = null;
          that.thresholdVisible = true;
        }
        else {
          that.$message({
            showClose: true,
            message: '请先选择任务！',
            type: 'error'
          });
        }
      }
      else if (tp && tp == 'cancel') {
        that.thresholdVal = null;
        that.thresholdVisible = false;
      }
    },
    // 批量设定阈值
    batchSetThcreshold() {
      let tsCfg = {
        url: setThresholdUrl,
        method: 'post',
        data: {
          "taskNos": "",
          "threshold": "",
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu,
        }
      }
      let sltTmArr = that.multipleSelection;
      let mis = [];
      let taskNos = [];
      if (sltTmArr && sltTmArr.length > 0) {
        taskNos = Lazy(sltTmArr).map(function (tm) {
          return tm.taskNo;
        }).uniq().toArray();
      }
      else {
        mis.push('任务编号');
      }
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (that.thresholdVal != null) {
        tsCfg.data.threshold = +that.thresholdVal;
      }
      else {
        mis.push('阈值');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      tsCfg.data.taskNos = taskNos;
      crudFun(tsCfg).then((data) => {
        if (data) {
          Lazy(that.zgtTimuData).each(function (zgt) {
            let suitIdx = null;
            let fndTm = Lazy(taskNos).find(function (tkn, idx) {
              let compareVal = tkn == zgt.taskNo;
              if (compareVal) {
                suitIdx = idx;
                return true;
              }
            });
            if (fndTm) {
              zgt.threshold = that.thresholdVal;
              sltTmArr.splice(suitIdx, 1);
            }
          });
          that.multipleSelection = [];
          that.clearSelection();
          that.setThresholdPop('cancel');
        }
      });
    },
    //查询评卷人员和题目组长
    getPjryFun(resolve, noArr) {
      let pjCfg = {
        url: getPjyInfosUrl,
        method: 'post',
        data: {
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu,
          "taskNos": noArr
        }
      }
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (!(noArr && noArr.length > 0)) {
        mis.push('任务编号');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        if (resolve) {
          resolve([]);
        }
        return;
      }
      crudFun(pjCfg).then((data) => {
        if (data) {
          if (resolve) {
            resolve(data);
          }
        }
        else {
          if (resolve) {
            resolve([]);
          }
        }
      });
    },
    //修改评卷人员弹出
    modPjryPop(tp, row) {
      if (tp && tp == 'pop') {
        that.selectTimu = row;
        that.timuPjryArr = Lazy(row.groupUsers).union(row.scoreUsers).toArray();
        that.pjryTableVisible = true;
      }
      else if (tp && tp == 'cancel') {
        that.selectTimu = null;
        that.timuPjryArr = [];
        that.pjryTableVisible = false;
        that.getZgyList();
      }
    },
    //修改评卷人员
    deletePjryFun(row) {
      let dlCfg = {
        method: 'post',
        url: delPjyUrl,
        data: []
      };
      let idsTmp = [];
      if (row && row == 'all') {
        Lazy(that.timuPjryArr).each(function (pjy) {
          if (pjy.taskCount == 0) {
            idsTmp.push(pjy.id);
          }
        });
      }
      else {
        idsTmp.push(row.id);
      }
      if (idsTmp && idsTmp.length > 0) {
        dlCfg.data = idsTmp;
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择要删除的用户！',
          type: 'error'
        });
        return;
      }
      crudFun(dlCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '用户删除成功！',
            type: 'error'
          });
          Lazy(idsTmp).each(function (id) {
            that.timuPjryArr = Lazy(that.timuPjryArr).reject(function (pjy) {
              return pjy.id == id;
            }).toArray();
          });
        }
      })
    },
    //修改用户
    modUserPop(tp, row) {
      if (tp && tp == 'pop') {
        if (row) {
          that.selectUsr = row;
          that.userParm = {
            "id": row.id,
            "xm": row.xm,
            "jsdm": row.jsdm,
            "sjh": row.sjh,
            "zjh": row.zjhm
          }
          that.innerVisible = true;
        }
        else {
          that.$message({
            showClose: true,
            message: '请选择要修改的用户！',
            type: 'error'
          });
        }
      }
      else if (tp && tp == 'cancel') {
        that.selectUsr = null;
        that.userParm = {};
        that.innerVisible = false;
      }
    },
    //保存用户修改
    saveUserInfo(formName) {
      if (!that.userParm) {
        that.$message({
          showClose: true,
          message: '请选择要修改的用户！',
          type: 'error'
        });
        return;
      }
      that.$refs[formName].validate((valid) => {
        if (valid) {
          let urCfg = {
            method: 'post',
            url: updatePjyUrl,
            data: {
              "id": that.userParm.id,
              "xm": that.userParm.xm,
              "sjh": that.userParm.sjh,
              "zjh": that.userParm.zjh,
              "jsdm": that.userParm.jsdm,
            }
          };
          crudFun(urCfg).then((data) => {
            if (data) {
              that.selectUsr.xm = that.userParm.xm;
              that.selectUsr.sjh = that.userParm.sjh;
              that.selectUsr.zjhm = that.userParm.zjh;
              that.selectUsr.jsdm = that.userParm.jsdm;
              that.selectUsr.jsmc = '';
              let fndJs = Lazy(that.jueseData).find(function (js) {
                return js.jsdm == that.userParm.jsdm;
              });
              if (fndJs) {
                that.selectUsr.jsmc = fndJs.jsmc;

              }
              that.$message({
                showClose: true,
                message: '用户信息修改成功！',
                type: 'success'
              });
              that.modUserPop('cancel');
            }
          });
        } else {
          return false;
        }
      });
    },
    //table的跨行和列
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex > 4) {
        if (row.rspan > 0) {
          return {
            rowspan: row.rspan,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    // 查询批次下面的科目
    getKeMu() {
      let gtCfg = {
        method: 'post',
        url: rwfpListXmidUrl + that.mvSltPici + '/' + that.mvSltKemu,
        data: {
          "pageNo": 1,
          "pageSize": 20
        }
      };
      that.pageGoTo('kemu');
      crudFun(gtCfg).then((data) => {
        if (data) {
          let kemuArr = data.records;
          let kemu_zero = Lazy(kemuArr).find(function (kemu) {
            return kemu.kmdm == that.mvSltKemu;
          });
          if (kemu_zero) {
            // let kemu_zero = kemuArr[0];
            if (kemu_zero.fzr) {
              kemu_zero.fzr = that.$comn.toObj(kemu_zero.fzr);
            }
            if (kemu_zero.kgthfy) {
              kemu_zero.kgthfy = that.$comn.toObj(kemu_zero.kgthfy);
            }
            that.kemuParm = kemu_zero;
          }
        }
      });
    },
    // 客观题答案模板下载
    downloadKgtTemplate() {
      let tpCfg = {
        method: 'get',
        url: downloadAswTemplate,
        params: {
          pcdm: that.mvSltPici,
          kmdm: that.mvSltKemu
        },
        responseType: 'blob'
      }
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      crudFun(tpCfg).then((res) => {
        if (res) {
          let fileStr = res.headers['content-disposition'];
          let fileArr = fileStr.split('=');
          let fileName = fileArr[1];
          const blob = new Blob([res.data]);
          saveAs(blob, fileName);
        }
      });
    },
    // 任务下发
    divideDone() {
      let xfCfg = {
        method: 'get',
        url: taskDivideFinishUrl,
        params: {
          pcdm: that.mvSltPici,
          kmdm: that.mvSltKemu
        }
      }
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      crudFun(xfCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '任务下发成功！',
            type: 'success'
          });
          that.getKeMu();
        }
      });
    }
  },
  mounted() {
    if (that.mvSltKemu) {
      that.getKeMu();
    }
    else {
      // that.$emit('on-updateData', { type: 'subject' });
    }
    that.pageGoTo('kemu');
  },
  created() {
    that = this;
  },
  watch: {
    mvSltKemu: {
      handler(newVal, oldVal) {
        // 数据发生变化时执行的操作
        if (that.mvSltKemu) {
          that.getKeMu();
          // let fndKm = Lazy(that.mainKemuArr).find(function (kemu) {
          //   return kemu.kmdm == that.mvSltKemu;
          // });
          // if (fndKm) {
          //   if (fndKm.kgthfy) {
          //     fndKm.kgthfy = that.$comn.toObj(fndKm.kgthfy);
          //   }
          //   that.kemuParm = fndKm;
          // }
        }
      },
      deep: true, // 是否深度监听
      // immediate: true, // 是否在组件创建时立即执行回调函数
    },
  },
}
</script>

<style lang="less" scoped>
.kemuInfo {
  .el-table::before {
    background-color: #fff;
  }

  .el-table {
    font-size: 16px;

    td {
      border: 0;
      padding: 5px;
    }
  }
}

.separator {
  font-size: 26px;
  padding: 0 30px;
  float: left;
  color: rgb(145, 145, 145);
}
</style>