<template>
  <div class="about">
    <div v-if="!showItemSetShow">
      <el-button size="small" type="primary" @click="addNewItem('new')">新建项目</el-button>
    </div>
    <div class="mt15" v-if="!showItemSetShow">
      <el-table border :data="itemData" style="width: 100%;">
        <el-table-column prop="xmmc" label="名称" align="center">
        </el-table-column>
        <el-table-column prop="xmsm" label="说明" align="center">
        </el-table-column>
        <el-table-column prop="" label="负责人" align="center">
          <template slot-scope="scope">
            <span v-for="(fzr, idx) in scope.row.xmfzr" :key="fzr.id">
              {{ fzr.xm }} <font v-if="idx != scope.row.xmfzr.length - 1">、</font>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="cjsj" label="创建时间" align="center">
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button @click="addNewItem('mod', scope.row)" type="text" size="small">修改</el-button>
            <el-button @click="itemSetPop('set', scope.row)" type="text" size="small" class="ml15">项目设定</el-button>
            <el-popover placement="top" width="160" v-model="scope.row.visible" class="ml15">
              <p>确定要删除此项目吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="scope.row.visible = false">取消</el-button>
                <el-button type="primary" class="ml15" size="mini" @click="deleteItem(scope.row)">确定</el-button>
              </div>
              <el-button slot="reference" type="text" size="small">删除</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="showItemSetShow">
      <el-form :model="setForm" :rules="setRules" ref="setForm" label-width="150px" style="width: 50%;">
        <el-form-item>
          <h1 class="ftz18">{{ selectItem.xmmc + '--' }}项目设定</h1>
        </el-form-item>
        <el-form-item label="项目负责人" prop="xmfzr">
          <el-row>
            <el-col :span="4">
              <el-button type="text" @click="addItemManagePop('add')">添加负责人</el-button>
            </el-col>
            <el-col :span="20">
              <!-- {{ setForm.xmfzr }} -->
              <span v-for="(fzr, idx) in setForm.xmfzr" :key="fzr.id">
                {{ fzr.xm }} <font v-if="idx != setForm.xmfzr.length - 1">、</font>
              </span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="任务分配模式" prop="rwfpms">
          <el-radio-group v-model="setForm.rwfpms">
            <el-radio :label="1">指派任务</el-radio>
            <!-- <el-radio :label="2">队列式任务</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="任务分配可选角色" prop="rwfpkxjs">
          <el-checkbox v-model="setForm.rwfpkxjs">大区负责人</el-checkbox>
          <el-checkbox v-model="checked" disabled>科目负责人</el-checkbox>
          <el-checkbox v-model="checked" disabled>小题组长</el-checkbox>
          <el-checkbox v-model="checked" disabled>评卷教师</el-checkbox>
          <!-- <el-checkbox-group v-model="setForm.rwfpkxjs">
            <el-checkbox v-for="js in jueseData" :key="js.id" :label="js.id">
              {{ js.jueseName }}
            </el-checkbox>
          </el-checkbox-group> -->
        </el-form-item>
        <el-form-item label="匿名" prop="sfnm">
          <el-radio-group v-model="setForm.sfnm">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="阅卷模式" prop="yjms">
          <el-radio-group v-model="setForm.yjms">
            <el-radio :label="1">单评</el-radio>
            <el-radio :label="2">双评</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分差阈值的解决办法" prop="fcyzjjbf" v-if="setForm.yjms == 2">
          <el-radio-group v-model="setForm.fcyzjjbf">
            <el-radio :label="1">仲裁</el-radio>
            <el-radio :label="2">三评</el-radio>
            <!-- 双评之后，才有此行选项 -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="开启人脸识别登录" prop="kqrlsbdl">
          <el-radio-group v-model="setForm.kqrlsbdl">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="开启双机位视频监控" prop="kqsjwspjk">
          <el-radio-group v-model="setForm.kqsjwspjk">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveItemSet('setForm')">保存</el-button>
          <el-button class="ml15" @click="itemSetPop('cancel')">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <!-- 新建项目 -->
      <el-dialog :title="itemParm.title" modal :close-on-click-modal="false" :show-close="false"
        :visible.sync="itemFormVisible">
        <el-form :model="itemParm" :rules="addNewRule" label-width="140px" style="width: 90%;">
          <el-form-item label="项目名称" prop="xmmc">
            <el-input v-model="itemParm.xmmc"></el-input>
          </el-form-item>
          <el-form-item label="项目说明" prop="xmsm">
            <el-input type="textarea" :rows="6" v-model="itemParm.xmsm"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addNewItem('cancel')">取 消</el-button>
          <el-button class="ml15" type="primary" @click="saveItem('itemParm')">保 存</el-button>
        </div>
      </el-dialog>
      <!-- 添加负责人 -->
      <el-dialog title="" modal :close-on-click-modal="false" :show-close="false" :visible.sync="addManageVisible">
        <div slot="title">
          <span class="ftz18">添加负责人</span>
          <el-select size="small" v-model="tjfzrSltDwid" placeholder="请选择单位" class="ml30" @change="queryDwXmfzr">
            <el-option v-for="org in orgData" :key="org.id" :label="org.dwmc" :value="org.id">
            </el-option>
          </el-select>
          <a class="tplAstyle" href="/template/项目负责人模板.xlsx" download="项目负责人模板.xlsx">
            项目负责人模板.xlsx
          </a>
          <el-upload class="upload-demo flr" ref="upload" action="" :on-remove="removeFile" :on-change="getFileInfo"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">批量导入项目负责人</el-button>
          </el-upload>
        </div>
        <div>
          <el-table ref="manageTable" :data="manageData" tooltip-effect="dark" style="width: 100%"
            @selection-change="handleMagChange" height="400">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="xm" label="姓名">
            </el-table-column>
            <!-- <el-table-column prop="dwmc" label="单位" show-overflow-tooltip>
            </el-table-column> -->
            <el-table-column prop="sjh" label="手机">
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addItemManagePop('cancel')">取 消</el-button>
          <el-button class="ml15" type="primary" @click="saveManageSelect()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { crudFun } from '@/libs/crud';
import Lazy from 'lazy.js';

let that = null;
let xmListUrl = '/xm/list'; //项目列表的查询
let xmDelUrl = '/xm/del'; //项目删除
let xmAddUrl = '/xm/add'; //项目添加
let xmUpdateUrl = '/xm/update'; //项目修改
let xmGxmfzfUrl = '/xm/getXMFZR'; //查询项目负责人
let xmSxmszUrl = '/xm/saveXMSZ'; //保存项目设定
let xmIfzrUrl = '/xm/importFZR/'; //导入项目负责人
let orgListUrl = '/dw/list'; //单位查询
export default {
  name: 'ItemManageView',
  data() {
    return {
      itemData: [],
      itemFormVisible: false,
      itemParm: {
        xmmc: null,
        xmsm: null,
        title: '项目管理',
      },
      showItemSetShow: false, //项目列表和项目设定的切换
      jueseData: [
        {
          id: 1,
          jueseName: '大区负责人'
        },
        {
          id: 2,
          jueseName: '科目负责人'
        },
        {
          id: 3,
          jueseName: '小题组长'
        },
        {
          id: 4,
          jueseName: '小题组长'
        },
      ],
      setForm: {
        xmfzr: '',
        rwfpms: 1,
        rwfpkxjs: false,
        sfnm: 1,
        yjms: 1,
        fcyzjjbf: 1,
        kqrlsbdl: 1,
        kqsjwspjk: 1,
        ids: null,
        xmid: null
      },
      addManageVisible: false,
      manageData: [],
      mtplSltMage: [],
      uploadFile: null,
      checked: true,
      page: {
        total: 0,
        size: 10,
        current: 1
      },
      usrPage: {
        total: 0,
        size: 100,
        current: 1
      },
      addNewRule: {
        xmmc: [
          { required: true, message: '项目名称不能为空！', trigger: 'blur' }
        ],
        xmsm: [
          { required: true, message: '项目说明不能为空！', trigger: 'blur' }
        ]
      },
      selectItem: null,
      setRules: {
        rwfpms: [
          { required: true, message: '请选择任务分配模式', trigger: 'change' }
        ],
        rwfpkxjs: [
          { required: true, message: '请选择任务分配可选角色', trigger: 'change' }
        ],
        sfnm: [
          { required: true, message: '请选择匿名', trigger: 'change' }
        ],
        yjms: [
          { required: true, message: '请选择阅卷模式', trigger: 'change' }
        ],
        fcyzjjbf: [
          { required: true, message: '请选择分差阈值的解决办法', trigger: 'change' }
        ],
        kqrlsbdl: [
          { required: true, message: '请选择开启人脸识别登录', trigger: 'change' }
        ],
        kqsjwspjk: [
          { required: true, message: '开启双机位视频监控', trigger: 'change' }
        ],
      },
      tjfzrSltDwid: null, //添加项目负责人选中的单位
      orgData: [],
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  methods: {
    //单位查询
    getOrg(resolve) {
      let gtCfg = {
        method: 'post',
        url: orgListUrl,
        data: {
          "pageNo": 1,
          "pageSize": 100 //先查询100条，后续商量如何处理
        }
      };
      crudFun(gtCfg).then((data) => {
        if (data) {
          let orgArr = data.records;
          that.orgData = orgArr;
          if (resolve) {
            resolve();
          }
        }
      });
    },
    //查询项目列表
    getItem() {
      let gtCfg = {
        method: 'post',
        url: xmListUrl,
        data: {
          "pageNo": that.page.current,
          "pageSize": that.page.size
        }
      };
      crudFun(gtCfg).then((data) => {
        if (data) {
          let itemArr = data.records;
          Lazy(itemArr).each(function (item) {
            item.visible = false;
            if (item.xmfzr) {
              item.xmfzr = that.$comn.toObj(item.xmfzr);
            }
            else {
              item.xmfzr = [];
            }
            if (item.xmsz) {
              item.xmsz = that.$comn.toObj(item.xmsz);
              if(item.xmsz.xmfzr){
                item.xmsz.xmfzr = that.$comn.toObj(item.xmsz.xmfzr);
              }
            }
            else {
              item.xmsz = {};
            }
          });
          that.page.total = +data.total;
          that.itemData = itemArr;
        }
      });
    },
    //新建项目
    addNewItem(tp, row) {
      that.itemParm = {
        id: null,
        xmmc: null,
        xmsm: null
      };
      that.itemFormVisible = !that.itemFormVisible;
      switch (tp) {
        case 'new':
          that.itemFormVisible = true;
          that.itemParm.title = '项目新增'
          break;
        case 'mod':
          let sltRow = that.$comn.cloneObj(row);
          sltRow.title = '项目修改';
          that.itemFormVisible = true;
          that.itemParm.id = sltRow.id;
          that.itemParm.xmmc = sltRow.xmmc;
          that.itemParm.xmsm = sltRow.xmsm;
          break;
        case 'cancel':
          that.itemFormVisible = false;
          break;
      }
    },
    //设定项目
    itemSetPop(tp, row) {
      if (tp && tp == 'set') {
        that.setForm.xmid = row.id;
        that.selectItem = that.$comn.cloneObj(row);
        let xmsz = that.selectItem.xmsz;
        if (xmsz) {
          let xmszCopy = that.$comn.toObj(xmsz);
          if(Object.keys(xmszCopy).length > 0){
            that.setForm = that.$comn.toObj(xmsz);
            that.setForm.rwfpkxjs = that.setForm.rwfpkxjs ? true : false;
          }
        }
      }
      else if (tp && tp == 'cancel') {
        that.selectItem = null;
        that.setForm = {
          xmfzr: '',
          rwfpms: 1,
          rwfpkxjs: false,
          sfnm: 1,
          yjms: 1,
          fcyzjjbf: 1,
          kqrlsbdl: 1,
          kqsjwspjk: 1,
          ids: null,
          xmid: null
        }
      }
      that.showItemSetShow = !that.showItemSetShow;
    },
    //删除项目
    deleteItem(row) {
      let delCfg = {
        method: 'get',
        url: xmDelUrl,
        params: {
          "id": null
        }
      };
      if (row) {
        delCfg.params.id = row.id;
        crudFun(delCfg).then((data) => {
          if (data) {
            row.visible = false;
            that.$message({
              showClose: true,
              message: '删除成功！',
              type: 'success'
            });
          }
          that.getItem();
        });
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择要删除的项目！',
          type: 'error'
        });
      }
    },
    //保存项目
    saveItem() {
      let mis = [];
      if (!that.itemParm.xmmc) {
        mis.push('项目名称');
      }
      if (!that.itemParm.xmsm) {
        mis.push('项目说明');
      }
      if (mis.length > 0) {
        that.$message({
          showClose: true,
          message: mis.join() + '不能为空！',
          type: 'error'
        });
        return;
      }
      let svCfg = {
        method: 'post',
        url: null,
        data: {
          "xmmc": that.itemParm.xmmc,
          "xmsm": that.itemParm.xmsm
        }
      };
      if (that.itemParm.id) {
        svCfg.url = xmUpdateUrl;
        svCfg.data.id = that.itemParm.id;
      }
      else {
        svCfg.url = xmAddUrl;
      }
      crudFun(svCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '成功！',
            type: 'success'
          });
          that.addNewItem('cancel');
          that.getItem();
          that.$emit('on-updateData', { type: 'item' });
        }
      });
    },
    //保存设定项目
    saveItemSet(formName) {
      that.$refs[formName].validate((valid) => {
        if (valid) {
          let setCfg = {
            method: 'post',
            url: xmSxmszUrl,
            data: {}
          };
          let copySet = that.$comn.cloneObj(that.setForm);
          copySet.rwfpkxjs = that.setForm.rwfpkxjs ? 1 : 0;
          delete copySet.xmfzr;
          setCfg.data = copySet;
          crudFun(setCfg).then((data) => {
            if (data) {
              that.$message({
                showClose: true,
                message: '项目设定成功！',
                type: 'success'
              });
              that.itemSetPop('cancel');
              that.getItem();
              that.$emit('on-updateData', { type: 'item' });
            }
          });
        } else {
          that.$message({
            showClose: true,
            message: '带 * 的为必选项，请选择！',
            type: 'error'
          });
          return false;
        }
      });
    },
    //添加项目负责人弹出
    addItemManagePop(tp) {
      that.addManageVisible = !that.addManageVisible;
      if (tp && tp == 'add') {
        that.queryDwXmfzr();
        that.getOrg();
      }
    },
    //选择负责人
    handleMagChange(val) {
      that.mtplSltMage = val;
    },
    //保存项目负责人选择
    saveManageSelect() {
      let xmTemp = [];
      let idTemp = [];
      Lazy(that.mtplSltMage).each(function (fzr) {
        xmTemp.push(fzr.xm);
        idTemp.push(fzr.id);
      });
      // that.setForm.xmfzr = xmTemp.join('、');
      that.setForm.xmfzr = that.$comn.cloneObj(that.mtplSltMage);
      that.setForm.ids = idTemp;
      that.addItemManagePop('cancel');
    },
    //得到项目负责人文件详细信息
    getFileInfo(file) {
      that.uploadFile = file;
      setTimeout(() => {
        that.importXmfzr();
      }, 1000);
    },
    //删除项目负责人文件
    removeFile(file) {
      that.uploadFile = null;
      let upFileElm = document.querySelector('.el-upload-list');
      if (upFileElm) {
        upFileElm.remove();
      }
    },
    //导入项目负责人
    importXmfzr() {
      let flCfg = {
        method: "post",
        url: xmIfzrUrl,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: null
      };
      const fd = new FormData();
      if (that.selectItem) {
        flCfg.url += that.selectItem.id;
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择项目！',
          type: 'error'
        });
        return;
      }
      let file = that.uploadFile.raw;
      fd.append("file", new Blob([file], { type: file.type }));
      flCfg.data = fd;
      crudFun(flCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '项目负责人导入成功！',
            type: 'success'
          });
          that.queryDwXmfzr();
          that.removeFile(that.uploadFile);
        }
      });
    },
    //查询单位项目负责人
    queryDwXmfzr() {
      let usrCfg = {
        method: 'get',
        url: xmGxmfzfUrl,
        params: {
          // "dwid": that.tjfzrSltDwid,
          "xmid": that.setForm.xmid
        }
      };
      if (that.tjfzrSltDwid) {
        usrCfg.params.dwid = that.tjfzrSltDwid;
      }
      if (!that.setForm.xmid) {
        that.$message({
          showClose: true,
          message: '请选项目！',
          type: 'error'
        });
        return;
      }
      crudFun(usrCfg).then((data) => {
        if (data) {
          let usrArr = data;
          Lazy(usrArr).each(function (usr) {
            usr.chk = false;
          });
          that.usrPage.total = usrArr.length;
          that.manageData = usrArr;
          setTimeout(function () {
            Lazy(that.setForm.ids).each(function (id) {
              let fndRow = Lazy(that.manageData).find(function (row) {
                return row.id == id;
              });
              if (fndRow) {
                that.$refs.manageTable.toggleRowSelection(fndRow);
              }
            });
          }, 100);
        }
      });
    },
  },
  mounted() {
    that.getItem();
    // if(that.user){
    //   that.tjfzrSltDwid = that.user.dwid;
    // }
  },
  created() {
    that = this;
  }
}
</script>

<style lang="less" scoped>
.dialog-footer {
  text-align: center;
}

.upload-demo {
  text-align: right;
}
</style>