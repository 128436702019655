<template>
  <div class="tryMark">
    <div v-if="pageNav == 'timu'">
      <el-table class="mt15" border :data="taskData" style="width: 100%;">
        <el-table-column prop="taskNo" label="任务编号" align="center">
        </el-table-column>
        <el-table-column prop="tmNos" label="题目序号" align="center">
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button @click="tryMarkTimu(scope.row)" type="text" size="small">试评</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-table class="mt15" border :data="timuData" style="width: 100%;">
        <el-table-column prop="paperId" label="试卷ID" align="center">
        </el-table-column>
        <el-table-column prop="questionNo" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="questionName" label="题型" align="center">
        </el-table-column>
        <el-table-column prop="score" label="分值" align="center">
        </el-table-column>
        <el-table-column prop="" label="答案/评分标准" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.answerdeal }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button @click="tryMarkTimu(scope.row)" type="text" size="small">试评</el-button>
          </template>
        </el-table-column>
      </el-table> -->
    </div>
    <div class="sp-wrap" v-if="pageNav == 'shiping'">
      <div class="daan-wrap">
        <div class="da-cont">

          <div class="zd-wrap mb30" v-for="zd in stuZuoDa" :key="zd.identifier">
            <h4>
              <span>题号：</span>
              <span>{{ zd.questionNo }}</span>
              <el-button type="text" class="ml30" @click="seeTiMu(zd)">查看原题</el-button>
              <el-button type="text" class="ml30" @click="modifyAnswer(zd)">修改评分参考</el-button>
            </h4>
            <div class="tkCont" v-if="zd.questionType == 4">
              <div class="kong" v-for="(kong, idx) in zd.responses" :key="idx" v-html="zd.answers[idx] || '&nbsp;'">
              </div>
            </div>
            <div class="zgt-wrap tkCont" v-if="zd.questionType == 5">
              <div class="stu-asw" v-for="(wd, idx) in zd.answers" :key="idx" v-html="wd">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pfck-wrap">
        <div class="mt10">
          <el-button type="danger" size="small" style="width: 100%;" @click="backToTiMu()">返回</el-button>
        </div>
        <!-- <div class="mt15">
          <el-button type="text" @click="seeTiMu()">查看原题</el-button>
          <el-button type="text" class="flr" @click="modifyAnswer()">修改评分参考</el-button>
        </div> -->
        <div class="mt15">
          <el-button-group>
            <el-button type="primary" style="width: 120px;" icon="el-icon-arrow-left" @click="pageTurn(-1)"
              :disabled="selectIdx == 0">
              上一页
            </el-button>
            <el-button type="primary" style="width: 120px;" @click="pageTurn(1)" :disabled="selectIdx == limitNum - 1">
              下一页<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </el-button-group>
        </div>
        <div class="mt15 algCt ftz18">
          {{ selectIdx + 1 }} / {{ limitNum }}
        </div>
      </div>
    </div>
    <!-- 引入题目子组件 -->
    <el-dialog title="查看题目" :visible.sync="tiMuVisible" width="50%" center :close-on-click-modal="false">
      <TimuView v-if="tiMuVisible" :passTimu="selectTimu" @timuEvent="timuPageClose"></TimuView>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Lazy from 'lazy.js';
import TimuView from './TimuView.vue';
import { crudFun } from '@/libs/crud';

let that = null;
let getTmzzQuestionUrl = '/paper/getCurrentSPData'; //获取当前小题组长所有题目信息
// let getStuAnswerPageDataUrl = '/paper/getStuAnswerPageData'; //小题组长获取当前试评内容
// let tiXingObj = {
//   1: '单选题',
//   2: '多选题',
//   3: '判断题',
//   4: '填空题',
//   5: '问答题',
//   6: '选择填空题',
//   7: '复合题'
// };
export default {
  name: 'TryMarkView',
  components: {
    TimuView
  },
  data() {
    return {
      pageNav: 'timu',
      taskData: [],
      timuData: [],
      tiMuVisible: false,
      page: {
        total: 0,
        size: 10,
        current: 1
      },
      selectTimu: null,
      timuZuoDa: [],
      chooseTimu: null,
      timuKeys: [],
      stuZuoDa: [],
      limitNum: 0,
      selectIdx: 0,
      students: []
    }
  },
  computed: {
    ...mapState([
      'mvSltPici',
      'mvSltKemu'
    ])
  },
  methods: {
    // 业内导航
    pageGoTo(tp) {
      that.pageNav = tp ? tp : 'timu';
      if (tp == 'kemu') {
      }
      else if (tp == 'timu') {
        that.getTiMu();
      }
    },
    //查询题目列表
    getTiMu() {
      let tmCfg = {
        url: getTmzzQuestionUrl,
        method: 'get',
        params: {
          "pcdm": that.mvSltPici,
          "kmdm": that.mvSltKemu,
        }
      }
      let mis = [];
      if (!that.mvSltPici) {
        mis.push('批次代码');
      }
      if (!that.mvSltKemu) {
        mis.push('科目代码');
      }
      if (mis && mis.length > 0) {
        that.$message({
          showClose: true,
          message: '缺少：' + mis.join(),
          type: 'error'
        });
        return;
      }
      that.taskData = [];
      that.timuData = [];
      that.students = [];
      that.limitNum = 0;
      crudFun(tmCfg).then((data) => {
        if (data) {
          Lazy(data).each(function (task) {
            let tmNoArr = [];
            Lazy(task.questionEntityList).each(function (tm) {
              tmNoArr.push(tm.questionNo);
              // let txid = tm.questionType;
              // let tmStdAsw = tm.standardAnswer;
              // tm.questionName = tiXingObj[tm.questionType];
              // tm.answerdeal = '未添加答案';
              // if (tmStdAsw && tmStdAsw.length > 0) {
              //   tm.standardAnswer = that.$comn.toObj(tmStdAsw);
              //   switch (txid) {
              //     case 1:
              //     case 2:
              //     case 3:
              //       tm.answerdeal = Lazy(tmStdAsw).map(function (asw) {
              //         return asw.answerContent;
              //       }).toArray().join('、');
              //       break;
              //     case 4:
              //     case 5:
              //       tm.answerdeal = '已写入';
              //       break;
              //   }
              // }
            });
            task.tmNos = tmNoArr.join('、');
          });
          that.taskData = data;
          // that.timuData = stuData.questionEntityList;
          // that.students = stuData.stus;
          // that.limitNum = that.students.length;
        }
      });
    },
    //查询学生作答
    tryMarkTimu(task) {
      if (task) {
        that.selectIdx = 0;
        that.timuData = task.questionEntityList;
        that.students = task.stus;
        that.limitNum = that.students.length;
        that.pageNav = 'shiping';
        that.pageTurn(0);
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择任务！',
          type: 'error'
        });
      }
      // that.chooseTimu = null;
      // if (tm) {
      //   that.chooseTimu = tm;
      //   let tmCfg = {
      //     url: getStuAnswerPageDataUrl,
      //     method: 'get',
      //     params: {
      //       "questionId": tm.identifier
      //     }
      //   }
      //   that.timuZuoDa = [];
      //   that.timuKeys = [];
      //   that.stuZuoDa = [];
      //   that.limitNum = 0;
      //   crudFun(tmCfg).then((data) => {
      //     if (data) {
      //       let stuZdTmp = [];
      //       that.limitNum = +data.limit[0];
      //       for (var i = 0; i < that.limitNum; i++) {
      //         stuZdTmp.push([]);
      //       }
      //       delete data.limit;
      //       Lazy(data).each(function (v, k, l) {
      //         that.timuKeys.push(k);
      //         if (v && v.length > 0) {
      //           Lazy(v).each(function (zd, idx) {
      //             stuZdTmp[idx].push(zd);
      //           });
      //         }
      //       });
      //       that.timuZuoDa = stuZdTmp;
      //       that.pageNav = 'shiping';
      //       that.pageTurn(0);
      //     }
      //   });
      // }
      // else {
      //   that.$message({
      //     showClose: true,
      //     message: '请选择题目！',
      //     type: 'error'
      //   });
      // }
    },
    //翻页
    pageTurn(dr) {
      let pageNum = that.selectIdx + dr;
      if (pageNum < 0) {
        pageNum = 0;
      }
      if (pageNum > that.limitNum - 1) {
        pageNum = that.limitNum - 1;
      }
      that.selectIdx = pageNum;
      let timuCopy = that.$comn.cloneObj(that.timuData);
      let sltStu = that.students[that.selectIdx];
      if (sltStu) {
        let zcid = sltStu.zcid;
        Lazy(timuCopy).each(function (tm) {
          tm.answers = [];
          if (tm.responses) {
            tm.responses = that.$comn.toObj(tm.responses);
          }
          if (tm.standardAnswer) {
            tm.standardAnswer = that.$comn.toObj(tm.standardAnswer);
          }
          let fndZd = Lazy(tm.listStuZD).find(function (zd) {
            return zd.stuRegId == zcid;
          });
          if (fndZd) {
            tm.answers = fndZd.answers;
          }
        });
        that.stuZuoDa = timuCopy;
      }
    },
    //查看题目
    seeTiMu(tm) {
      tm['修改题目'] = false;
      that.selectTimu = that.$comn.cloneObj(tm);
      that.tiMuVisible = true;
    },
    //题目页面关闭
    timuPageClose(isMod) {
      // that.chooseTimu['修改题目'] = false;
      that.tiMuVisible = false;
    },
    //修改标准答案
    modifyAnswer(tm) {
      tm['修改题目'] = true;
      that.selectTimu = that.$comn.cloneObj(tm);
      that.tiMuVisible = true;
    },
    //返回需要阅的题目列表
    backToTiMu() {
      // that.chooseTimu = null;
      that.timuZuoDa = [];
      that.timuKeys = [];
      that.stuZuoDa = [];
      that.limitNum = 0;
      that.selectIdx = 0;
      that.pageNav = 'timu';
    },
  },
  mounted() {
    that.pageGoTo('timu');
  },
  created() {
    that = this;
  }
}
</script>

<style lang="less" scoped>
.tryMark {
  width: 100%;
  height: 100%;
  position: relative;
  // background: #a59ffc80;
}

.sp-wrap {
  position: absolute;
  // background-color: #fcb0b0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.daan-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 260px;
  border-right: 1px solid #cfcfcf;

  img {
    max-width: 100%;
  }

  .da-cont {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 15px;
    // width: 100%;
    overflow-y: auto;
  }
  .zd-wrap{
    .el-button--text{
      padding: 0 !important;
    }
  }
}

.pfck-wrap {
  position: absolute;
  width: 240px;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
}

.tkCont {
  margin-top: 10px;
  padding: 10px;
  position: relative;
  min-height: 24px;
  background-color: #f1f1f1;
  line-height: 32px;
  border-radius: 6px;

  .kong {
    margin-right: 15px;
    display: inline-block;
    padding: 0 10px;
    border-bottom: 1px solid #000;
  }
}
</style>