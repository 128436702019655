import { render, staticRenderFns } from "./ItemManageView.vue?vue&type=template&id=1188f51e&scoped=true&"
import script from "./ItemManageView.vue?vue&type=script&lang=js&"
export * from "./ItemManageView.vue?vue&type=script&lang=js&"
import style0 from "./ItemManageView.vue?vue&type=style&index=0&id=1188f51e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1188f51e",
  null
  
)

export default component.exports