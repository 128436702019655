import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import PlgCommon from './plugins/common/common';
import '@wangeditor/editor/dist/css/style.css';
// import axios from 'axios';

Vue.config.productionTip = false;

Vue.use(ElementUI);

//注册插件
Vue.use(PlgCommon);

// 设置全局变量 $http
// Vue.prototype.$http = axios;

// Vue.prototype.version = '202401071154'; //不要忘记修改version.txt

new Vue({
  router,
  store,
  // debugger: true,
  render: h => h(App)
}).$mount('#app')
