import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginView from '../views/LoginView.vue';
import MainView from '../views/MainView.vue';
import MarkingView from '../views/MarkingView.vue';
import ScoreView from '../views/ScoreView.vue';
import ItemManageView from '../components/ItemManageView.vue';
import OrgManageView from '../components/OrgManageView.vue';
import StuManageView from '../components/StuManageView.vue';
import TaskDistView from '../components/TaskDistView.vue';
import TaskDqfzrView from '../components/TaskDqfzrView.vue';
import TaskKmfzrView from '../components/TaskKmfzrView.vue';
import TaskManageView from '../components/TaskManageView.vue';
import TaskScheduleView from '../components/TaskScheduleView.vue';
import FillAnswerView from '../components/FillAnswerView.vue';
import TaskXmfzrView from '../components/TaskXmfzrView.vue';
import TaskXtzzView from '../components/TaskXtzzView.vue';
import TimedTaskView from '../components/TimedTaskView.vue';
// import TimuView from '../components/TimuView.vue';
import UserManageView from '../components/UserManageView.vue';
import TryMarkView from '../components/TryMarkView.vue';

let originPush = VueRouter.prototype.push;  //备份原push方法

VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {                     //如果没有传回调函数，手动添加
    originPush.call(this, location, () => { }, () => { });
  }
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/tatlogin',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/main',
    name: 'MainView',
    component: MainView,
    children: [
      {
        path: '/score',
        name: 'ScoreView',
        component: ScoreView
      },
      {
        path: '/netmarking',
        name: 'MarkingView',
        component: MarkingView
      },
      {
        path: '/orgmanage',
        name: 'OrgManageView',
        component: OrgManageView
      },
      {
        path: '/usermanage',
        name: 'UserManageView',
        component: UserManageView
      },
      {
        path: '/itemmanage',
        name: 'ItemManageView',
        component: ItemManageView
      },
      {
        path: '/taskmanage',
        name: 'TaskManageView',
        component: TaskManageView
      },
      {
        path: '/stumanage',
        name: 'StuManageView',
        component: StuManageView
      },
      {
        path: '/taskdist',
        name: 'TaskDistView',
        component: TaskDistView
      },
      {
        path: '/taskxmfzr',
        name: 'TaskXmfzrView',
        component: TaskXmfzrView
      },
      {
        path: '/taskdqfzr',
        name: 'TaskDqfzrView',
        component: TaskDqfzrView
      },
      {
        path: '/taskkmfzr',
        name: 'TaskKmfzrView',
        component: TaskKmfzrView
      },
      {
        path: '/trymark',
        name: 'TryMarkView',
        component: TryMarkView
      },
      {
        path: '/taskxtzz',
        name: 'TaskXtzzView',
        component: TaskXtzzView
      },
      {
        path: '/taskschedule',
        name: 'TaskScheduleView',
        component: TaskScheduleView
      },
      {
        path: '/taskfillzc',
        name: 'FillAnswerView',
        component: FillAnswerView
      },
      {
        path: '/timedtask',
        name: 'TimedTaskView',
        component: TimedTaskView
      },
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
