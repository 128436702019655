<template>
  <div class="login">
    <el-row class="lgWrap" v-if="showElement">
      <el-col :span="12">
        <el-card class="loginCard">
          <div class="card-header">
            <span class="loginSysTitle mr">阅卷系统</span>
            <span class="ftz18">登录</span>
          </div>
          <el-form ref="ruleFormRef" :model="ruleForm" status-icon class="loginRuleForm">
            <el-form-item prop="usrName">
              <el-input v-model="ruleForm.usrName" type="text" placeholder="请输入手机号" prefix-icon="el-icon-user">
              </el-input>
            </el-form-item>
            <el-form-item prop="psw">
              <el-input v-model="ruleForm.psw" type="password" placeholder="请输入密码" prefix-icon="el-icon-key">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm()" class="wdp100">登录</el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <div class="vsBox" v-if="verNum">
          versions: {{ verNum }}
        </div>
      </el-col>
      <el-col :span="12" class="illustration">
        &nbsp;
      </el-col>
    </el-row>
    <div class="load-wrap" v-else>
      <div v-loading="loading">
        <!-- 加载中…… -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { crudFun } from '@/libs/crud.js';
import Lazy from 'lazy.js';

let that = null;
let jueseCode = ['xtgly', 'xmfzr', 'dqfzr', 'kmfzr', 'tmzz', 'zcjs', 'pjjs', 'spjs', 'kgthfy'];
let loginUrl = '/login';
let userCdtlUrl = '/user/getCredentials';
// let xmListUrl = '/xm/list'; //项目列表的查询
export default {
  name: 'LoginView',
  data() {
    return {
      showElement: false,
      loading: true,
      ruleForm: {
        usrName: "", 
        psw: ""

        // usrName: '13821240001', //大区负责人
        // psw: '040001'

        // usrName: '13500110002', //仲裁教师
        // psw: '110002'

        // usrName: '13400110002', //三评教师
        // psw: '110002'

        // usrName: '15010330199', //系统管理员
        // psw: '123456'

        // usrName: '19610121001', //项目负责人
        // psw: '100001'

        // usrName: '13310231102', //科目负责人
        // psw: '231112'

        // usrName: '15522110003', 
        // psw: '081102'

        // usrName: '03330102301', //题目组长
        // psw: '231101'

        // usrName: '13121110003', //评卷员
        // psw: '010003'

        // http://yj.zhifz.com/login?no=13300330002
        // http://127.0.0.1:8081/login?no=00018101705
      },
      verNum: null
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  methods: {
    ...mapMutations([
      'updateUserInfo',
      // 'updateXmArr'
    ]),
    //普通登陆
    submitForm(no) {
      new Promise(function (resolve, reject) {
        let config = {
          url: loginUrl,
          method: "post",
          data: {
            "mobile": "",
            // "password": ""
            // "mobile": that.ruleForm.usrName,
            // "password": that.ruleForm.psw
          }
        };
        if(no){
          config.method = "put";
          config.data.mobile = no;
        }
        else{
          config.data.mobile = that.ruleForm.usrName;
          config.data.password = that.ruleForm.psw;
        }
        if (!that.ruleForm.usrName && !no) {
          that.$message({
            showClose: true,
            message: '请输入用户名！',
            type: 'error'
          });
          return;
        }
        if (!that.ruleForm.usrName && !no) {
          that.$message({
            showClose: true,
            message: '请输入密码！',
            type: 'error'
          });
          return;
        }
        crudFun(config).then((data) => {
          if (data) {
            if (data.token) {
              that.$comn.localStg('set', 'myToken', data.token);
              delete data.token;
            }
            if(no){
              resolve(data);
            }
            else{
              let pjRoles = ['pjjs', 'spjs', 'zcjs'];
              let usrRoles = data.roles;
              let hasYjjs = false;
              Lazy(usrRoles).each(function(rl){
                let isIn = Lazy(pjRoles).contains(rl);
                if(isIn){
                  hasYjjs = true;
                }
              });
              if(location.pathname == '/login' && hasYjjs){
                that.$message({
                  showClose: true,
                  message: '请从独立监考端登录！',
                  type: 'error'
                });
                reject('请从独立监考端登录！');
              }
              else{
                resolve(data);
              }
            }
          }
          else {
            reject('当前用户未授权！');
          }
        });
      })
        .then(function (lgUsr) {
          return new Promise(function (resolve, reject) {
            let roleCongig = {
              url: userCdtlUrl,
              method: 'get',
              params: {}
            }
            crudFun(roleCongig).then((data) => {
              if (data) {
                let xmArr = [];
                let groupByXmid = Lazy(data).groupBy('xmid').toObject();
                Lazy(groupByXmid).each(function (v, k, l) {
                  let xmTmp = {
                    xmid: k,
                    xmmc: '',
                    jsdm: '',
                    jsmc: '',
                    pcArr: []
                  };
                  if (k != 'null' && v && v.length > 0) {
                    let groupByPcdm = Lazy(v).groupBy('pcdm').toObject();
                    xmTmp.xmmc = v[0].xmmc;
                    xmTmp.jsdm = v[0].jsdm;
                    xmTmp.jsmc = v[0].jsmc;
                    Lazy(groupByPcdm).each(function (v1, k1, l1) {
                      let pcTmp = {
                        pcdm: k1,
                        pcmc: '',
                        jsdm: '',
                        jsmc: '',
                        kmArr: []
                      }
                      if (k1 != 'null' && v1 && v1.length > 0) {
                        let groupByKmdm = Lazy(v1).groupBy('kmdm').toObject();
                        pcTmp.pcmc = v1[0].pcmc;
                        pcTmp.jsdm = v1[0].jsdm;
                        pcTmp.jsmc = v1[0].jsmc;
                        Lazy(groupByKmdm).each(function (v2, k2, l2) {
                          if(k2 != 'null' && v2 && v2.length > 0){
                            pcTmp.kmArr.push(v2[0]);
                            if (!(lgUsr.sltJs && lgUsr.sltJsdm)) {
                              for (var i = 0; i < jueseCode.length; i++) {
                                let jsCode = jueseCode[i];
                                let fndJs = Lazy(v2).find(function (js) {
                                  return js.jsdm == jsCode;
                                });
                                if (fndJs) {
                                  lgUsr.sltJs = fndJs.jsmc;
                                  lgUsr.sltJsdm = fndJs.jsdm;
                                  break;
                                }
                              }
                            }
                          }
                        });
                        xmTmp.pcArr.push(pcTmp);
                      }
                    });
                    xmArr.push(xmTmp);
                  }
                });
                lgUsr.jueseArr = xmArr;
                that.updateUserInfo(lgUsr);
                resolve();
              }
              else {
                reject('没有查询到用户角色！');
              }
            });
          })
            .then(function () {
              that.$router.push({ path: '/main' });
              // let gtCfg = {
              //   method: 'post',
              //   url: xmListUrl,
              //   data: {
              //     "pageNo": 1,
              //     "pageSize": 100
              //   }
              // };
              // crudFun(gtCfg).then((data) => {
              //   if (data) {
              //     let itemArr = data.records;
              //     that.updateXmArr(itemArr);
              //     that.$router.push({ path: '/main' });
              //   }
              //   else{
              //     that.$router.push({ path: '/main' });
              //   }
              // });
            })
            .catch(function (err) {
              if (err) {
                that.$message({
                  showClose: true,
                  message: err,
                  type: 'error'
                });
              }
            });
        });
    },
    //读取版本号
    loadVsn(name) {
      let xhr = new XMLHttpRequest(),
          okStatus = document.location.protocol === "file:" ? 0 : 200;
      xhr.open('GET', name, false);
      xhr.overrideMimeType("text/html;charset=utf-8");//默认为utf-8
      xhr.send(null);
      return xhr.status === okStatus ? xhr.responseText : null;
    }
  },
  mounted() {
    that.verNum = that.loadVsn("/version.txt");
  },
  created() {
    that = this;
    let passUrl = location.href;
    let param = that.$comn.queryURLParams(passUrl);
    let paramKeys = [];
    if(param){
      paramKeys = Object.keys(param);
    }
    if(paramKeys && paramKeys.length > 0){
      let noVal = param.no;
      if(noVal){
        that.showElement = false;
        that.submitForm(noVal);
      }
      else{
        that.showElement = true;
      }
    }
    else{
      that.showElement = true;
    }
    // that.$router.push({ path: '/main' });
  }
}
</script>
<!-- 
<script setup>
import { ref } from 'vue';
let that = this;
const ruleForm = ref({
  usrName: '',
  psw: ''
});

function submitForm() {
  that.$router.push({ path: '/main' });
}
</script> -->

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  height: 100%;
}
.load-wrap{
  margin-top: 15%;
}
.lgWrap {
  height: 100%;
  background-color: #fff;
}

/* .logo {
  margin-left: 50px;
  margin-top: 20px;
} */
.illustration {
  background: #1a8ad2 url('@/assets/login_back.png') no-repeat 60px center / auto 60%;
  height: 100%;
}

.loginCard {
  width: 450px;
  margin: 30% auto 0 auto;
}

.card-header {
  text-align: center;
  padding: 10px 0 15px 0;
}

.loginSysTitle {
  font-size: 32px;
  margin-right: 20px;
}
.vsBox{
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 50%;
  color: #888;
}
</style>
