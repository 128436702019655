import Common from './common.vue';
import Lazy from 'lazy.js';

// 全角转半角
function ToCDB(str) {
  let tmp = "";
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 65248 && str.charCodeAt(i) < 65375) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
    }
    else {
      tmp += String.fromCharCode(str.charCodeAt(i));
    }
  }
  return tmp;
}

// 半角转全角
function ToDBC(txtstring) {
  let tmp = "";
  for (let i = 0; i < txtstring.length; i++) {
    if (txtstring.charCodeAt(i) == 32) {
      tmp = tmp + String.fromCharCode(12288);
    }
    if (txtstring.charCodeAt(i) < 127) {
      tmp = tmp + String.fromCharCode(txtstring.charCodeAt(i) + 65248);
    }
  }
  return tmp;
}

export default {
  /**
   * 每个插件都有的install方法，用于安装插件
   * @param {Object} Vue - Vue类
   * @param {Object} [pluginOptions] - 插件安装配置
   */
  install(Vue, pluginOptions = {}) {
    // 创建"子类"方便挂载
    // const PlgCommon = Vue.extend(Common);
    let comnFun = {
      cloneObj: null,
      qxJudge: null,
      checkBrowser: null,
      dateToLc: null,
      toObj: null,
      hasClass: null,
      addClass: null,
      removeClass: null,
      toggleClass: null,
      imgHasCont: null,
      typeObj: null,
      inZfzFrame: null,
      agLogin: null,
      agLogout: null,
      agJoin: null,
      agLeave: null,
      agSendChnlMsg: null,
      agSendPeerMsg: null,
      agQueryPeer: null,
      setOnAgCmd: null,
      getUid: null,
      createWebDb: null,
      danXuanScr: null,
      duoXuanScr: null,
      panDuanScr: null,
      tianKongScr: null,
      xuanZheTkScr: null,
      tuoZhuaiScr: null,
      secToHms: null,
      base64ToBlob: null,
      dealSh: null,
      localStg: null,
      makeStgKey: null,
      getStgKey: null,
      deleteDB: null,
      // getObsClient: null,
      uploadToBox: null,
      checkHasZd: null,
      xztkDealZt: null,
      dealTiMu: null,
      getFileType: null,
      queryURLParams: null
    };
    let letter = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
      'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    //日期格式化，格式化为本地
    comnFun.dateToLc = (dtStr, tp) => {
      let nd = new Date(dtStr);
      let y = nd.getFullYear();
      let m = nd.getMonth() + 1;
      let d = nd.getDate();
      let h = nd.getHours();
      let f = nd.getMinutes();
      let s = nd.getSeconds();
      if (m < 10) {
        m = '0' + m;
      }
      if (d < 10) {
        d = '0' + d;
      }
      if (h < 10) {
        h = '0' + h;
      }
      if (f < 10) {
        f = '0' + f;
      }
      if (s < 10) {
        s = '0' + s;
      }
      let dateTime = y + '-' + m + '-' + d + ' ' + h + ':' + f;
      if (tp && tp == 'short') {
        dateTime = y + '年' + m + '月' + d + '日';
      }
      else if (tp && tp == 'shortline') {
        dateTime = y + '-' + m + '-' + d;
      }
      return dateTime;
    };

    //复制对象
    comnFun.cloneObj = (obj) => {
      if (obj || obj === 0) {
        let newObj = [];
        newObj = JSON.parse(JSON.stringify(obj));
        return newObj;
      }
      else {
        return '缺少需要复制的对象！';
      }
    };

    //权限判断
    comnFun.qxJudge = (jsArr, dtNum, qxNum) => {
      let result = false;
      let jsLen = jsArr.length || 0;
      for (let i = 0; i < jsLen; i++) {
        let qxArr = jsArr[i]['数据权限'] || [];
        if (qxArr.length > 0) {
          let qxLen = qxArr.length;
          for (let j = 0; j < qxLen; j++) {
            let qxItem = qxArr[j];
            let sjRlt = ((qxItem['数据种类'] & dtNum) == dtNum);
            let qxRlt = ((qxItem['权限'] & qxNum) == qxNum);
            result = sjRlt && qxRlt;
            if (result) {
              break;
            }
          }
        }
      }
      return result;
    };

    //浏览器检查
    comnFun.checkBrowser = () => {
      let Sys = {};
      let ua = navigator.userAgent.toLowerCase();
      let re = /(msie|firefox|chrome|opera|version).*?([\d.]+)/;
      let m = ua.match(re);
      Sys.browser = m[1].replace(/version/, "'safari");
      Sys.ver = m[2];
      return '浏览器:' + Sys.browser + ',版本号:' + Sys.ver;
    };

    //将字符串转换为Obj
    comnFun.toObj = (str) => {
      let bcDt = str;
      if (str && Object.prototype.toString.call(str) == '[object String]') {
        try {
          // str = unescape(str);
          let contZkhZb = str.indexOf('[');
          let contZkhYb = str.indexOf(']');
          let contDkhZb = str.indexOf('{');
          let contDkhYb = str.indexOf('}');
          if ((contZkhZb >= 0 && contZkhYb >= 0) || (contDkhZb >= 0 && contDkhYb >= 0)) {
            bcDt = JSON.parse(str);
          }
        }
        catch (err) {
          // that.alertInfFun('err', err.message);
        }
      }
      return bcDt;
    };

    /*
      图片压缩方法 *传入的文件大小已经比设定的参数小的时候，会直接返回原文件
      第一个入参  file
      第二个入参  可以直接是一个数字，表示图片大小，默认100kb (100000 byte)
      也可以是一个小数，表示图片压缩比例，例如 .8 表示 #分辨率# 压缩为原来的0.8倍
      也可以是一个对象，可以包含size, width, height, fileType，其中：
          size可以是图片大小，也可以是压缩比例
          width是压缩后的图片宽度，默认自动按比例缩小
          height是压缩后的图片高度，默认自动按比例缩小
          fileType是压缩后的图片类型，可以是png或者jpg，不填则与原文件相同
          qualityArgument是压缩后的图片质量，针对jpg图片，默认0.8中画质。0.6为低画质，0.9～1为高画质
    */
    comnFun.imageCompress = (file, obj = {}) => {
      let getImgCanvas = document.querySelector('#imgCanvas');
      let Orientation = null;
      let minSize = 500000; //图片最小容量，500kb
      let orgXyMz = false; //原题是否小于 minSize
      if (getImgCanvas) {
        getImgCanvas.remove();
      }
      EXIF.getData(file, function () {
        Orientation = EXIF.getTag(this, 'Orientation');
      });
      return new Promise((resolve, reject) => {
        let { size, width, height, fileType, qualityArgument } = obj;
        if (typeof obj == 'number') {
          size = obj;
        }
        if (file && file.size) {
          //不需要压缩
          if (minSize && file.size <= minSize) {
            orgXyMz = true;
          }
        }
        else {
          reject({
            msg: '文件参数错误，请确认是否传入了文件！'
          })
          return;
        }
        // size = size || 500000;
        if (!/(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(file.type)) {
          reject({
            msg: '文件格式不是jpg、png、gif或者jpeg，请确认文件格式！'
          })
          return;
        }
        fileType = fileType || file.type;
        switch (fileType) {
          case 'gif':
          case 'jpg':
          case 'jpeg':
          case 'image/jpeg':
            fileType = 'image/jpeg'
            break
          case 'png':
          case 'image/png':
            fileType = 'image/png'
            break
          default:
            reject({
              msg: '不支持的文件格式'
            })
            return;
        }
        //canvas检测。canvas用来压缩图片
        let canvas = document.createElement('canvas');
        canvas.id = 'imgCanvas';
        if (!canvas || !canvas.getContext) {
          reject({
            msg: '浏览器不支持canvas'
          })
          return;
        }
        let context = canvas.getContext('2d');
        //FileReader检测。FileReader用来转base64
        if (!window.FileReader) {
          reject({
            msg: '浏览器不支持FileReader'
          })
          return;
        }
        let reader = new FileReader(),
          img = new Image();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          // e.target.result就是图片base64
          img.src = e.target.result;
        }
        img.onload = function () {
          let originWidth = img.width,
            originHeight = img.height;
          if (width && height) {
            if (width > originWidth && height > originHeight) {
              //原始分辨率比设定的分辨率小，不需要压缩
              orgXyMz = true;
            }
          }
          else if (width) {
            if (width > originWidth) {
              //原始分辨率比设定的分辨率小，不需要压缩
              orgXyMz = true;
            }
            else {
              height = originHeight * width / originWidth;
            }
          }
          else if (height) {
            if (height > originHeight) {
              //原始分辨率比设定的分辨率小，不需要压缩
              orgXyMz = true;
            }
            else {
              width = originWidth * height / originHeight;
            }
          }
          else {
            if (orgXyMz) {
              //不需要压缩
              width = originWidth;
              height = originHeight;
            }
            else {
              let ratio = (size > 0 && size < 1) ? size : 0.3;
              width = (originWidth * ratio) | 0;
              height = (originHeight * ratio) | 0;
              qualityArgument = 0.95;
            }
          }
          canvas.width = width;
          canvas.height = height;
          if (Orientation && Orientation != 1) {
            switch (Orientation) {
              case 6: // 旋转90度
                canvas.width = height;
                canvas.height = width;
                context.rotate(Math.PI / 2);
                // (0,-height) 从旋转原理图那里获得的起始点
                context.drawImage(this, 0, -height, width, height);
                break;
              case 3: // 旋转180度
                context.rotate(Math.PI);
                context.drawImage(this, -width, -height, width, height);
                break;
              case 8: // 旋转-90度
                canvas.width = height;
                canvas.height = width;
                context.rotate(3 * Math.PI / 2);
                context.drawImage(this, -width, 0, width, height);
                break;
            }
          }
          else {
            context.drawImage(img, 0, 0, width, height);
          }
          //输出Base64数据
          let baseDt = canvas.toDataURL(fileType, qualityArgument || 1);
          resolve(baseDt);
          //输出Blob数据
          // canvas.toBlob(function (blob){
          //   blob.name = file.name;
          //   if(size && size > 1){
          //     if(blob.size <= size){
          //       resolve(blob);
          //     } 
          //     else {
          //       comnFun.imageCompress(blob, obj).then((newBlob) => {
          //         resolve(newBlob);
          //       });
          //     }
          //   } 
          //   else {
          //     resolve(blob);
          //   }
          // }, fileType, qualityArgument || .6);
        }
      })
    };

    /**
     * HTML5本地存储
     */
    comnFun.localStg = (tp, key, val) => {
      if (typeof (Storage) !== 'undefined') {
        if (tp == 'set') {
          localStorage.setItem(key, val);
        }
        else if (tp == 'get') {
          return localStorage.getItem(key);
        }
        else if (tp == 'remove') {
          let shenHeKey = key + '_shenhe';
          let markKey = key + '_mark';
          let playKey = key + '_play';
          let lqcKey = key + '_lastqc';
          let qcrKey = key + '_qcarr';
          localStorage.removeItem(key);
          localStorage.removeItem(shenHeKey);
          localStorage.removeItem(markKey);
          localStorage.removeItem(playKey);
          localStorage.removeItem(lqcKey);
          localStorage.removeItem(qcrKey);
        }
        else if (tp == 'clear') {
          localStorage.clear();
        }
      }
      else {
        alert('你的设备不知道HTML5本地存储！');
      }
    };

    //判断是否有class
    comnFun.hasClass = (obj, cls) => {
      return obj.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
    };

    //添加class
    comnFun.addClass = (obj, cls) => {
      if (!comnFun.hasClass(obj, cls)) obj.className += " " + cls;
    };

    //删除class
    comnFun.removeClass = (obj, cls) => {
      if (comnFun.hasClass(obj, cls)) {
        let reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
        obj.className = obj.className.replace(reg, ' ');
      }
    };

    //切换class
    comnFun.toggleClass = (obj, cls) => {
      if (comnFun.hasClass(obj, cls)) {
        comnFun.removeClass(obj, cls);
      }
      else {
        comnFun.addClass(obj, cls);
      }
    };

    // 检查图片是否为空
    comnFun.imgHasCont = (imgDt) => {
      let len = imgDt.length;
      let rtBl = false;
      for (let i = 0; i < len; i += 4) {
        let alphaVal = imgDt[i + 3];
        if (alphaVal != 0) {
          rtBl = true;
          break;
        }
      }
      return rtBl;
    };

    //判断数据种类
    comnFun.typeObj = (data) => {
      let type = Object.prototype.toString.call(data);
      if (type == '[object Number]') {
        return 'Number';
      }
      else if (type == '[object String]') {
        return 'String';
      }
      else if (type == '[object Boolean]') {
        return 'Boolean';
      }
      else if (type == '[object Object]') {
        return 'Object';
      }
      else if (type == '[object Array]') {
        return 'Array';
      }
      else {
        return '未知类型'
      }
    };

    //判断是否在框架里
    comnFun.inZfzFrame = () => {
      try {
        return (ZhiFuZi !== undefined);
      }
      catch (err) {
        return false;
      }
    };

    //声网login
    comnFun.agLogin = (params) => {
      let data = {
        result: true
      };
      agLoginPam = params;
      if (rtm._logined) {
        return new Promise(resolve => {
          resolve(data);
        });
      }
      else {
        return rtm.login(params.accountName, params.token).then(function () {
          rtm._logined = true;
          return data;
        });
        // .catch(function(err){
        //   data.result = false;
        //   data.error = err;
        //   return data;
        // });
      }
    };

    //声网logout
    comnFun.agLogout = (params) => {
      let data = {
        result: true
      };
      if (rtm._logined) {
        return rtm.logout().then(function () {
          rtm._logined = false;
          return data;
        });
        // .catch(function(err){
        //   data.result = false;
        //   data.error = err;
        //   return data;
        // });
      }
      else {
        return new Promise(resolve => {
          resolve(data);
        });
      }
    };

    //声网join
    comnFun.agJoin = (params) => {
      let data = {
        result: true
      };
      agJoinPam = params;
      return new Promise((resolve, reject) => {
        if (!rtm._logined) {
          reject(new Error('Please Login First'));
          return;
        }

        if (rtm.channels[params.channelName] && rtm.channels[params.channelName].joined) {
          resolve(data);
          return;
        }

        return rtm.joinChannel(params.channelName).then(() => {
          rtm.channels[params.channelName].joined = true;
          data.data = rtm.accountName;
          resolve(data);
        })
          .catch(err => {
            reject(err);
          });
      });
    };

    //声网leave
    comnFun.agLeave = (params) => {
      let data = {
        result: true
      };

      return new Promise((resolve, reject) => {
        if (!rtm._logined) {
          reject(new Error('Please Login First'));
          return;
        }

        else if (!rtm.channels[params.channelName] || (rtm.channels[params.channelName] && !rtm.channels[params.channelName].joined)) {
          resolve(data);
          return;
        }

        return rtm.leaveChannel(params.channelName).then(() => {
          if (rtm.channels[params.channelName]) {
            rtm.channels[params.channelName].joined = false;
            rtm.channels[params.channelName] = null;
          }
          data.data = rtm.accountName;
          resolve(data);
        })
          .catch(err => {
            reject(err);
          });
      });
      // let result = true;
      // let resultTxt = '';
      // let data = {
      //   result: true
      // };
      // if (!rtm._logined) {
      //   result = false;
      //   resultTxt = 'Please Login First';
      // }
      // else if (!rtm.channels[params.channelName] || (rtm.channels[params.channelName] && !rtm.channels[params.channelName].joined)){
      //   return new Promise(resolve => {
      //     resolve(data);
      //   });
      // }
      // else{
      //   return rtm.leaveChannel(params.channelName).then(() => {
      //     if (rtm.channels[params.channelName]) {
      //       rtm.channels[params.channelName].joined = false;
      //       rtm.channels[params.channelName] = null;
      //     }
      //     data.data = rtm.accountName;
      //     return data;
      //   })
      //   .catch(function(err){
      //     data.result = false;
      //     data.error = err;
      //     return data;
      //   });
      // }
    };

    //发送房间消息
    comnFun.agSendChnlMsg = (params) => {
      let result = true;
      let resultTxt = '';
      let data = {
        result: true
      };
      if (!rtm._logined) {
        result = false;
        resultTxt = 'Please Login First';
        if (agLoginPam) {
          comnFun.agLogin(agLoginPam);
        }
        // cnlMsgArr.push(params);
      }
      else if (!rtm.channels[params.channelName] || (rtm.channels[params.channelName] && !rtm.channels[params.channelName].joined)) {
        result = false;
        resultTxt = 'Please Join first';
        if (agJoinPam) {
          comnFun.agJoin(agJoinPam);
        }
        // cnlMsgArr.push(params);
      }
      if (result) {
        return rtm.sendChannelMessage(params.channelMessage, params.channelName).then(() => {
          return data;
        })
          .catch(function (err) {
            data.result = false;
            data.error = err;
            // cnlMsgArr.push(params);
            return data;
          });
      }
      else {
        return new Promise(resolve => {
          data.result = false;
          data.error = resultTxt;
          // cnlMsgArr.push(params);
          resolve(data);
        });
      }
    };

    //给个人发送信息
    comnFun.agSendPeerMsg = (params) => {
      let result = true;
      let resultTxt = '';
      let data = {
        result: true
      };
      if (!rtm._logined) {
        result = false;
        resultTxt = 'Please Login First';
        if (agLoginPam) {
          comnFun.agLogin(agLoginPam);
        }
        // potMsgArr.push(params);
      }
      // else if (!rtm.channels[params.channelName] || (rtm.channels[params.channelName] && !rtm.channels[params.channelName].joined)){
      //   result = false;
      //   resultTxt = 'Please Join first';
      //   if(agJoinPam){
      //     comnFun.agJoin(agJoinPam);
      //   }
      // }
      if (result) {
        return rtm.sendPeerMessage(params.peerMessage, params.peerId).then(() => {
          return data;
        })
          .catch(function (err) {
            data.result = false;
            data.error = err;
            // potMsgArr.push(params);
            return data;
          });
      }
      else {
        return new Promise(resolve => {
          data.result = false;
          data.error = resultTxt;
          // potMsgArr.push(params);
          resolve(data);
        });
      }
    };

    //查询在线用户状态
    comnFun.agQueryPeer = (params) => {
      let result = true;
      let resultTxt = '';
      let data = {
        result: true
      };
      if (!rtm._logined) {
        result = false;
        resultTxt = 'Please Login First';
      }
      if (result) {
        return rtm.queryPeersOnlineStatus(params.memberId).then((res) => {
          data.data = res[params.memberId];
          return data;
        })
          .catch(function (err) {
            data.result = false;
            data.error = err;
            return data;
          });
      }
      else {
        return new Promise(resolve => {
          data.result = false;
          data.error = resultTxt;
          resolve(data);
        });
      }
    };

    //声网消息获得UID
    comnFun.getUid = (str) => {
      let rtnMsg = '';
      let uid = '';
      let rtnObj = {
        rst: true,
        msg: ''
      };
      if (str) {
        let uidArr = str.split('_');
        if (uidArr && uidArr.length >= 2) {
          let uidStr = uidArr[1];
          if (uidStr) {
            rtnMsg = +uidStr;
            uid = +uidStr;
          }
          else {
            rtnObj.result = false;
            rtnMsg = 'UID不存在';
          }
        }
        else {
          rtnObj.result = false;
          rtnMsg = 'accountName不符合要求';
        }
      }
      else {
        rtnObj.result = false;
        rtnMsg = '缺少accountName';
      }
      rtnObj.msg = rtnMsg;
      return uid;
    };

    //设置声网文字的回调
    comnFun.setOnAgCmd = (cb) => {
      onWsCmd = cb;
    };

    //创建数据库
    let openDbTimeOut = null;
    let indexedDB = null;
    let db = null;
    let dbName = 'zfzWebDbNew';
    let storeName = 'tatStuZuoDaTable';
    let luYinStore = 'tatLuYinTable';
    comnFun.createWebDb = (resolve, reject, dbVer) => {
      //1\. 获取IDBFactory接口实例（文档地址： https://developer.mozilla.org/en-US/docs/Web/API/IDBFactory）
      indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.msIndexedDB;
      if (!indexedDB) {
        if (reject) {
          reject({ result: false, error: '你的浏览器不支持IndexedDB!' });
        }
        else {
          return { result: false, error: '你的浏览器不支持IndexedDB!' }
        }
      }
      // 2\. 通过IDBFactory接口的open方法打开一个indexedDB的数据库实例
      // 第一个参数： 数据库的名字，第二个参数：数据库的版本。返回值是一个：IDBRequest实例,此实例有onerror和onsuccess事件。
      let IDBOpenDBRequest = null;
      if (dbVer) {
        IDBOpenDBRequest = indexedDB.open(dbName, dbVer);
      }
      else {
        IDBOpenDBRequest = indexedDB.open(dbName);
      }
      try {
        // 3\. 对打开数据库的事件进行处理
        // 打开数据库成功后，自动调用onsuccess事件回调。
        IDBOpenDBRequest.onsuccess = function (e) {
          db = e.target.result;
          console.log('1.打开数据库成功!');
          let zdOsn = db.objectStoreNames.contains(storeName);
          let lyOsn = db.objectStoreNames.contains(luYinStore);
          console.log(`作答表：${zdOsn}; 录音表：${lyOsn}`);
          if (zdOsn && lyOsn) {
            if (resolve) {
              resolve({ result: true, data: db });
            }
            else {
              return { result: true, data: db }
            }
          }
          else {
            throw 'objectStoreNames不完整！';
          }
        };
        // 打开数据库失败
        IDBOpenDBRequest.onerror = function (e) {
          // that.$Message.warning({content: e.currentTarget.error.message, duration: 5, closable: true});
          if (reject) {
            reject({ result: false, error: e });
          }
          else {
            return { result: false, error: e }
          }
        };
        // 第一次打开成功后或者版本有变化自动执行以下事件：一般用于初始化数据库。
        IDBOpenDBRequest.onupgradeneeded = function (e) {
          db = e.target.result; // 获取到 demoDB对应的 IDBDatabase实例,也就是我们的数据库。
          console.log('2.打开数据库成功!');
          // console.log(db);
          if (!db.objectStoreNames.contains(storeName)) {
            //如果表格不存在，创建一个新的表格（keyPath，主键 ； autoIncrement,是否自增），会返回一个对象（objectStore）
            // objectStore就相当于数据库中的一张表。IDBObjectStore类型。
            let objectStore = db.createObjectStore(storeName, {
              keyPath: '注册ID题目ID'
            });
            //指定可以被索引的字段，unique字段是否唯一。类型： IDBIndex
            objectStore.createIndex('注册ID', '注册ID', { unique: false });
            objectStore.createIndex('题目ID', '题目ID', { unique: false });
            objectStore.createIndex('保存状态', '保存状态', { unique: false });
          }
          if (!db.objectStoreNames.contains(luYinStore)) {
            let objectStore = db.createObjectStore(luYinStore, {
              keyPath: '注册ID题目ID'
            });
            //指定可以被索引的字段，unique字段是否唯一。类型： IDBIndex
            objectStore.createIndex('注册ID', '注册ID', { unique: false });
            objectStore.createIndex('题目ID', '题目ID', { unique: false });
            objectStore.createIndex('保存状态', '保存状态', { unique: false });
          }
        };
        //数据库意外关闭
        IDBOpenDBRequest.onclose = function (e) {
          if (openDbTimeOut) {
            clearTimeout(openDbTimeOut);
          }
          openDbTimeOut = setTimeout(function () {
            comnFun.createWebDb(resolve, reject);
          }, 1000);
        };
        //数据阻塞
        IDBOpenDBRequest.onblocked = function (e) {
          if (reject) {
            reject({ result: false, error: e });
          }
          else {
            return { result: false, error: e }
          }
        };
      }
      catch (e) {
        db.close();
        if (openDbTimeOut) {
          clearTimeout(openDbTimeOut);
        }
        openDbTimeOut = setTimeout(function () {
          let verTmp = new Date().getTime();
          comnFun.createWebDb(resolve, reject, verTmp);
        }, 1000);
        console.log(e.message);
      }
    };

    //删除数据库
    comnFun.deleteDB = function () {
      if (indexedDB) {
        let DBDeleteRequest = indexedDB.deleteDatabase(dbName);
        DBDeleteRequest.onerror = function (event) {
          console.log('删除数据库失败！');
        };
        DBDeleteRequest.onsuccess = function (event) {
          console.log('数据库' + dbName + '删除成功！');
        };
      }
    };

    // 单选题现场评分(题目分值，标准答案，学生作答)
    comnFun.danXuanScr = (tiMuScr, bzDa, xsDa) => {
      let getScr = 0;
      if (xsDa === 0 || xsDa > 0) {
        if (xsDa === bzDa) {
          getScr = tiMuScr;
        }
      }
      return getScr;
    };

    // 多选题现场评分(题目分值，标准答案，学生作答, 评分标准)
    comnFun.duoXuanScr = (tiMuScr, bzDa, xsDa, pfbz) => {
      let getScr = 0;
      let divideScr = 0;
      if (xsDa && xsDa.length > 0) {
        let xsStd = Lazy(xsDa).without(bzDa).toArray();
        let stdXs = Lazy(bzDa).without(xsDa).toArray();
        switch (pfbz) {
          case 1:
            // 1-全对才给分, 否则不给分；
            if (xsStd.length == 0 && stdXs.length == 0) {
              getScr = tiMuScr;
            }
            break;
          case 2:
            // 2-少选给一半分, 多选不给分；
            if (xsStd.length == 0 && stdXs.length == 0) {
              getScr = tiMuScr;
            }
            else if (xsStd.length == 0 && stdXs.length > 0) {
              getScr = tiMuScr / 2;
            }
            else if (xsStd.length > 0 && stdXs.length == 0) {
              getScr = 0;
            }
            break;
          case 3:
            // 3-按正确选项数均分分值, 答对一个选项给一个分, 答错一个选项扣一个分,最低扣到0分；
            divideScr = tiMuScr / (bzDa.length || 1);
            if (xsStd.length == 0 && stdXs.length == 0) {
              getScr = tiMuScr;
            }
            else {
              let scoreTmp = 0;
              Lazy(xsDa).each(function (as) {
                let hasIn = Lazy(bzDa).contains(+as);
                if (hasIn) {
                  scoreTmp += divideScr;
                }
                else {
                  scoreTmp -= divideScr;
                }
              });
              if (scoreTmp > 0) {
                getScr = scoreTmp;
              }
              else {
                getScr = 0;
              }
            }
            break;
          case 4:
            // 4-按正确选项数均分分值, 答对一个选项给一个分, 多答不给分。
            divideScr = tiMuScr / (bzDa.length || 1);
            if (xsStd.length == 0 && stdXs.length == 0) {
              getScr = tiMuScr;
            }
            if (xsStd.length == 0 && stdXs.length > 0) {
              getScr = divideScr * xsDa.length;
            }
            if (xsStd.length > 0 && stdXs.length == 0) {
              getScr = 0;
            }
            break;
        }
      }
      return getScr;
    };

    // 判断题现场评分(题目分值，标准答案，学生作答)
    comnFun.panDuanScr = (tiMuScr, bzDa, xsDa) => {
      let getScr = 0;
      let bzdaTmp = 0;
      if (bzDa == 1 || bzDa === true || bzDa === 'true' || bzDa == '对') {
        bzdaTmp = 1;
      }
      if (+xsDa === bzdaTmp) {
        getScr = tiMuScr;
      }
      return getScr;
    };

    // 填空题现场评分(题目分值，标准答案，学生作答) 
    comnFun.tianKongScr = (tiMuScr, bzDa, xsDa) => {
      let getScr = 0;
      let divideScr = tiMuScr / (bzDa.length || 1);
      if (xsDa && xsDa.length > 0) {
        let scrTmp = 0;
        Lazy(xsDa).each(function (kg) {
          let xhNum = kg['序号'];
          let xhDa = kg['答案'];
          let fndBzda = Lazy(bzDa).find(function (bzda) {
            return bzda['序号'] == xhNum;
          });
          xhDa = ToCDB(xhDa);
          if (fndBzda) {
            // let hasIn = Lazy(fndBzda['答案']).contains(xhDa);
            let hasIn = Lazy(fndBzda['答案']).find(function (bzda) {
              return bzda == xhDa;
            });
            if (hasIn === 0 || hasIn) {
              scrTmp += divideScr;
            }
          }
        });
        getScr = scrTmp;
      }
      return getScr;
    };

    //选择填空题现场评分（）
    comnFun.xuanZheTkScr = (tiMuScr, bzDa, xsDa) => {
      let getScr = 0;
      let divideScr = tiMuScr / (bzDa.length || 1);
      let hasIn = Lazy(bzDa).contains(xsDa);
      if (hasIn) {
        getScr = divideScr;
      }
      return getScr;
    };

    // 拖拽题现场评分(题目分值，标准答案，学生作答)
    comnFun.tuoZhuaiScr = (tiMuScr, bzDa, xsDa) => {
      let getScr = 0;
      if (bzDa && bzDa.length > 0 && xsDa && xsDa.length > 0) {
        if (Lazy(xsDa).equals(Lazy(bzDa))) {
          getScr = tiMuScr;
        }
      }
      return getScr;
    };

    //秒转为时分秒
    comnFun.secToHms = (msec, hSw) => {
      let hour = Math.floor(msec / 3600);
      let lftSec = msec % 3600;
      let min = Math.floor(lftSec / 60);
      let sec = parseInt(msec % 60);
      let minStr = min > 9 ? min : '0' + min;
      let secStr = sec > 9 ? sec : '0' + sec;
      let rtTimeStr = '';
      if (hSw) {
        rtTimeStr += hour + ':';
      }
      else {
        if (hour > 0) {
          rtTimeStr += hour + ':';
        }
      }
      rtTimeStr += minStr + ':';
      rtTimeStr += secStr;
      return rtTimeStr;
    };

    //base64转blob
    //atob
    // let s = new Buffer.from('待解码的字符', 'base64').toString('binary');
    //btoa
    // let s = new Buffer.from('待解码的字符', 'binary').toString('base64');
    comnFun.base64ToBlob = ({ b64data = '', contentType = '', fileName = '', sliceSize = 512 } = {}) => {
      return new Promise((resolve, reject) => {
        // 使用 atob() 方法将数据解码
        // let byteCharacters = atob(b64data);
        let newFileName = fileName || 'myLuYin.mp3';
        let byteCharacters = new Buffer.from(b64data, 'base64').toString('binary');
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          let slice = byteCharacters.slice(offset, offset + sliceSize);
          let byteNumbers = [];
          for (let i = 0; i < slice.length; i++) {
            byteNumbers.push(slice.charCodeAt(i));
          }
          // 8 位无符号整数值的类型化数组。内容将初始化为 0。
          // 如果无法分配请求数目的字节，则将引发异常。
          byteArrays.push(new Uint8Array(byteNumbers));
        }
        let result = new Blob(byteArrays, {
          type: contentType
        })
        result = Object.assign(result, {
          // 这里一定要处理一下 URL.createObjectURL
          preview: URL.createObjectURL(result),
          name: newFileName
        });
        resolve(result);
      });
    };

    // 处理审核
    comnFun.dealSh = (str) => {
      let shenHeCont = '';
      str.replace(shenHeReg, function (arg) {
        shenHeCont = arg;
      });
      return shenHeCont;
    };

    //本地存储，生成key
    comnFun.makeStgKey = (zcid, tp) => {
      if (zcid && tp) {
        return zcid + '_' + tp;
      }
      else {
        return '';
      }
    };

    //本地存储，生成key
    comnFun.getStgKey = (keyStr) => {
      if (keyStr) {
        let keyArr = keyStr.split('_');
        let zcid = keyArr[0];
        return zcid;
      }
      else {
        return '';
      }
    };

    //获取obs客户端 CONST
    // comnFun.getObsClient = () => {
    //   return new ObsClient({
    //     access_key_id: CONST.OBS_AK,
    //     secret_access_key: CONST.OBS_SK,
    //     server : CONST.OBS_SERVER,
    //     timeout : 60 * 5,
    //   });
    // };

    //上传文件到OBS
    comnFun.uploadToBox = (file, objkey, sbytes) => {
      let lctPrtl = location.protocol;
      let backDt = {
        result: true,
      };
      let obsServe = lctPrtl == 'https:' ? sbytes.SRS : sbytes.SR;
      let obsShow = lctPrtl == 'https:' ? sbytes.SWS : sbytes.SW;
      let obs = new ObsClient({
        access_key_id: sbytes.A,
        secret_access_key: sbytes.S,
        server: obsServe,
        timeout: 60 * 5,
      });
      return new Promise(function (resolve, reject) {
        obs.putObject({
          Bucket: sbytes.B,
          Key: objkey,
          SourceFile: file
        })
          .then(function (result) {
            if (result.CommonMsg.Status < 300) {
              let tmpBk = {
                ETag: result.InterfaceResult.ETag,
                objectKey: obsShow + objkey
              };
              backDt.result = true;
              backDt.data = tmpBk;
              resolve(backDt);
            }
            else {
              backDt.result = false;
              backDt.error = '错误状态码：' + result.CommonMsg.Status;
              resolve(backDt);
            }
          });
      });
    };

    //检查是否有作答
    comnFun.checkHasZd = (arr, key) => {
      let rightArr = [];
      let errArr = [];
      Lazy(arr).each(function (item) {
        if (item[key] === 0 || item[key]) {
          let tpOf = comnFun.typeObj(item[key]);
          if (tpOf == 'Object') {
            if (Object.keys(item[key]).length > 0) {
              rightArr.push(item);
            }
            else {
              errArr.push(item);
            }
          }
          else {
            rightArr.push(item);
          }
        }
        else {
          errArr.push(item);
        }
      });
      let backDt = {
        right: '',
        error: ''
      };
      if (rightArr.length > 0) {
        backDt['right'] = rightArr;
      }
      if (errArr.length > 0) {
        backDt['error'] = errArr;
      }
      return backDt;
    };

    //选择填空题没有子题时，子题的整理
    comnFun.xztkDealZt = (tm) => {
      let tmnr = tm['题目内容'];
      let tkTgStr = tm['题目内容']['题干'];
      let daAnFormatReg = new RegExp('<\%{.*?}\%>', 'g');
      let count = 1;
      let daAnArr = [];
      let xxtkDaType = 1;
      let kxxArr = [];
      let xztkZtArr = [];
      if (tmnr.hasOwnProperty('选项') && tmnr['选项'] && tmnr['选项'].length > 0) {
        xxtkDaType = 2;
      }
      let tkKsDa = null;
      if (tm['考生作答'] && tm['考生作答']['考生答案']) {
        tkKsDa = tm['考生作答']['考生答案'];
        tkKsDa = comnFun.toObj(tkKsDa);
      }
      tm['题目选项类型'] = xxtkDaType;
      tm['子题'] = [];
      let tmRgtDa = tm['题目内容']['答案'];
      if (tmRgtDa || tmRgtDa === 0 || tmRgtDa === '0') {
        tm['题目内容']['原始答案'] = comnFun.cloneObj(tmRgtDa);
      }
      else {
        tm['题目内容']['原始答案'] = null;
      }
      tm['题目内容']['原始题干'] = comnFun.cloneObj(tm['题目内容']['题干']);
      tm['题目内容']['题干'] = tkTgStr.replace(daAnFormatReg, function (arg) {
        let xhStr = '';
        let text = arg.slice(2, -2);
        let textJson = comnFun.toObj(text);
        let ztmTmp = {
          '分值': 1,
          '序号': textJson['序号'],
          '题目内容': {
            '备注': '',
            '序号': textJson['序号'],
            '答案': [],
            '选项': [],
          },
          '题目类型ID': 2
        };
        let kongXx = xxtkDaType == 1 ? textJson['选项'] : tm['题目内容']['选项'];
        let emptyArr = [];
        let xxTmpArr = [];
        if (kongXx && kongXx.length > 0) {
          Lazy(kongXx).each(function (xx, idx) {
            let xxTmp = {
              '选项内容': xx,
              '选项序号': idx
            };
            xxTmpArr.push(xxTmp);
          });
        }
        ztmTmp['题目内容']['选项'] = xxTmpArr;
        emptyArr.push(ztmTmp);
        xztkZtArr.push(emptyArr);
        if (xxtkDaType == 1) { //题目选项整理，每个空独立选项
          let daTmp = '第' + count + '空：';
          let daNum = '';
          if (textJson['答案'] !== '') {
            daNum = + textJson['答案'];
            daTmp += letterArr[daNum];
          }
          daAnArr.push(daTmp);
          textJson['空索引'] = count;
          kxxArr.push(textJson);
        }
        count++;
        return xhStr;
      });
      if (xxtkDaType == 2) {
        let tmxxTmp = {
          '空索引': 1,
          '选项': tmnr['选项'],
          '答案': tmnr['答案'],
          '备注': ''
        };
        let tmdaArr = comnFun.toObj(tm['题目内容']['答案']);
        kxxArr.push(tmxxTmp);
        Lazy(tmdaArr).each(function (da, idx) {
          let tmp = '第' + (idx + 1) + '空：';
          if (da !== '') {
            tmp += letterArr[da];
          }
          daAnArr.push(tmp);
        });
      }
      Lazy(kxxArr).each(function (xx) {
        let xxKidx = xx['空索引'] - 1;
        if (tkKsDa !== null) {
          let stuZdVal = tkKsDa[xxKidx];
          xx['考生作答'] = stuZdVal === 0 || stuZdVal > 0 ? stuZdVal : null;
        }
      });
      tm['题目内容']['空选项数组'] = kxxArr;
      tm['题目内容']['答案'] = daAnArr.join('；');
      tm['题目空数'] = comnFun.cloneObj(count - 1);
      tm['子题'] = xztkZtArr;
      return tm;
    };

    //整理题目
    comnFun.dealTiMu = (tm, isMod) => {
      let tmnr = tm['题目内容'];
      let tmAsw = tmnr['答案'];
      let tkTgStr = tm['题目内容']['题干'];
      let daAnFormatReg = new RegExp('<\%{.*?}\%>', 'g');
      let tmlxId = +tm['题目类型ID'];
      let txId = +tm['题型ID'];
      let newXxArr = [];
      let orgTmXx = null;
      let aswArr = [];
      let stuZuoDa = null;
      // let lx = tmlxId || txId;
      let lx = tmlxId;
      let aswHasImg = false;
      tm['图片答案'] = [];
      switch (lx) {
        case 1:
        case 2:
          newXxArr = [];
          orgTmXx = tmnr['选项'] || '';
          if (orgTmXx && orgTmXx.length > 0) {
            Lazy(orgTmXx).each(function (xx, xxIdx) {
              if (comnFun.typeObj(xx) == 'Object') {
                newXxArr.push(xx);
              }
              else {
                let xxTmp = {
                  '选项序号': xxIdx,
                  '选项内容': xx
                };
                newXxArr.push(xxTmp);
              }
            });
            tm['题目内容']['选项'] = newXxArr;
          }
          if (lx == 2) {
            stuZuoDa = [];
          }
          break;
        case 4:
          tm['题目内容']['题干'] = tkTgStr.replace(daAnFormatReg, () => {
            let xhStr = '<span class="kong dpIb"></span>';
            return xhStr;
          });
          stuZuoDa = [];
          break;
        case 5:
        case 6:
          stuZuoDa = [];
          break;
      }
      if (tmAsw && tmAsw.length > 0) {
        Lazy(tmAsw).sortBy('identifier').each((asw) => {
          let aswCont = asw.answerContent;
          if(aswCont){
            let aswIdx = null;
            let newAswCont = comnFun.cloneObj(aswCont);
            switch (lx) {
              case 1:
                aswIdx = Lazy(letter).indexOf(aswCont);
                if (aswIdx > -1) {
                  stuZuoDa = aswIdx;
                }
                break;
              case 2:
                let aswArr = aswCont.split(',');
                Lazy(aswArr).each(function(da){
                  aswIdx = Lazy(letter).indexOf(da);
                  if (aswIdx > -1) {
                    stuZuoDa.push(aswIdx);
                  }
                });
                break;
              case 3:
                if (aswCont == 'A') {
                  stuZuoDa = 1;
                }
                else if (aswCont == 'B') {
                  stuZuoDa = 0;
                }
                break;
              case 4:
                aswIdx = Lazy(aswCont).indexOf('Resources/answer');
                if (aswIdx > -1) {
                  // let aswMake = '<p><img src="' + aswCont + '" alt="答案"></p>';
                  let aswMake = aswCont;
                  aswHasImg = true;
                  stuZuoDa.push(aswMake);
                  newAswCont = comnFun.cloneObj(aswMake);
                }
                else {
                  stuZuoDa.push(aswCont);
                }
                break;
              case 5:
                stuZuoDa = aswCont;
                // aswIdx = Lazy(aswCont).indexOf('Resources/answer');
                // if (aswIdx > -1) {
                //   let aswMake = '<p><img src="' + aswCont + '" alt="答案"></p>';
                //   aswHasImg = true;
                //   stuZuoDa.push(aswMake);
                //   newAswCont = comnFun.cloneObj(aswMake);
                // }
                // else {
                //   stuZuoDa = aswCont;
                // }
                break;
              case 6:
                stuZuoDa.push(aswCont);
                break;
            }
            aswArr.push(newAswCont);
          }
        });
        tm.tmAnswer = aswArr.join(';');
      }
      if (comnFun.typeObj(stuZuoDa) == 'Array') {
        if(aswHasImg){
          // tm['学生作答'] = stuZuoDa.join('</br>');
          tm['学生作答'] = stuZuoDa; //有图片，输入框置空
          // tm['图片答案'] = stuZuoDa;
          // tm['图片答案'] = ['<p><img src="' + 'http://yj.zhifz.com:15888/Resources/answer/c8c88d18956140058f2932c857921aa9.png' + '" alt="答案"></p>'];
        } 
        else{
          if(stuZuoDa.length && !isMod){
            tm['学生作答'] = stuZuoDa.join(';');
          }
          else{
            tm['学生作答'] = stuZuoDa;
          }
        }
      }
      else {
        tm['学生作答'] = stuZuoDa;
      }
      tm['答案是图片'] = aswHasImg;
      return tm;
    };

    //获取文件类型
    comnFun.getFileType = (name) => {
      //获取文件类型
      var reg = /([^\\/]+)\.([^\\/]+)/i;
      reg.test(name);
      return RegExp.$2;
    };

    //获取URL中的参数
    comnFun.queryURLParams = (url) => {
      let pattern = /(\w+)=(\w+)/ig; //定义正则表达式
      let parames = {}; // 定义参数对象
      url.replace(pattern, ($, $1, $2) => {
        parames[$1] = $2;
      });
      return parames;
    };

    /**
     * 初始化并显示loading
     * @returns {Promise} Promise实例
     */
    Vue.prototype.$comn = comnFun;
  }
}
// 在任意想要的地方执行this.$comn()就可以启动loading动画了，在需要关闭的地方执行this.$comn.end()就可以关闭