<template>
  <div class="about">
    <div v-if="showTimuList">
      <el-table class="mt15" border :data="puzzleData" style="width: 100%;">
        <el-table-column prop="taskNo" label="任务编号" align="center">
        </el-table-column>
        <el-table-column prop="questionNo" label="题号" align="center">
        </el-table-column>
        <el-table-column prop="questionScore" label="题目分值" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="dealPuzzle(scope.row)">去处理</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else>
      <div v-if="selectZcTm">
        <el-form label-width="120px" :model="selectZcTm">
          <el-form-item label="学生作答">
            <div class="tkCont">
              <div class="stuZuoDa">
                <div class="kong" v-for="(kong, idx) in selectZcTm.responses" :key="idx"
                  v-html="selectZcTm.stuAnswerData[idx] || '&nbsp;'">
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="参考答案">
            <div class="tkCont">
              <div class="stuZuoDa">
                <div class="kong" v-for="(kong, idx) in selectZcTm.responses" :key="idx"
                  v-html="selectZcTm.standardAnswerData[idx] || '&nbsp;'">
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="教师评分">
            <p class="tech-scr" v-for="(tch, idx) in selectZcTm.techerScr" :key="tch.id">
              <span>评卷{{ cnNum[idx] }}: </span>
              <span>{{ tch.stuScore }} 分</span>
            </p>
          </el-form-item>
          <el-form-item label="仲裁分数">
            <el-input-number v-model="fillScr" :min="0" :max="selectZcTm.questionScore" label="仲裁分数"></el-input-number>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button class="ml30" @click="backToList()">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Lazy from 'lazy.js';
import { crudFun } from '@/libs/crud';

let that = null;
let getArbitrateFillTaskListUrl = '/arbitrate/getArbitrateFillTaskList'; //获取填空仲裁和三评任务列表
let getFillScoreListUrl = '/arbitrate/getFillQuestionScores'; //获取填空题分数
let commitFillArbitrateUrl = '/arbitrate/commitFillArbitrate'; //提交填空题仲裁
export default {
  name: 'FillAnswerView',
  data() {
    return {
      puzzleData: [],
      selectZcTm: null, //选中的仲裁题目
      showTimuList: true,
      cnNum: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
      fillScr: null,
      renderComponent: true
    }
  },
  computed: {
    ...mapState([
      'user',
      'mvSltPici',
      'mvSltKemu'
    ])
  },
  methods: {
    //查询答案仲裁数据
    getFillTask() {
      let yndCfg = {
        method: 'get',
        url: getArbitrateFillTaskListUrl,
        params: {
          pcdm: that.mvSltPici,
          kmdm: that.mvSltKemu,
          pageNo: 1,
          pageSize: 100
        }
      }
      that.puzzleData = [];
      crudFun(yndCfg).then((data) => {
        if (data && data.records) {
          that.puzzleData = data.records;
        }
        else {
          that.$message({
            showClose: true,
            message: '没有填空题答案仲裁任务！',
            type: 'warining'
          });
        }
      });
    },
    //处理填空题仲裁
    dealPuzzle(row) {
      let yndCfg = {
        method: 'get',
        url: getFillScoreListUrl,
        params: {
          id: "",
        }
      }
      if (row) {
        yndCfg.params.id = row.id;
      }
      else {
        that.$message({
          showClose: true,
          message: '请选择要仲裁的填空题！',
          type: 'warining'
        });
        return;
      }
      that.selectZcTm = null;
      crudFun(yndCfg).then((data) => {
        if (data && data.length > 0) {
          if (row.questionData) {
            row.questionData = that.$comn.toObj(row.questionData);
            let fndTm = Lazy(row.questionData).find(function(tm){
              return tm.questionNo == row.questionNo;
            });
            if (fndTm && fndTm.responses) {
              row.responses = that.$comn.toObj(fndTm.responses);
            }
          }
          that.showTimuList = false;
          let dataZero = data[0];
          if(dataZero.standardAnswerData){
            row.standardAnswerData = that.$comn.toObj(dataZero.standardAnswerData);
          }
          else{
            row.standardAnswerData = [];
          }
          if(dataZero.stuAnswerData){
            row.stuAnswerData = that.$comn.toObj(dataZero.stuAnswerData);
          }
          else{
            row.stuAnswerData = [];
          }
          row.techerScr = data;
          that.selectZcTm = row;
        }
        else {
          that.$message({
            showClose: true,
            message: '没有需要仲裁的填空题答案！',
            type: 'warining'
          });
        }
      });
    },
    //添加数据
    onSubmit() {
      let zcCfg = {
        method: 'get',
        url: commitFillArbitrateUrl,
        params: {
          id: "",
          score: ""
        }
      };
      let zcfs = that.fillScr;
      if (that.selectZcTm) {
        zcCfg.params.id = that.selectZcTm.id;
      }
      else {
        that.$message({
          showClose: true,
          message: '缺少仲裁题目ID！',
          type: 'warning'
        });
        return;
      }
      if (zcfs === 0 || zcfs > 0) {
        zcCfg.params.score = zcfs;
      }
      else {
        that.$message({
          showClose: true,
          message: '请输入仲裁分数！',
          type: 'warning'
        });
        return;
      }
      crudFun(zcCfg).then((data) => {
        if (data) {
          that.$message({
            showClose: true,
            message: '填空题仲裁分数保存成功！',
            type: 'success'
          });
          that.puzzleData = Lazy(that.puzzleData).reject(function (pd) {
            return pd.id == that.selectZcTm.id;
          }).toArray();
          that.backToList();
        }
      });
    },
    //返回列表
    backToList() {
      that.selectZcTm = null;
      that.showTimuList = true;
      that.getFillTask();
    },
    //强制刷新dom
    forceRerender(tp) {
      that.renderComponent = false;
      that.$nextTick().then(() => {
        that.renderComponent = true;
      });
    },
  },
  mounted() {
    that.getFillTask();
  },
  created() {
    that = this;
  }
}
</script>

<style lang="less" scoped>
.tkCont {
  margin-top: 10px;
  padding: 10px;
  position: relative;
  min-height: 24px;
  background-color: #ebebeb;
  line-height: 32px;
  padding-right: 270px;
  border-radius: 6px;

  .kong {
    margin-right: 15px;
    display: inline-block;
    padding: 0 10px;
    border-bottom: 1px solid #000;
  }

}

.tech-scr {
  margin: 0 0 15px 0;
}
</style>