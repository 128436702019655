import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    mvSltItem: null,
    mvSltPici: null,
    // mvSltItem: '1696458312312258562',
    // mvSltPici: '2023091001',
    mvSltKemu: null,
    mvSltPcsz: null,
    jueseArr: null,
    mainKemuArr: []
  },
  getters: {
  },
  mutations: {
    // 更新用户信息
    updateUserInfo(state, newUser) {
      state.user = newUser;
    },
    // 更新项目选择信息
    updateItemInfo(state, newUser) {
      state.mvSltItem = newUser;
    },
    // 更新批次选择信息
    updatePiciInfo(state, newUser) {
      state.mvSltPici = newUser;
    },
    // 更新科目选择信息
    updateKemuInfo(state, newUser) {
      state.mvSltKemu = newUser;
    },
    // 更新所选批次设置
    updatePcszInfo(state, newPcsz) {
      state.mvSltPcsz = newPcsz;
    },
    // 更新角色信息
    updateJueseInfo(state, newJs) {
      state.jueseArr = newJs;
    },
    // 更新项目信息
    updateKemuArr(state, newKm) {
      state.mainKemuArr = newKm;
    },
  },
  actions: {
  },
  modules: {
  }
})
